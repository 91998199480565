import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';

import PropTypes from 'prop-types';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import configurationblack from './images/configurationblack.png';




export default class Reports extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] 
      }
	 };
    
   
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  




componentDidMount() {
	
	if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "21" )
{
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  
}
	}
	
	
	document.title = "Reports";
	
	
 
  this.apicall(); 

}



apicall(){
	
	 axios.get(Serverurl+"ajaxdashboard.php?action=list")
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  
    });
}



  
render() {
	
if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    }
  	 
    
  return (
  
  
    <Page layout="public">

<Section slot="menu"><a href='#/Reports'> / Reports</a>
</Section>

<Section slot="btn"><a href='#/Reports'>Reports</a>
</Section>

<Section slot="content">
  
  
   <React.Fragment>
   
   
   
 <div className="main">

		        

<div className="ConfigurationS">

<ul>
<h5><img src={configurationblack} />  Purchase</h5>
<li><a href='#/Purchasepaymentreport'>PO Report</a></li>
<li><a href='#/Billingreport'>Bill Report</a></li>
<li><a href='#/Purchasegstreport'>GST Report</a></li>

</ul>

</div>

<div className="reports">

<ul>
<h5><img src={configurationblack} />  Sales</h5>

<li><a href='#/Quotationbillreport'>Quotation Report</a></li>
<li><a href='#/Invoicebillreport'>Invoice Report</a></li>
<li><a href='#/Invoicegstreport'>Invoice GST Report</a></li>

<li><a href='#/Billgstreport'> GST Report</a></li>
</ul>

</div>

<div className="inventory">

<ul>
<h5><img src={configurationblack} />  Inventory</h5>
<li><a href='#/Stockreport'>Stock Report</a></li>
<li><a href='#/Categoryreport'>Category Report</a></li>
<li><a href='#/Stockinventoryreport'>Stock Inventory Report</a></li>

</ul>

</div>


		<div className="clear"></div>  			
				



<div className="ConfigurationS">

<ul>
<h5><img src={configurationblack} />  Contacts</h5>
<li><a href='#/Customerbillreport'>Customer Report</a></li>
<li><a href='#/Vendorbillreport'>Vendor Report</a></li>
<li><a href='#/Customeroutstandingreport'>Customer Outstanding Report</a></li>
<li><a href='#/Vendoroutstandingreport'>Vendor Outstanding Report</a></li>

</ul>

</div>







<div className="clear"></div>  		
   
 </div>

  
   
  
   
 </React.Fragment>
		
		  
	 </Section></Page>	 


  );
  }	
   
   


}