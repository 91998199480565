import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  

import { Page, Section } from 'react-page-layout';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import VisibilityIcon from '@material-ui/icons/Visibility';
import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


import CloseIcon from '@material-ui/icons/Close';



import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import AddIcon from '@material-ui/icons/Add';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [




{ id: 'pr_type', numeric: false,  label: 'Product Type' },
{ id: 'pr_code', numeric: false,  label: 'Product Code' },

{ id: 'pr_name', numeric: false,  label: 'Product Name' },



{ id: 'pr_ca_id', numeric: false,  label: 'Category' },
{ id: 'pr_unit', numeric: false,  label: 'Product Unit' },




{ id: 'pr_salesrateincl', numeric: false,  label: 'Sales Price(Inc)' },
{ id: 'pr_minstock', numeric: false,  label: 'Stock' },





{ id: '', numeric: false,  label: '' },

]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};





const useStyles = makeStyles(theme =>({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
 
form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
})
)

export default class adminproduct extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'pr_id',pr_id:0,type:'',code:'',hsncode:'',name:'',description:'',contactname:'',unit:'',size:'',
	salesrateexcl:'',taxname:'',purchaserateincl:'',salesrateincl:'',minstock:'',vendor:'',openingstock:'',
	currentstock:'',code:'',category:'',categoryarray:[],
	btndisabled:false,
submitbtn:"Submit",
	 
	 
	  
	 
        
      }
	  
	 
	  
	 };
	 
	 
	 
onloadcategorybind(){

    axios.get(Serverurl+"ajaxcategories.php?action=categoriesgetallforproduct")
    
      .then((response) => {    
     
      this.setState({  categoryarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.categoryarray.map(category => {
                            
          return {value: category.ca_id, display:category.ca_name}
        });
      
		
        this.setState({
          categoryarray: [{value: '0', display: 'Category'}].concat(roleFromApi)
        });
      })


}	 
	 
	 
  myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  


componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}


componentDidMount() {
	
if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2"  )
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}
	  
	
		  
		  
		  

 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 

this.onloadcategorybind();
 
	}
	


apicall(page,rowsPerPage){
 
 this.setState({btndisabled:true,submitbtn:"Processing..."})
   axios.get(Serverurl+'ajaxproduct.php?action=list&pr_code='+this.state.code+'&pr_name='+this.state.name+'&pr_ca_id='+this.state.category+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  
  .then(resp => {  
	
 
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[];
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}
			
	
	  this.setState({rowsPaging:copy,btndisabled:false,submitbtn:"Search"});
	  
	 
     }
  else{
	  this.setState({  
			  StoreData: [], btndisabled:false,submitbtn:"Search", 
			   totaldata:0
			});
  }
    });
}


handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
   
    
    let name = this.state.name;
	let code = this.state.code;
	let category = this.state.category;
	this.apicall(this.state.page,this.state.rowsPerPage);
	
} 




handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  }; 


handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });
   

   };


  

productdelete(prid){  
  
		let r = window.confirm("Do you really want to Delete?");
		 if(r === true){ //sign Out logic
				 var formData = new FormData(); 
					 formData.append('action','delete')
					
					 formData.append('pr_id',prid)
					 formData.append('userid',localStorage.getItem("Ruserid"))
				
					  axios.post(Serverurl+"ajaxproduct.php", formData, {
						}).then(res => {
					
							 this.setState({ prevpage: -1,});
   
 
        setTimeout(() =>  this.componentDidUpdate() ,500); 
				  
					
					
							});
		 }
 }




 
 productupdate(id) {
        localStorage.setItem("prid", id);
       
		 this.setState({ editredirect: true})
		
 }
 
 
 
 
 
	

 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
 
 
userinsertorupdate(id) {
        localStorage.setItem("prid", id);
       
		 this.setState({ editredirect: true})
		
 }
 
 
 
 Useraddbox(val){
       // this.setState({ cuid: val });  
  
         setTimeout(() => this.refs.Import.handleClickOpen(),500);

      }
 
 
 
 
 render() {
	
	
if (this.state.editredirect === true) {
      return <Redirect to='/addproduct' />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
  
  
  	 
    
  return (
  
  
   <Page layout="public">

<Section slot="menu"><a href='#/configuration'>Configuration</a> / <a href='adminproduct'>Product</a>
</Section>

<Section slot="btn">

<a  href='#/addproduct'>New Product</a> 




<a className="title"  onClick={()=>{this.Useraddbox(0)}}><AddIcon className={useStyles.icon}/>Import</a>



	<Import ref="Import"  updateDonenew={this.insertDonenewParent}/>
</Section>



<Section slot="content">
  
  
  
  
    <React.Fragment>
	
 
    <div className="main">
	
	
	
	<div className="headername">
		 <p><b>Product</b></p>
		 </div>
	
	
 
	
	  <div className="clear"></div>
	
	<div className="search">
	
	<ul>
	
	<li>
	<p><TextField
            //variant="outlined"
            margin="normal"
            
            fullWidth
			value={this.state.code}
            id="code"
           variant="outlined"
            name="code" onChange={this.myChangeHandler}
            autoComplete="code"
			placeholder="Product Code"
            autoFocus
			
          />
		  </p></li>
		  
		 <li> <p><TextField
            //variant="outlined"
            margin="normal"
            variant="outlined"
            fullWidth
			value={this.state.name}
            id="name"
           
            name="name" onChange={this.myChangeHandler}
            autoComplete="name"
			placeholder="Product Name"
            autoFocus
			
          />
		  </p></li>
		  
		  
		  <li>
		 <p><FormControl  className={useStyles.formControl} >
        <p></p>
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.category }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.category}
		  name="category"
		
		
        >
		{this.state.categoryarray.map(category => (
            <option  
            
              value={category.value}
            >
              {category.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></li>
 <li><p > <Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
           id="searchform" 
            className={useStyles.submit}
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
          >
             {this.state.submitbtn}
          </Button></p>	</li>
 </ul>
		
		  
		 </div>  
	
	
	
	
	
	
	
	
	
	
	
	
	<div className="adminproduct">
   
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.StoreData.length}
            />
                      <TableBody>

                     {stableSort(this.state.StoreData, getSorting(this.state.order, this.state.orderBy)).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >



<TableCell><a  className="linkcolor" href={"#/Productdetails?prid="+item.pr_id+""}>{item.pr_type}</a>


</TableCell>
<TableCell><a className="linkcolor" href={"#/Productdetails?prid="+item.pr_id+""}>{item.pr_code}</a></TableCell>

<TableCell><a className="linkcolor" href={"#/Productdetails?prid="+item.pr_id+""}>{item.pr_name}</a></TableCell>


<TableCell><a className="linkcolor" href={"#/Productdetails?prid="+item.pr_id+""}>{item.ca_name}</a></TableCell>
<TableCell><a className="linkcolor" href={"#/Productdetails?prid="+item.pr_id+""}>{item.pr_unit}</a></TableCell>





<TableCell><a className="linkcolor" href={"#/Productdetails?prid="+item.pr_id+""}>Rs. {item.pr_salesrateincl}</a></TableCell>
<TableCell><a className="linkcolor" href={"#/Productdetails?prid="+item.pr_id+""}>{item.pr_currentstock}</a></TableCell>



<TableCell >

<div className="primary">
<a className="link" href={"#/addproduct?prid="+item.pr_id+""}>
<EditIcon  />  
</a>

</div>

<div className="secondary">
<DeleteIcon onClick={()=>{this.productdelete(item.pr_id)}}/>
</div>

<div className="viewicon">
<a href={"#/productbarcode?prid="+item.pr_id+""} >
  <VisibilityIcon  /></a>
</div>

</TableCell>






 </TableRow>


				</React.Fragment>	
				);
					})}
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
   </div>
 

   </div>
 </React.Fragment>
		

 </Section></Page>	  






  );
  }	
   

}

export  class  Import extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',id:'',editredirect: false,page: 0 ,prevpage:0,totaldata:0,rowsPerPage:50,rowsPaging:[],
	 
	  search:'',order:'desc' ,orderBy:'pr_id',doccsv:'',btndisabled:false,open:false,
submitbtn:"Submit",
	 
        
      }
	 };
    
   
    


myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
  
  
  
}



componentDidMount() {
	
	if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  
	
  
	}
	
	
handleClickOpen = (e) => {
 
  this.setState({ open:!this.state.open,pr_id:0
	  
		 });
		



};	
  
	

	
oncsvFileChange=(e) =>{
	
	
	 		  var filedate=new Date(e.target.files[0].lastModified);
	   var todaydate=new Date();
	   var minutes = Math.abs(todaydate- filedate);
	 
	   
		 
	   let imageFile3 = e.target.files[0];
	   
	   if (!imageFile3.name.match(/\.(csv)$/)) {
  alert( 'Please Upload CSV File' );
    return false;
  }else{
        this.setState({ doccsv: e.target.files })
  }
	
	
}	



handleClose= (event) => {
	


this.setState({btndisabled:true,submitbtn:"Processing..."})
 var formData = new FormData();

 if(this.state.doccsv!='')
	  {
		  
		for (const key of Object.keys(this.state.doccsv)) {
            formData.append('Filedata', this.state.doccsv[key])
        } 
 formData.append('file','1')		
	  } 
	  

				 
				 
				 	   
					 formData.append('action','import')
					
				
	               formData.append('userid',localStorage.getItem("Ruserid"))
				   
	   
					  axios.post(Serverurl+"ajaxproduct.php", formData, {
        }).then(res => {
			 this.setState({
            
			
			doccsv:'',
			
			
          })
	
		  alert("Uploaded Successfully..!");
		  this.setState({
		  btndisabled:false,submitbtn:"Save"})
		  
            });
        	

}









render() {
	 
  return (
  
 
 <React.Fragment>
 
 <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Product Import</DialogTitle>
      <DialogContent>
	  
 
 
 <p>
 <a href={Serverurl+"uploads/Productimport.csv"} target="_blank" className="" >
 
            Download NEW CSV file
         </a></p>
 

 <p className="inptcol upload-btn-wrapper">


  
 
 <input type="file" className="upload-btnfile" name="doccsv"  onChange={this.oncsvFileChange} /></p>

 
 

 
 </DialogContent>

     
      <DialogActions>
       
      
		
		 <Button
            type="submit"
            halfWidth
            variant="contained" 
            onClick={this.handleClickOpen}
            id="secondary"
          >
         Cancel
          </Button>
		
		  <Button
            type="submit"
            halfWidth
			disabled={this.state.btndisabled}
            variant="contained" 
            onClick={this.handleClose} color="secondary"
            id="searchform" 
          >
            {this.state.submitbtn}
          </Button>
		
      </DialogActions>
    </Dialog>
  

 
 
 

   
 </React.Fragment>
	





  );
  }	
   
   
  
  
  
  
	
}