import React from "react";






//const Serverurl= ["http://localhost/xampp/apoorva/rajshinkar/rbilling/"]
//const Serverurl= ["http://localhost/xampp/anjali/rbilling/"]




const Serverurl= ["https://bill.rashinkars.com/appfiles/"]

export default Serverurl;






