import React, { Component } from 'react';
import { Slot } from 'react-page-layout';

import './styles.css';






import homeblack from './images/homeblack.png';
import Menu from './Menu'


class PublicLayout extends Component {
	
	constructor(props) {
		

    super();

	 this.state = {open:false,islog:false,redirect:false,pagename:'',year:new Date().getFullYear()}
	}
	

	render() {

		return (
		  <React.Fragment>
		  
		
		  
		  
		  
		  
		  
		   <div className="header">


 <div className="stockleft">
 <ul>
 <li><h3>RASHINKAR'S</h3></li>
  <li>  </li>
 </ul>
 </div>

<div className="stockright">
<ul>

<li><p><a href='#/dashboard' tabIndex="-1">Dashboard</a></p></li>
<li><p><a href='#/adminuser' tabIndex="-1">Users</a></p></li>
<li><p><a href='#/configuration' tabIndex="-1">Configuration</a></p></li>
<li><p><a href='#/invoicefiles' tabIndex="-1">Invoice Files</a></p></li>

</ul>

</div>

   <div className="clear"></div>
 </div>

  

	

 
		  
		  
		  
		  
		  
		  <div className="contentright">
		  
		  <div className="breadcrumpbg">
		  <div className="menuleft">
		  <Menu/> </div>
		  <div className="breadcrump">
		  
		  
		  <ul>
		  <li></li>
		  <li><a href='#/dashboard' tabIndex="-1"><img src={homeblack} alt="Rashinkar's Billing" /> Home</a>&nbsp;&nbsp;</li>
	
		 <li> <Slot name="menu"  /></li>
		  </ul>
		  </div> 
		  <div className="stockbtns">

<p><Slot name="btn"  /></p>
</div>

  <div className="clear"></div>


</div>
		  
		
		  
		  <Slot name="content" />
		  
		
		  
		 
		<div className="clear"></div> </div>
		
		<div className="footer">
		<footer>
	
	<p className="copyrighttext">©Copyright {this.state.year} RASHINKAR'S, Mysore. All rights reserved.</p>
	
	</footer>
		</div>
		
		
	
		  </React.Fragment>
		);
	}
}

export default PublicLayout;