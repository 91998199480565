import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  

import { Page, Section } from 'react-page-layout';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';

import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';




function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [



{ id: 'us_name', numeric: false, label: 'Name' },
{ id: 'us_username', numeric: false,  label: 'User Name' },
{ id: 'us_email', numeric: false,  label: 'Email' },

{ id: 'us_mobile', numeric: false,  label: 'Mobile' },
{ id: 'us_ma_id', numeric: false,  label: 'Role' },

{ id: '', numeric: false,  label: '' },

]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};





const useStyles = makeStyles(theme =>({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
 
form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
})
)

export default class adminuser extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'us_id',name:'',us_id:0,username:'',email:'',uspassword:'',mobile:'',role:'',address:'',
	cusemail:'',	btndisabled:false,
submitbtn:"Submit",
	 
	 
	  
	 
        
      }
	 };
	 
	 
  myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  


componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}


componentDidMount() {
	
	 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2" )
{
	
}else{
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
	}
 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 

		
		
		  

	}
	
  



 

	


apicall(page,rowsPerPage){
	
	this.setState({btndisabled:true,submitbtn:"Processing..."})
	
	
axios.get(Serverurl+'ajaxuser.php?action=list&us_email='+this.state.cusemail+'&StartIndex='+page+'&PageSize='+rowsPerPage)
 
  // axios.get(Serverurl+'ajaxuser.php?action=list&StartIndex='+page+'&PageSize='+rowsPerPage)
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[];
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}
			
	
	  this.setState({rowsPaging:copy,btndisabled:false,submitbtn:"Search"});
	  
	 
     }
  else{
	  
	  this.setState({  
			  StoreData: [],  
			   totaldata:0,btndisabled:false,submitbtn:"Search"
			});
  }
    });
}

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  }; 


handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });
   

   };


  

userdelete(usid){  
  
		let r = window.confirm("Do you really want to Delete?");
		 if(r === true){ //sign Out logic
				 var formData = new FormData(); 
					 formData.append('action','delete')
					
					 formData.append('us_id',usid)
					 formData.append('userid',localStorage.getItem("Ruserid"))
				
					  axios.post(Serverurl+"ajaxuser.php", formData, {
						}).then(res => {
					
							 this.setState({ prevpage: -1,});
   
 
        setTimeout(() =>  this.componentDidUpdate() ,500); 
				  
					
					
							});
		 }
 }



userupdate(usid){
	
	 axios.get(Serverurl+'ajaxuser.php?action=list&us_id='+usid)
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		this.setState({  
		name:resp.data[0].us_name,
		username:resp.data[0].us_username,
		email:resp.data[0].us_email,
		uspassword:resp.data[0].us_password,
		mobile:resp.data[0].us_mobile,
		role:resp.data[0].ma_value,
		address:resp.data[0].us_address,
		
		us_id:resp.data[0].us_id
		});
	}
	
	
  })

 }
 
 
 handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
   
    
   // let name = this.state.name;
	let cusemail = this.state.cusemail;
	this.apicall(this.state.page,this.state.rowsPerPage);
	
}
 
 
	

 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
 
 
userinsertorupdate(id) {
        localStorage.setItem("usid", id);
       
		 this.setState({ editredirect: true})
		
 }
 
 
 
 
 
 
 
 
 render() {
	
	
if (this.state.editredirect === true) {
      return <Redirect to='/adduser' />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
  
  
  	 
    
  return (
  
  <Page layout="public">

<Section slot="menu"> / <a href='#/configuration' tabIndex="-1">Configuration</a> / Users
</Section>

<Section slot="btn"><a href='#/adduser' tabIndex="-1">New User</a>
</Section>

<Section slot="content">
  
  
  
    <React.Fragment>
	
 <br></br>
    <div className="main">
	<div className="headername">
		 <p><b>User</b></p>
		 </div>
	
	
   <div className="searchbg">
   <ul>
   <li> <p><TextField
           
            margin="normal"
            
            fullWidth
			value={this.state.cusemail}
            id="cusemail"
            variant="outlined"
            name="cusemail" onChange={this.myChangeHandler}
            autoComplete="cusemail"
			placeholder=" Name / Mobile / E-Mail"
            autoFocus
			
          />
		  </p></li>
		  
		 <li> <p className="btnsearch"><Button
            type="submit"
            fullWidth
            variant="contained" 
			disabled={this.state.btndisabled}
            color="primary"
            id="searchform" 
            className={useStyles.submit}
			onClick={this.handleSubmit}
			
          >
          {this.state.submitbtn}
          </Button></p>	</li></ul></div>
   
  <div className="adminproduct"> 
   
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.StoreData.length}
            />
                      <TableBody>

                     {stableSort(this.state.StoreData, getSorting(this.state.order, this.state.orderBy)).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >


<TableCell>{item.us_name}</TableCell>
<TableCell>{item.us_username}</TableCell>
<TableCell>{item.us_email}</TableCell>

<TableCell>{item.us_mobile}</TableCell>
<TableCell>{item.ma_value}</TableCell>



<TableCell >

<div className="primary">
<a className="link" href={"#/adduser?usid="+item.us_id+""}>
<EditIcon  />  
</a>

</div>

{item.orcount==="0" && (
<div className="secondary">
<DeleteIcon onClick={()=>{this.userdelete(item.us_id)}}/>
</div>
)}

</TableCell>

 </TableRow>


				</React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="6">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={[50, 100, 150,200]} 
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
   
 </div>

   </div>
  
 </React.Fragment>
		

 </Section></Page>	  






  );
  }	
   

}

