import React ,{Component} from 'react';

import { Page, Section } from 'react-page-layout';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';

import   styles from './styles.css';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';


const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));

export default class adminoption extends Component {
	
	
	
	
	constructor(props) {
    super(props);
	
	
	 this.state = {logredirect:false,pagename:'',ao_id:0,type:'',adminarray:[],label:'Select Name',desc:'',redirect: false};

	//this.handleEditorChange = this.handleEditorChange.bind(this);

    
  }




myChangeHandler = (event) => {
	
	 
  let nam = event.target.name;
  let val = event.target.value;
 
  this.setState({[nam]: val});
  
  
  
}


onchangeHandler= (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 if(event.target.name!==0)
	  
	  {
		 
		  
		   setTimeout(() => this.apicall(event.target.value),200);
	  }
}


apicall(aoid)
	{
		var self=this;
	
		  axios.get(Serverurl+'ajaxadminoption.php?action=list&ao_id='+aoid)

		    .then((response) => {    

 self.setState({  type: response.data[0].ao_type});


	    }) 
 
	  
	}

componentDidMount() {

 //document.ao_id = this.props.ao_id	
   
	 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
		  
  
    axios.get(Serverurl+"ajaxadminoption.php?action=list")
    
      .then((response) => {    
     
      this.setState({  adminarray: response.data,  });
      })   
    
      .then(data => {
        let adminFromApi = this.state.adminarray.map(admin => {
                            
          return {value: admin.ao_id, display: admin.ao_name}
        });
      
		
        this.setState({
          adminarray: [{value: '0', display: 'Select Name'}].concat(adminFromApi)
        });
      }) 
 
	  
  }



handleSubmit= (event) => {
    
    event.preventDefault();
	
    

    let type = this.state.type;
	var formData = new FormData();
	  
alert('type');
  
formData.append('action','update') 

formData.append('ao_id',this.state.ao_id)
formData.append('ao_type',type)

	   
formData.append('userid',1)	
	 
	   	 axios.post(Serverurl+"ajaxadminoption.php", formData, {
        }).then(res => {
			  alert("Submitted Sucecessfully");
		  
            });
		
};




onTagsChange = (event, values) => {
    this.setState({
      tags: values
    }, () => {
      
    
    });
  }





render() {
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	
	
	return (
	  <Page layout="public">

<Section slot="menu">Configuration / Admin Option
</Section>

<Section slot="btn"><a href='#/configuration'>Configuration</a>
</Section>

<Section slot="content">
	
	 <React.Fragment>
	
	<form className={useStyles.form}  onSubmit={this.handleSubmit}>

          
		
		 <div className="mainadoptionbg">

       <p>Name</p>
	   
	   <p>
       <Select
         native
        
		  defaultValue={{ label: this.state.label, value: this.state.ao_id }}
       
          onChange={this.onchangeHandler}
          
		   value={this.state.ao_id}
		  name="ao_id"
		  variant="outlined"
            margin="normal"
			fullWidth
        >
		{this.state.adminarray.map(admin => (
            <option  
            
              value={admin.value}
            >
              {admin.display}
            </option  >
          ))}
		
        </Select>
	</p>	  
		
		<br></br>
		  
	
<div className="content">

	<form>  
	
	  <p>Description</p>
	  <p>
		<TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.type}
            id="type"
           
            name="type" onChange={this.myChangeHandler}
            autoComplete="type"
			
          multiline
          rows={4}
			
            
			
          /></p>
	  
	  
</form>
	  
	  
	</div>	  
		<br/>  
		  
        <Button
            type="submit"
            halfWidth
            variant="contained" 
            color="primary"
            id="submit"  
            className={useStyles.submit}
			onClick={this.handleSubmit}>
			
            Submit
          </Button>
          
		  
		  
		  
		  
		
		  </div>
		   
		</form>
		  
		
		  
</React.Fragment>
	
	 </Section></Page>	 
 


  );
  }
}










