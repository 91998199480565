import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';

import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';



import ReactToPrint from 'react-to-print';



let strCus=[{title:'',year:0}];
const strCusdeflt=[];



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [




{ id: 'ma_value', numeric: false,  label: 'Invoice Type' },
{ id: 'or_invoice', numeric: false,  label: 'Invoice No' },
{ id: 'or_createddate', numeric: false,  label: 'Invoice Date' },
{ id: 'cu_contactname', numeric: false,  label: 'Customer' },
{ id: '', numeric: false,  label: 'Sub Total' },
{ id: 'or_discount', numeric: false,  label: 'Discount' },
{ id: 'tx_rate', numeric: false,  label: 'Tax' },
{ id: '', numeric: false,  label: 'Total Amount' },

{ id: 'or_roundoff', numeric: false,  label: 'Round off' },
{ id: '', numeric: false,  label: 'Grand Total' },


{ id: '', numeric: false,  label: '' },

]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class  Datewisereprint extends Component {
	
	
	constructor(props) {
    super();
	
	
	var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:-1,totaldata:0,rowsPerPage:50,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'or_id',value:'',invoice:'',createddate:'',contactname:'',reporttype:'',orddiscount:0,
	discount:'',rate:'',roundoff:0,searcharray:[],custtags:[],customer:'',reporttype:'1',briefdisplay:'1',detaileddisplay:'0',orid:'',InvoiceData:[],
	orname:'',oraddress:'',orcity:'',oremail:'',ormobilenum:'',orpannum:'',orgstinnum:'',or_taxamount:0,exportData:[],
	
	
	 
	   date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,btndisabled:false,
submitbtn:"Reprint", 


 
        
      }
	this.updateDonenewParent = this.updateDonenewParent;
	   this.onTagsChange = this.onTagsChange.bind(this);
	  
	 };
	updateDonenewParent = (text) => {this.componentDidMount();  }
	 handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }
  
  
	 handtoChange = (date) => {
	  var month="",date1="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  } 
	 
	 
	 
onloadCustomerBind=(event)=>{
	// axios.get(Serverurl+'ajaxcustomer.php?action=searchextender')
	  axios.get(Serverurl+'ajaxcustomer.php?action=searchextender&cu_contactname='+event.target.value)

  .then(resp => { 
  this.setState({ searcharray:resp.data});

	 var rowsArray1 = this.state.searcharray;
	 rowsArray1.map((row, k) => {
			 
		 const items = {
			 
        title:row.title,
         year:row.year
          
        };
         strCus.push( items)
		
		 });
  });
  
  
}	
	 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  
  localStorage.removeItem("fromdate");
   localStorage.removeItem("todate");
  
  this.setState({[nam]: val});
  
 /* if(nam==="" &&  val!="1")
{
this.setState({briefdisplay:"1",detaileddisplay:"0"	})
}

else {
this.setState({briefdisplay:"0",detaileddisplay:"1"})
	
}*/

 } 
 

handleSubmit= (event) => {
    
    event.preventDefault();
	
  let value = this.state.value;
	let invoice = this.state.invoice;
	let customer = this.state.customer;
	
	let fdate = this.state.fdate1;
	let tdate = this.state.tdate1;
	let reporttype = this.state.reporttype;

this.setState({detaileddisplay:"1"})



	this.apicall(this.state.page,this.state.rowsPerPage);
	
}


 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

componentDidMount() {
	
	document.title = "";
		
	

if(localStorage.getItem("Ruserid")===null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2" || localStorage.getItem("Rrole") === "4" || localStorage.getItem("Rrole") === "21" )
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}
	
	
		
		  localStorage.removeItem("fd");
	    localStorage.removeItem("td");
 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 

 this.orgviewmore(); 

}	 


orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  this.setState({		
    orname:resp.data[0].or_name,	
	oraddress:resp.data[0].or_address, 
   
	orcity:resp.data[0].or_city,
	oremail:resp.data[0].or_email,
	ormobilenum:resp.data[0].or_mobilenum,
	orpannum:resp.data[0].or_pannum,
	
	orgstinnum:resp.data[0].or_gstinnum,
	
	 }); 
	 
     }
 
    });
}





apicall(page,rowsPerPage){
	
	
 this.setState({btndisabled:true,submitbtn:"Processing..."})	
	
	// axios.get(Serverurl+'ajaxorders.php?action=invoicereport&ma_value='+this.state.value+'&or_invoice='+this.state.invoice+'&cu_contactname='+this.state.customer+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  
  axios.get(Serverurl+'invoice.php?or_id='+this.state.orid+'&ma_value='+this.state.value+'&or_invoice='+this.state.invoice+'&cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  
 
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			 // StoreData: resp.data, 
              InvoiceData: resp.data,			  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[50,100,150];
		
			/*for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}*/
			
	
	  this.setState({rowsPaging:copy ,btndisabled:false,submitbtn:"Reprint"});
	   localStorage.setItem("fd",this.state.fdate1);
	    localStorage.setItem("td",this.state.tdate1);
	   setTimeout(() => this.refs.Printfeedetailscopy.printcall(),500);
     }
	 else{
		  this.setState({InvoiceData:[],
		 totaldata:0,
		 btndisabled:false,submitbtn:"Reprint"});
	  
	 }
  
    });
	
	
	
	this.exportapicall();

}

exportapicall(){
	
		 axios.get(Serverurl+'invoice.php?or_id='+this.state.orid+'&ma_value='+this.state.value+'&or_invoice='+this.state.invoice+'&cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1)
  
  
	 .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportData: resp.data,  
			
			  
			   totaldata:parseInt(resp.data[0].count),
			   
			   
			   
			});
			
		
	 
     }
  
    });
	

	
}


 


handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
}


onTagsChange = (event, values) => {
	
	let optionsval= values["year"];
	let optionsval1= values["title"];

	
	 
    this.setState({
      custtags: values,
	   customer: values["title"]
	   
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
  }



render() {
	
				
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
		
	 let search = window.location.search;
let params = new URLSearchParams(search);


	
	return (
	
	
	 <Page layout="public">

<Section slot="menu"> / Datewise Reprint 
</Section>


<Section slot="btn">




 
<Printfeedetailscopy ref="Printfeedetailscopy" fdate1={this.state.fdate1} tdate1={this.state.tdate1}    updateDonenew={this.updateDonenewParent} />
        
    

</Section>

<Section slot="content">
  
   <React.Fragment>
   
   
    <div className="main">
	
	<br></br>
 
 <div className="headername">
		 <p><b>Reprint </b></p>
		 </div>
		
	
	
	<div className="search">
	
	<ul>
	
	<li><p>From :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
        value={this.state.fdate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
	id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</li>
 
 <li><p>To :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
      <DatePicker fullWidth
        value={this.state.tdate || addDays(moment().toDate(),1)}
		maxDate={addDays(moment().toDate(),0)}
        onChange={this.handtoChange}
            format="dd/MM/yyyy"
        autoOk name="tdate"
        ampm={false}
		id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	
		</li>
	
	
	
		  
		  
		 
 

		
		  
		 <li > <Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
           id="searchform"
            className={useStyles.submit}
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
          >
             {this.state.submitbtn}
          </Button></li>	
	
	 </ul>
	 
	 <br/>
	
	

   </div></div>
 </React.Fragment>
 
 
 {this.state.detaileddisplay === "1" && ( <React.Fragment>
   
   <div ref={el => (this.componentRef = el)} >
  <div className="main">
  
  <div className="reportheading">
		
       <b>REPRINT INVOICES</b>
           </div>
  
  
  
   <div className="quotationview">

<Paper className={useStyles.root}>
  
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table"> 


	

<TableRow >  
<TableCell colSpan={2}>

<div className="fromdetails">

<p><b>{this.state.orname}</b><br/>
	{this.state.oraddress}<br/>
	{this.state.orcity}<br/>
	{this.state.oremail}<br/>
	{this.state.ormobilenum}<br/>
	{this.state.orpannum}<br/>
	{this.state.orgstinnum}</p>

</div></TableCell>
</TableRow> 


            {this.state.InvoiceData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => {  
             let subtaotal = 0;
let grandtotal = 0;  
		  let strdiscount=row.or_discount;
		  let or_taxamount=row.or_taxamount;
		    let or_roundoff=row.or_roundoff;
			   return ( 
                 <React.Fragment>
           
               
			    <TableRow colSpan={2} >  

	           
			
  <TableCell>	          
			
		
<p>To:</p>

<p>Name : {row.or_name}</p>
<p>Email : {row.or_email}</p>
<p>Phone No : {row.or_phone}</p>
<p>PAN No : {row.cu_panno}</p>
<p>GST No : {row.or_gstn}</p>

	</TableCell>			
          
   
		 
<TableCell>		   
		   
<p>Details :</p>


<p>Invoice Type : <b>{row.ma_value}</b></p>
<p>Invoice No : {row.or_invoice}</p>
<p>Invoice Date: {row.or_createddate}</p>
<p>Sales Person : {row.us_name}</p>

</TableCell>
		   
		  
              </TableRow> 
			  
			
			  
              
  <TableRow className="expandable" ref="expanderBody" key={"tr-expander"}> 
      <TableCell className="uk-background-muted" colSpan={2}> 
      <TableContainer className={useStyles.container}>  
        <Table stickyHeader aria-label="sticky table"> 
        <TableBody>	

 <TableRow>  
        
			   <TableCell ><b>Product Code</b></TableCell>
			   <TableCell ><b>Product Name</b></TableCell>			   
               <TableCell ><b>S.Rate(Inc)</b></TableCell>  
               <TableCell ><b>S.Rate(Exc)</b></TableCell>
               <TableCell ><b>Discount</b></TableCell>  			   
               <TableCell ><b>Tax</b></TableCell>  
               <TableCell ><b>Qty</b></TableCell> 
               <TableCell ><b>Amount</b></TableCell> 
				 
              
               
            </TableRow>  
        

		
      {this.state.InvoiceData[index].subinvoice.map((roweach) => {

		subtaotal=(Number(subtaotal)+Number(roweach.netamt)).toFixed(2)
       
        grandtotal=(Number(grandtotal)+Number(roweach.netamt)).toFixed(2)

              return ( 
     
        
         
		  
          <TableRow  > 
   

   
   
          <TableCell>{roweach.pr_code}</TableCell>
           <TableCell>{roweach.op_title}</TableCell>
            <TableCell>Rs. {roweach.op_srateinc}</TableCell>
            <TableCell>Rs. {roweach.op_srateexc}</TableCell>
			 <TableCell>Rs. {roweach.op_discount}</TableCell>
			 <TableCell>{roweach.op_tax_value}</TableCell>
			 <TableCell>{roweach.op_quantity}</TableCell>
			 <TableCell>Rs. {roweach.netamt}</TableCell>
			
			 
        
        </TableRow>
		
		
		
	
        
              )})}
			  
			
			<TableRow  >
	

<TableCell colspan={7} align="right">Sub Total</TableCell>
<TableCell align="left">Rs.{subtaotal}</TableCell>

	</TableRow>	
		<TableRow  >
	

<TableCell colspan={7} align="right">Discount</TableCell>
<TableCell align="left">Rs.{Number(strdiscount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colspan={7} align="right">GST</TableCell>
<TableCell align="left">Rs.{Number(or_taxamount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colspan={7} align="right">Round off</TableCell>
<TableCell align="left">Rs.{or_roundoff}</TableCell>

	</TableRow>
		<TableRow  >
	

<TableCell colspan={7} align="right">Grand Total</TableCell>
<TableCell align="left">Rs.{Math.floor(Number(Number(subtaotal).toFixed(2))-parseFloat(parseFloat(strdiscount).toFixed(2))-Number(Number(or_roundoff).toFixed(2)))}</TableCell>

	</TableRow> 
			
			
			
			
			
			   </TableBody>
			  
              </Table> 
           </TableContainer>
		   
		   <div className="page-break" style={{"margin-top": "5px"}} ></div>
                 </TableCell>
				  

           </TableRow> 
     
              </React.Fragment>   
              );  
           })}  
			
			
		{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="2">No Records</TableCell>
					</TableRow>
					) }
			
        
        



  
  </Table>
  </TableContainer> 
  
  
  
   <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      /> 
   
   
   </Paper>
    </div>
  </div>
   
 
   
   </div>
  </React.Fragment>)} 
 
 
 
 
 
		

		 </Section></Page>	 	


);
 
  }	
   
   

}



class InvoicePrintpreview extends Component {
	
	constructor(props) {
    super(props);
	
	this.state = {logredirect:false,pagename:'',customerviewmore:[],order:'desc',orderBy:'or_id',page: 0 ,orddiscount:0,
	totaldata:0,cuid:'',orid:'',or_taxamount:0,sessionid:'',discount:'',roundoff:'',subtaotal:'',amount:0,disamt:0,
	grandtotal:'',invoiceno:'',
	redirect:false,vredirect:false,orgviewmore:[],invoiceviewmore:[],opviewmore:[],cname:'',cinvoice:''
}
	
	
	 
	  
	}
	
	
	myChangeHandler = (event) => {
	const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
 
}

getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

componentDidMount() {
	
//alert(this.props.invoice);

if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
	
	let itnumb=this.props.invoiceno;
 
 localStorage.setItem("page",this.state.rowsPerPage);
 
   
  this.viewmoreorder(); 
  // this.opviewmore(orid); 
  

}


 



orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  
    });
}

componentWillReceiveProps(props) {
	
this.viewmoreorder();	
}
viewmoreorder(){
		




axios.get(Serverurl+'invoice.php?fromdate='+localStorage.getItem("fd")+'&todate='+localStorage.getItem("td"))
  

	// axios.get(Serverurl+'ajaxorders.php?action=invoicereport&or_invoice='+this.props.invoice)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  invoiceviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   cname:resp.data[0].or_name,
			   cinvoice:resp.data[0].or_invoice,
			      orddiscount:resp.data[0].or_discountval,
			    or_taxamount:resp.data[0].or_taxamount,
			 discount:resp.data[0].or_discount,
			   roundoff:Number(resp.data[0].or_roundoff).toFixed(2),
			   taxgst:Number((resp.data[0].or_taxamount)/2).toFixed(2),
			   createddate:resp.data[0].or_createddate,
			   invdate:resp.data[0].date,
			   sp:resp.data[0].us_name
			});
			
	  // this.opviewmore(resp.data[0].or_sessionid); 
	 let strmaincon="";
	 var strorderarr=resp.data;

		
	strorderarr.map((rows, index) => {
strmaincon+='<table style="width:472px;font-Size:10px;font-Family;Arial"  >';

strmaincon+='<tr><td><div style="margin-top:160px"><table style="width: 100%"><tr>';

strmaincon+='<td style="width:220px;padding-left:60px" ><p>'+rows.or_invoice+'</p><p>'+rows.or_name+'</p></td>';

strmaincon+='<td style="width:200px" ><p style="text-align:right">'+rows.or_createddate+'</p><p style="text-align:left">'+rows.us_name+'</p>';
strmaincon+='</td></tr>';

 


strmaincon+='</table></div></td> </tr>';



strmaincon+='<tr><td>';
 let previoustax="",strcurnttax="",productdiscount=0,orddisval=0;;
			let strsubtaotal=0,taxablevalue=0,taxamount=0,totalamount=0,strcontent="",strincgrossval=0,strincgrossamt=0,strgrandtotal=0;
		
    var strRowsarr=resp.data[index].subinvoice;
  
  strRowsarr.map((row, i) => {
	 
	 strsubtaotal=parseFloat(strsubtaotal)+parseFloat(row.op_grossamount)
		
		strincgrossval= parseFloat(row.op_srateinc)*parseFloat(row.op_quantity)
		
		strincgrossamt = parseFloat(strincgrossamt)+parseFloat(strincgrossval)
       
        strgrandtotal=parseFloat(strgrandtotal)+parseFloat(row.op_grossamount)
		strcurnttax=row.op_tax_value;
			  productdiscount=parseFloat(productdiscount)+ parseFloat(row.op_proddiscountval) 
			 
			  if(strcurnttax!==previoustax && previoustax!=="")
			  {taxablevalue=0;
				 

previoustax=row.op_tax_value ;
  
			  } 
		let strtax=parseFloat(row.taxablemt);
				strtax=((parseFloat(strtax) * (100 / (100 + parseFloat(((row.op_tax_value))) ))))*row.op_quantity;
			 taxablevalue=Number(taxablevalue)+parseFloat(strtax);
		
			taxamount=Number(taxamount)+ parseFloat(taxablevalue);
				  
			totalamount=parseFloat(taxablevalue)+(parseFloat(taxamount)*2)
			
		let j=Number(i)+1
	    let k= resp.data[index].subinvoice.length;
	 
	 if(i===0)
	 {
	 strcontent+=' <div style="margin-top:60px;height: 265px"><table style="width:100%;font-size:12px" cellPadding="0" cellSpacing="0">'
	 
	 }
	 
	 
	 strcontent+=' <tr   key={index} >';

 strcontent+='<td style="width:30px;padding-left:45px" align="left" >'+row.op_quantity+'</td>';
strcontent+='<td   style="width:153px;padding-left:65px"  align="left" >'+row.op_title+'</td>';
strcontent+='<td ><p style="width:90px;padding-left:30px;text-align:left" >'+row.op_srateinc+' ('+row.op_tax_value+'%)</p></td>';
strcontent+='<td style="width:113px" align="right" >'+strincgrossval.toFixed(2)+'</td></tr>';

if(k===j && k<8)
	 {
	
		strcontent+='</table></div>'; 
	 }
if(j===k)
{
	
	if(j !== 0 && k>8)  {
		strcontent+='</table></div>';
	}
	 strcontent+=' <tr  ><td colSpan="4" align="right" >';
strcontent+='<table style="width:100%;margin-top:0px;margin-left:20px;font-size:12px;fontFamily:Arial" >';
	
	
strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:132px" ></td>';
strcontent+='<td  style="width:96px;padding-left:22px;text-align:right"  >Sub Total</td>';
strcontent+='<td style="width:113px" align="center" >'+strincgrossamt.toFixed(2)+'</td></tr>';

	

orddisval=parseFloat(this.state.discount)-parseFloat(productdiscount)
if(productdiscount !== 0 ){

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';

strcontent+='<td   style="width:96px" ,"padding-left:22px;text-align:right"  >Product Discount</td >';
strcontent+='<td style="width:113px" align="center" >'+parseFloat(productdiscount).toFixed(2)+'</td ></tr>';

}

if( this.state.orddiscount!=="0" ){

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';

strcontent+='<td   style="width:96px" ,"padding-left:22px;text-align:right"  >Special Discount</td >';
strcontent+='<td style="width:113px" align="center" >'+parseFloat(orddisval).toFixed(2)+'</td ></tr>';

}

strcontent+='<tr><td style="width:46px"></td><td  style="width:153px;margin-left:20px" ></td>';
strcontent+='<td  style="width:96px;padding-left:22px;text-align:right"  >'+parseFloat(taxablevalue).toFixed(2)+'</td ><td style="width:113px" align="center"></td ></tr>';

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';
strcontent+='<td   style="width:96px;padding-left:22px;text-align:right"  >'+(parseFloat(rows.or_taxamount)/2).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px" align="center"></td ></tr>	';
	

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';
strcontent+='<td   style="width:96px;padding-left:22px;text-align:right"  >'+(parseFloat(rows.or_taxamount)/2).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px" align="center" ></td ></tr>';
	
	
strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td><td  style="width:96px"  align="center">'+Math.floor(parseFloat(strincgrossamt)-parseFloat(rows.or_discount)-parseFloat(rows.or_roundoff)).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px"  align="center" >'+Math.floor(parseFloat(strincgrossamt)-parseFloat(rows.or_discount)-parseFloat(rows.or_roundoff)).toFixed(2)+' </td>';
strcontent+=' </tr></table ></td></tr>';
	
}
	if(j !== 0 && k>8)  {
	
	
	if(j % 8 == 0 ){
strcontent+='</table></div><div className="page-break" style="margin-top:330px;height:265px" >';
strcontent+='<table style="width:100%;font-size:12px" cellPadding="0" cellSpacing="0">';

}

	


	}
	 });

 
   strmaincon+=strcontent;
  
   
  
 
  
  strmaincon+='</td> </tr>  <tr> <td></td></tr></table>		   <div className="page-break" style="margin-top:0px" ></div>';
  
  });
	
	this.setState({ displayprod: strmaincon});
     }
 
    });
}

opviewmore(sessionid){

	var stramount=0, strdiscount=0;
	// axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_id='+opid)
	 
	 axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_sessionid='+sessionid)
  
	 
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  opviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let previoustax="",strcurnttax="";
			let strsubtaotal=0,taxablevalue=0,taxamount=0,totalamount=0,strcontent="",strincgrossval=0,strincgrossamt=0,strgrandtotal=0;
		var strRowsarr=resp.data;

		
	strRowsarr.map((row, i) => {
	 
	 strsubtaotal=parseFloat(strsubtaotal)+parseFloat(row.op_grossamount)
		
		strincgrossval= parseFloat(row.op_srateinc)*parseFloat(row.op_quantity)
		
		strincgrossamt = parseFloat(strincgrossamt)+parseFloat(strincgrossval)
       
        strgrandtotal=parseFloat(strgrandtotal)+parseFloat(row.op_grossamount)
		strcurnttax=row.op_tax_value;
			   
			 
			  if(strcurnttax!==previoustax && previoustax!=="")
			  {taxablevalue=0;
				 

previoustax=row.op_tax_value ;
  
			  } 
		
		let strtax=parseFloat(row.taxablemt)-(parseFloat(row.taxablemt) * (100 / (100 + parseFloat(((row.op_tax_value)/2)) )));
	
		 taxablevalue=Number(taxablevalue)+(parseFloat(row.taxablemt)-(parseFloat(strtax)*2));
			taxamount=Number(taxamount)+ parseFloat(strtax);
				  
			totalamount=parseFloat(taxablevalue)+(parseFloat(taxamount)*2)
			
		let j=Number(i)+1
	    let k= resp.data.length;
	 
	 if(i===0)
	 {
	 strcontent+=' <div style="margin-top:60px;height: 265px"><table style="width:100%;font-size:12px" cellPadding="0" cellSpacing="0">'
	 
	 }
	 
	 
	 strcontent+=' <tr   key={index} >';

 strcontent+='<td style="width:30px;padding-left:45px" align="left" >'+row.op_quantity+'</td>';
strcontent+='<td   style="width:153px;padding-left:65px"  align="left" >'+row.op_title+'</td>';
strcontent+='<td ><p style="width:90px;padding-left:30px;text-align:left" >'+row.op_srateinc+' ('+row.op_tax_value+'%)</p></td>';
strcontent+='<td style="width:113px" align="right" >'+strincgrossval.toFixed(2)+'</td></tr>';
if(k===j && k<8)
	 {
		 
		strcontent+='</table></div>'; 
	 }
if(j===k)
{
	
	if(j !== 0 && k>8)  {
		strcontent+='</table></div>';
	}
	 strcontent+=' <tr  ><td colSpan="4" align="right" >';
strcontent+='<table style="width:100%;margin-top:0px;margin-left:20px" >';
	
	
strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:132px" ></td>';
strcontent+='<td  style="width:96px;padding-left:22px;text-align:right"  >Sub Total</td>';
strcontent+='<td style="width:113px" align="center" >'+strincgrossamt.toFixed(2)+'</td></tr>';

	

if(this.state.discount !== "0" ){

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';

strcontent+='<td   style="width:96px" ,"padding-left:22px;text-align:right"  >Discount</td >';
strcontent+='<td style="width:113px" align="center" >'+parseFloat(this.state.discount).toFixed(2)+'</td ></tr>';

}

strcontent+='<tr><td style="width:46px"></td><td  style="width:153px;margin-left:20px" ></td>';
strcontent+='<td  style="width:96px;padding-left:22px;text-align:right"  >'+parseFloat(taxablevalue).toFixed(2)+'</td ><td style="width:113px" align="center"></td ></tr>';

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';
strcontent+='<td   style="width:96px;padding-left:22px;text-align:right"  >'+parseFloat(this.state.taxgst).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px" align="center"></td ></tr>	';
	

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';
strcontent+='<td   style="width:96px;padding-left:22px;text-align:right"  >'+parseFloat(this.state.taxgst).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px" align="center" ></td ></tr>';
	
	
strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td><td  style="width:96px"  align="center">'+Math.floor(parseFloat(strincgrossamt)-parseFloat(this.state.discount)-parseFloat(this.state.roundoff)).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px"  align="center" >'+Math.floor(parseFloat(strincgrossamt)-parseFloat(this.state.discount)-parseFloat(this.state.roundoff)).toFixed(2)+' </td>';
strcontent+=' </tr></table ></td></tr>';
	
}
	if(j !== 0 && k>8)  {
	
	
	if(j % 8 == 0 ){
strcontent+='</table></div><div className="page-break" style="margin-top:330px;height:265px" >';
strcontent+='<table style="width:100%;font-size:12px" cellPadding="0" cellSpacing="0">';

}

	


	}
	 });
	
	this.setState({ displayprod: strcontent});

	 
	
     }
   document.getElementById('print-button').click();
    });
	
}
		
		
 render() {
	
let displayprod=this.state.displayprod;
	 /*let search = window.location.search;
let params = new URLSearchParams(search);*/
let j=0,k=0,strmargin=0;
let search = window.location.href;
let invoiceno = this.getParameterByName('invoiceno',search);

let subtaotal = 0;
let grandtotal = 0;
let dis = 0;
let incgrossamt = 0;


let incgrossval=0;

	  
  return ( 
  
  
   
 



<div className="printfont" style={{"fontSize":"10px"}}  >


 <div dangerouslySetInnerHTML={ { __html: displayprod}} />
 
  

  
 
  
  
   </div> 
  
  
  
  
 
  
  
   
 
 
 	) ;

 }
}
 
		
 class Printfeedetailscopy extends Component {
	

	 
	  constructor(props) {
        super();
        this.state =  {view:false}
			
			
		
	  }
   printcall = (e) => {
	//alert(this.props.id);
	
  document.getElementById('print-button').click();
	 
}

    render() {
	
	
      return (
	
 <React.Fragment>
        <div className="printdetails">
		 <div className="printdetails1">
		 
		<InvoicePrintpreview ref="InvoicePrintpreview" fd={this.props.fdate1} td={this.props.tdate1}  ref={el => (this.componentRef = el)} /> 
         
	   </div>
	   
         
  <p>  <ReactToPrint 
            trigger={() => <p id="print-button" className="Primarybuttons">Print </p>}
			content={() =>  this.componentRef} />
            
         
		 
		 
		 
		 
		 
		 
		 
		 
		 
		 </p>
        </div>
		 </React.Fragment>
      );
    }
  } 
  

 
	