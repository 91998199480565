import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';

import PropTypes from 'prop-types';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import configurationblack from './images/configurationblack.png';




export default class configuration extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] 
      }
	 };
    
   
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  




componentDidMount() {
	
	document.title = "Configuration";
	 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2" )
{
	

}else
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
	}

	
	
	
 
  this.apicall(); 

}



apicall(){
	
	 axios.get(Serverurl+"ajaxdashboard.php?action=list")
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  
    });
}



  
render() {
	
if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    }
  	 
    
  return (
  
  
    <Page layout="public">

<Section slot="menu"><a href='#/configuration'> / Configuration</a>
</Section>

<Section slot="btn"><a href='#/configuration'>Configuration</a>
</Section>

<Section slot="content">
  
  
   <React.Fragment>
   
   
   
 <div className="main">

		        

<div className="ConfigurationS">
<ul>
<h5><img src={configurationblack} />  Configuration</h5>
<li><a href='#/organization'>Organization</a></li>
<li><a href='#/master'>Master</a></li>
<li><a href='#/adminstate'>State / Country</a></li>
<li><a href='#/taxes'>Taxes</a></li>
<li><a href='#/Paymentterms'>Payment Terms</a></li>
</ul>
</div>

		<div className="clear"></div>  			
				

 

   
 </div>

  
   
  
   
 </React.Fragment>
		
		  
	 </Section></Page>	 


  );
  }	
   
   


}