import React ,{Component} from 'react';


import { Page, Section } from 'react-page-layout';


import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';



import ReactToPrint from 'react-to-print';

import Barcode from 'react-barcode';



 
export default class Productbarcode extends Component {
	
	constructor(props) {
    super();
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',barprice:0,StoreData:[] ,id:'',Productcode:'',salesinclusive:0
	 

      }
	 };


 
 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

componentDidMount() {
 
if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2")
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}
	
 /*
   let search = window.location.search;
let params = new URLSearchParams(search);
let prid = params.get('prid');
*/



let search = window.location.href;
let prid = this.getParameterByName('prid',search);


if(prid!=null)
{
	
	this.onloadproductbyid(prid); 
	
	
}




}





  
  
  
onloadproductbyid(prid){
		
		 axios.get(Serverurl+'ajaxproduct.php?action=list&pr_id='+prid)
  .then(resp => {  
   if(resp.data.length>0){
	 
    this.setState({ 
   
	Productcode:resp.data[0].pr_code, 
   
	salesinclusive:resp.data[0].pr_salesrateincl,
	barprice:resp.data[0].barprice,
	name:resp.data[0].subprname,
	
	
	
  
	});
	
   }
    document.getElementById('print-barcode').click();
  });

} 



	

render() {
	if (this.state.redirect === true) {
      return <Redirect to='/adminproduct' />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	
	
	return (
	
	<Page layout="public">

<Section slot="menu"><a href='#/configuration'>Configuration</a> /  Product
</Section>

<Section slot="btn"><a href='#/adminproduct'>Back</a>
</Section>

<Section slot="content">
	
	
	


	
<div className="productbarcode" >
<div ref={el => (this.componentRef = el)} style={{width: "192px",height:"96px" ,textAlign: "center", margin: "auto"}}>
<h3 style={{margin: "0"}}>Rashinkar's </h3>

 
<div style={{}}>
<Barcode value={this.state.Productcode} displayValue="false" format="CODE128" textAlign="center" width="2" height="50"   margin= "1"/>
</div>



<h4  style={{marginBottom: "5px"}}>{this.state.name}</h4>
<h3 style={{textAlign: "center"}}>
	{this.state.barprice!== this.state.salesinclusive && (
<React.Fragment>
<span className="price">Rs.{this.state.salesinclusive}</span> &nbsp;&nbsp;Rs.{this.state.barprice}
</React.Fragment>)}
	{this.state.barprice=== this.state.salesinclusive && (
<React.Fragment>
Rs.{this.state.barprice}
</React.Fragment>
)}
</h3>
</div>

<br/>
<br/>

<br/>
<br/>
<ReactToPrint
            trigger={() => <p id="print-barcode" className="Primarybuttons"> Print Barcode</p>}
            content={() => this.componentRef}
          />
</div>

<div className="clear"></div>



	 
	 </Section></Page>		
		  
		 
		  




  );
  }
}











