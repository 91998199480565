import React ,{Component} from 'react';
import { Redirect  } from 'react-router-dom';


export default class Logout extends Component {
	
	constructor(props) {
    super(props);	
	 
	 this.state = {redirect:false,pagename:'', };
	 };
	
	componentDidMount() {

		localStorage.removeItem("Ruserid");
		localStorage.removeItem("Rusername");
		localStorage.removeItem("Rpassword");
		
		
	if(localStorage.getItem("Ruserid")===null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2" || localStorage.getItem("Rrole") === "4")
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}
	}
	
	
render() {
	  if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }

return (
  <React.Fragment>
 
   </React.Fragment>
      );

    }
   }
 
