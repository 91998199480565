import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import Uploadsurl from './Apiurl';
import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';



import ReactToPrint from 'react-to-print';


let strCus=[{title:'',year:0}];
const strCusdeflt=[];






function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [


{ id: 'createddate', numeric: false,  label: 'Date' },
{ id: 'or_invoice', numeric: false,  label: 'Particulars' },
{ id: 'subtotal', numeric: false,  label: 'Payment' },
{ id: 'recsubtaol', numeric: false,  label: 'Receipt' },







]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class  Customerbillreport extends Component {
	
	
	constructor(props) {
    super();
	
	
	var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:50,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'or_id',name:'',or_id:0,invoice:'',customer:'',date:'',
	product:'',searcharray:[],exportData:[],
	 
	date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,
  btndisabled:false,
submitbtn:"Submit", 	 orlogo:'',orphonenum:'',
  
	  
	 
        
      }
	  
	  this.onTagsChange = this.onTagsChange.bind(this);
	  
	 };
	 
	 
handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }	 
	 
	
handtoChange = (date) => {
	  var month="",date1="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }

	
	 
onloadCustomerBind=(event)=>{
	// axios.get(Serverurl+'ajaxcustomer.php?action=searchextender')
	  axios.get(Serverurl+'ajaxcustomer.php?action=searchextender&cu_contactname='+event.target.value)

  .then(resp => { 
  this.setState({ searcharray:resp.data});

	 var rowsArray1 = this.state.searcharray;
	 rowsArray1.map((row, k) => {
			 
		 const items = {
			 
        title:row.title,
         year:row.year
          
        };
         strCus.push( items)
		
		 });
  });
  
  
}		 

	 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  
  localStorage.removeItem("fromdate");
   localStorage.removeItem("todate");
  
  this.setState({[nam]: val});

 } 
 
handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
  
	let customer = this.state.customer;
	
	let fdate = this.state.fdate1;
	let tdate = this.state.tdate1;
	
	
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
} 


 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

componentDidMount() {
	
	document.title = "";
	
		
if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "4" || localStorage.getItem("Rrole") === "2")
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}  
 localStorage.setItem("page",this.state.rowsPerPage);
 

this.orgviewmore();

}	 




orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	this.setState({		
    orname:resp.data[0].or_name,	
	oraddress:resp.data[0].or_address, 
   
	orcity:resp.data[0].or_city,
	oremail:resp.data[0].or_email,
	ormobilenum:resp.data[0].or_mobilenum,
	orpannum:resp.data[0].or_pannum,
	
	orgstinnum:resp.data[0].or_gstinnum,
	orlogo:resp.data[0].or_logo,
	orphonenum:resp.data[0].or_phonenum,
	
	 }); 
	 
     }
 this.apicall(this.state.page,this.state.rowsPerPage); 
    });
}



apicall(page,rowsPerPage){
	
	this.setState({btndisabled:true,submitbtn:"Processing..."})	
	
	axios.get(Serverurl+'ajaxcustomer.php?action=datewisepaymentreport&cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&sorting='+this.state.orderBy +' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  	
	//axios.get(Serverurl+'ajaxdailystock.php?action=stockreport&pr_name='+this.state.product+'&sorting='+this.state.orderBy +' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[50,100,150];
		
			/*for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}
			*/
	
	  this.setState({rowsPaging:copy,btndisabled:false,submitbtn:"Search"});
	  
	 
     }
	 else{
		 this.setState({ StoreData:[],
		 totaldata:0,
		 btndisabled:false,submitbtn:"Search"})
		 
	 }
  
    });
	
	this.exportapicall();
	
}

exportapicall(){
	
	
	axios.get(Serverurl+'ajaxcustomer.php?action=datewisepaymentreport&cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			 exportData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
			
	
	 
	  
	 
     }else{
		 this.setState({  
			 exportData: []}); 
	 }
  
    });
}





handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
}


onTagsChange = (event, values) => {
	
	let optionsval= values["year"];
	let optionsval1= values["title"];

	
	 
    this.setState({
      custtags: values,
	  customer: values["year"]
	   //customer: values["title"]
	   
	   
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
  }




render() {
	
	return (
	
	
	 <Page layout="public">

<Section slot="menu"> / Reports / Customer Report
</Section>

<Section slot="btn"><a href='#/Reports'>Reports</a>

<ReactToPrint
            trigger={() => <p id="print-barcode" className="printreport"> Print </p>}
            content={() => this.componentRef}
          />
 <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="expbtn"
                    table="purreport"
                    filename="Customer Bill Report"
                    sheet="Customer Bill Report"
                    buttonText="Export"/>	




</Section>

<Section slot="content">
  
   <React.Fragment>
   
   
    <div className="main">
	
	<br></br>
 
 <div className="search">
 
  <ul>
  
  <li><p>From :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
        value={this.state.fdate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
	id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</li>
 
 <li><p>To :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
      <DatePicker fullWidth
        value={this.state.tdate || addDays(moment().toDate(),1)}
		maxDate={addDays(moment().toDate(),0)}
        onChange={this.handtoChange}
            format="dd/MM/yyyy"
        autoOk name="tdate"
        ampm={false}
		id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	
		</li>
 
 
 <li className="lialignment"> <Autocomplete
       
        
      
       margin="normal"
      
            fullWidth
	   options={this.state.searcharray}
        getOptionLabel={option => option.title}
		//options={strCus}
   //defaultValue={strCusdeflt}
	  onChange={this.onTagsChange}  
        renderInput={params => (
          <TextField {...params} onChange={this.onloadCustomerBind}  variant="outlined" id="search" placeholder="Customer" />
        )}
      /></li>

 
 
 </ul>
		
	
	
	<br/>
	
	<p className='submitrep'><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="searchform"
            className={useStyles.submit}
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
			
          >
            {this.state.submitbtn}
          </Button>	</p> 
	
	<br/>
	<br/>
	
	</div>
	
	
	
		
<div style={{"display":"none"}}>	
<Paper className={useStyles.root}>



 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table" id="purreport">  

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.exportData.length}
            />
                      <TableBody>

                     {this.state.exportData.map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >





<TableCell>{item.createddate}</TableCell>
<TableCell>{item.or_invoice}</TableCell>


<TableCell> {Math.floor(item.subtotal).toFixed(2)}</TableCell>

<TableCell>{item.recsubtaol}</TableCell>







	

 </TableRow>


				</React.Fragment>	
				);
					})}
                      </TableBody>
					  
					  
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="4">No Records</TableCell>
					</TableRow>
					) }		  
					  


</Table>
  </TableContainer> 
  

  
    
  </Paper>
	
	</div>
	
<div className="">
	
		<div ref={el => (this.componentRef = el)} >
	
	 <div className="reportheading">
		
       <b>CUSTOMER REPORT</b>
           </div>
		   
		<div className="repborder">   
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  	
	
	
	
	<TableRow >  
<TableCell colSpan={2} >


<div className="reportmain">
<div className="repfromdetails">


	<p><b>{this.state.orname}</b><br/>
	{this.state.oraddress} <br/> 
	{this.state.orcity} <br/>

{this.state.orgstinnum}<br/>
	{this.state.orphonenum} , {this.state.ormobilenum}

	<br/>{this.state.oremail}</p>
	</div>
	
	
	

	
	<div className="replogo">
	
	{this.state.orlogo!=="" &&(
 <p> <img  src={Uploadsurl+"uploads/"+this.state.orlogo} /> </p>
 )}
 
	</div>
	<div clssName="clear"></div>
	
	
	</div>
	</TableCell>
	
	


	
	
	
	
	
 </TableRow> 
 

 </Table>
 </TableContainer>
 </Paper>
 
 </div>
	
	
<Paper className={useStyles.root}>

<div className="main">

 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.StoreData.length}
            />
                      <TableBody>

                     {stableSort(this.state.StoreData, getSorting(this.state.order, this.state.orderBy)).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >





<TableCell>{item.createddate}</TableCell>
<TableCell>{item.or_invoice}</TableCell>


<TableCell>Rs. {Math.floor(item.subtotal).toFixed(2)}</TableCell>

<TableCell>{item.recsubtaol}</TableCell>







	

 </TableRow>


				</React.Fragment>	
				);
					})}
                      </TableBody>
					  
					  
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="4">No Records</TableCell>
					</TableRow>
					) }		  
					  


</Table>
  </TableContainer> 
  
  </div>
  
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
   
 </div>

   </div></div>
 </React.Fragment>
		

		 </Section></Page>	 	


);
 
  }	
   
   

}
 
		 