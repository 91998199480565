import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  

import { Page, Section } from 'react-page-layout';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';


import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import DateFnsUtils from '@date-io/date-fns';
import TableBody from '@material-ui/core/TableBody'; 
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';




let strCust=[{title:'',year:0}];



const strProduct=[];
const strProductdeflt='';


const useStyles = makeStyles(theme =>({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
 
form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
})
)



export default class Addpurchasebillorder extends Component {
	
	
	constructor(props) {
    super();
	var today = new Date();
	var todayMonth=(today.getMonth() + 1);
	if(todayMonth<10)
		todayMonth='0'+todayMonth;
    var        cudate = today.getFullYear() + '-' + todayMonth + '-' + today.getDate();
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],invoicearray:[],invoice:'18',invoicelabel:'Select Invoice Type',invoiceno:'',paymentarray:[],fdate:Date.now(),fdate1:cudate,gstin:'',salespersonarray:[], salesperson:'0',adminData:[],custtags:'',
	 custarray:[],stat:'0',statarray:[],dest:'0',scancode:'',pvdata:[],taxdata:[],taxdatacount:0,
	 barcodeval:'',salesrateexcl:'',salesrateincl:'',orddiscount:0,qty:'',newpcid:"-1",ordgst:0,
amount:'',produserarray:[],taxarray:[],tax:'',barcodedata:[],prdextndata:[],payment:'',showField:-1,
customer:[],productselectdval:[],productselectdtags:'',redirect:false,roundoff:0,billingassress:'',shippingaddress:'',
	
	 pr_salesrateexcl:'',pr_salesrateincl:'',prodarrauto:[],pr_tx_id:'',orddiscounttype:1,editclick:0,selectedproduct:'',
subtotal:0,roundoffval:0,discountval:'',ordtotaldiscount:0,salesdata:[],grandtotal:0,
salespersonname:'',dcus:'',errormessage:'',open:false,selectedcustomerid:'',paymentname:'',orderproduct:0,billno:'',refno:'',
rdate:Date.now(),rdate1:cudate,ddate:Date.now(),ddate1:cudate,
 btndisabled:false,
submitbtn:"Submit",pureditData:[],

	        
      }
	  
	  this.onTagsChange = this.onTagsChange.bind(this);
	    this.insertDonenewParent = this.insertDonenewParent;
	   this.onTagsproductChange = this.onTagsproductChange.bind(this);
	  this.escFunction = this.escFunction;
	   this.escSalesFunction = this.escSalesFunction;
	   
	    this.ChildElement = React.createRef();
	 };
	 
	 
 insertDonenewParent =(text)=>{
	 
	 
	//alert(localStorage.getItem("newcuid")) 
   this.onloaduserbyid(localStorage.getItem("newcuid"));
	 
 }

 Useraddbox(val){
       // this.setState({ cuid: val });  
  
         setTimeout(() => this.refs.Newcustomer.handleClickOpen(),500);

      }
componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
 // this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


componentDidMount() {
	
	let search = window.location.href;
let poid = this.getParameterByName('poid',search);
if(localStorage.getItem("Ruserid")===null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2" || localStorage.getItem("Rrole") === "4")
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}
	


if(poid!=="" && poid!==null)
{
	
	 this.Purchaseorderbyid(poid);
}
else{
		this.purchasebillnocall();
		
}

 localStorage.setItem("page",this.state.rowsPerPage);
 // this.apicall(this.state.page,this.state.rowsPerPage); 
 

 document.addEventListener("keydown", this.escFunction, false);
  document.addEventListener("keydown", this.escSalesFunction, false);
	}
	
	
	
	componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
	document.removeEventListener("keydown", this.escSalesFunction, false);
  }
  
  myChangeHandler = (event) => {
	
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
if(nam==="payment" && val!=="")
  {
	// alert(val)
	  let idx = event.target.selectedIndex;
	 let paymentname=event.target.options[idx].dataset.pname;
	//  alert(paymentname)
	  
	  this.setState({["paymentname"]: event.target.options[idx].dataset.pname});

//alert(this.state.paymentname)
  }

}


Purchaseorderbyid(poid){
	
axios.get(Serverurl+'ajaxpurchasebillorders.php?action=purchaseeditbypoid&po_id='+poid)
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		this.onloaduserbyid(resp.data[0].po_cu_id)	;
	setTimeout(() => {  	this.setState({  
		
		pureditData:resp.data,
		billno:resp.data[0].po_billno,
		fdate:resp.data[0].purchasedate,
		refno:resp.data[0].po_referenceno,
		rdate:resp.data[0].referencedate,
		payment:resp.data[0].po_paymentterms,
		ddate:resp.data[0].duedate,
		dest:resp.data[0].po_destination,
		 customer: resp.data[0].cu_contactname,
		orddiscount:resp.data[0].po_orddiscountval,
orddiscounttype:resp.data[0].po_orddiscounttype,  
			roundoff:resp.data[0].po_roundoff,    
			});}, 800);
		
	}
  });	
	
}























  
	purchasebillnocall(){
		
axios.get(Serverurl+'ajaxpurchasebillorders.php?action=generatebillno')
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
			this.setState({  
			 // adminData: resp.data,
              billno: resp.data[0].billno			  
              //invoiceno: "IN/"+resp.data[0].ao_type+"/"+nxt,
              			  
			   
			});
			
			this.onloaduserbyid(resp.data[0].ao_type);
	
	 
	  
	 
     }
  
    });
	
		
	}
	

onloaduserbyid(cuid){
		
		 axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
   if(resp.data.length>0){
	   const items = {
        title:resp.data[0].cu_contactname,
          year:resp.data[0].cu_id,
          email:resp.data[0].cu_email,
		   mobileno:resp.data[0].cu_mobileno,
		       rowindex:1,
			   billingassress:resp.data[0].cu_billingassress,
			   shippingaddress:resp.data[0].cu_shippingaddress,
			   gstin:resp.data[0].cu_gstinno,
			   payment:resp.data[0].cu_payment,
			   dest:resp.data[0].cu_pl_id,
			
        };
 
       
    this.setState({ 
	
	selectedcustomerid:resp.data[0].cu_id,
   	 	custtags:resp.data[0].cu_contactname,
	   customer: items,
	billingassress:resp.data[0].cu_billingassress,
	shippingaddress:resp.data[0].cu_shippingaddress,
	gstin:resp.data[0].cu_gstinno,
	payment:resp.data[0].cu_payment,
	dest:resp.data[0].cu_pl_id,
	
	
	
	
  
	});
	
   }
   //this.invoicebind();

this.paymentbind();   
  });

} 


	
paymentbind(){
	
	
	 axios.get(Serverurl+"ajaxpaymentterms.php?action=paymentbind&type=0")
    
      .then((response) => {    
     
      this.setState({  paymentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.paymentarray.map(payment => {
                            
          return {value: payment.pt_id, display:payment.pt_title}
        });
      
		
        this.setState({
          paymentarray: [{value: '0', display: 'Select payment Type'}].concat(roleFromApi)
        });
      })
	this.Statebind();
	
	
}


Statebind(){
	
	
	 axios.get(Serverurl+"ajaxstate.php?action=list")
    
      .then((response) => {    
     
      this.setState({  statarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.statarray.map(stat => {
                            
          return {value: stat.pl_id, display:stat.pl_name}
        });
      
		
        this.setState({
          statarray: [{value: '0', display: 'Select State'}].concat(roleFromApi)
        });
      })
	
	 this.taxbind();
	
}


 
 onloadProductsBind=(event)=>{
	 if(event.target.value.length>3 ){
 axios.get(Serverurl+'ajaxproduct.php?action=productautoextendervendor&pr_name='+event.target.value)
  .then(resp => { 
  this.setState({ produserarray:resp.data });
  
  
//alert(JSON.stringify(this.state.produserarray))



	 var rowsArrayp = this.state.produserarray;
	 rowsArrayp.map((row, j) => {
			 
		 const items = {
			 
        title:row.title,
         year:row.year,
		 pr_name:row.pr_name,
		 pr_salesrateexcl:row.pr_salesrateexcl,
		 pr_salesrateincl:row.pr_salesrateincl,
		 tax:row.tax,
		 stock:row.stock,
		 pr_tx_id:row.pr_tx_id,
		  qty:row.qty,
           rowindex:row.rowindex,
		   pr_code:row.pr_code,
		   pr_unit:row.pr_unit,
		   discount:row.discount,
        };
         strProduct.push(items)
		
		 });
		 this.setState({prodarrauto:strProduct})
  });
 
	 }
}
 
 

taxbind(){
	
	
	 axios.get(Serverurl+"ajaxtaxes.php?action=list")
    
      .then((response) => {    
     
      this.setState({  taxarray: response.data,  });
	  
	  this.UsergetPVdata();
      })   
    
      .then(data => {
        let roleFromApi = this.state.taxarray.map(tax => {
                            
          return {value: tax.tx_id+"~"+tax.tx_rate, display:tax.tx_rate}
        });
      
		
        this.setState({
          taxarray: [{value: '0', display: 'Select Tax'}].concat(roleFromApi)
        });
      })
	

	
}

 


onloadCustomerBind(){
	 strCust=[{title:'',year:0}];
	 axios.get(Serverurl+'ajaxcustomer.php?action=vendorautoextender')
  .then(resp => { 
  this.setState({ custarray:resp.data});

	 var rowsArray = this.state.custarray;
	 rowsArray.map((row, i) => {
			 
		 const items = {
        title:row.title,
          year:row.year,
          email:row.cu_email,
		   mobileno:row.cu_mobileno,
		       rowindex:row.rowindex,
			   billingassress:row.cu_billingassress,
			   shippingaddress:row.cu_shippingaddress,
			   gstin:row.cu_gstinno,
			   payment:row.cu_payment,
			destid:row.destid,
        };
         strCust.push( items)
		  
		 });
  });
  

  
	
}






handfrChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }
  

 handrefChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ rdate:date,
rdate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }

handdueChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ ddate:date,
ddate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }


handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};



handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });
	
	 
   
  }; 


handleDiscard= (event) => {
	
	window.location.reload();
}

handleSubmit= (event) => {
	
	
	let search = window.location.href;
let poid = this.getParameterByName('poid',search);
    
    event.preventDefault();
	
   
     let taxamt = this.state.ordgst;
    let billno = this.state.billno;
	let refno = this.state.refno;
	let fdate1 = this.state.fdate1;
	let payid = this.state.payment;
	let ddate1 = this.state.ddate1;
	let rdate1 = this.state.rdate1;
	let selectedcustomerid = this.state.selectedcustomerid;
	let orddiscount = this.state.orddiscount;
		let orddiscounttype = this.state.orddiscounttype;
	let dest = this.state.dest;
	//alert(dest)
	
	  let termsid = this.state.paymentname;

	  
	
	 
	 
	  if(payid === "0")
	 {
		
		 this.setState({errormessage:"Please Select Payment Terms",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	 
	 
	 
	 
	   if(selectedcustomerid === "0")
	 {
		 
		 this.setState({errormessage:"Please Select Customer",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	  
	 
	 
	   if(dest === "0")
	 {
		
		 this.setState({errormessage:"Please Select Destination State",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	 
	 this.setState({btndisabled:true,submitbtn:"Processing..."})	
var formData = new FormData();
	
		formData.append('action','purchasebillordersinsert') 
	
	
	 if(poid === "" || poid === null)
	  {
		   formData.append('po_id',0)
	  }
	  else{
		  formData.append('po_id',poid)
	  }
	
	
	
	formData.append('po_billno',billno)
		formData.append('po_referenceno',refno)
		formData.append('po_paymentterms',payid)
		formData.append('po_cu_id',selectedcustomerid)
		//formData.append('or_source',stat)
		formData.append('po_destination',dest)
		formData.append('po_duedate',ddate1)
		formData.append('po_referencedate',rdate1)
		
		formData.append('po_taxamount',taxamt)
		formData.append('po_discount',this.state.ordtotaldiscount)
		formData.append('po_tx_id',0)
		formData.append('po_roundoff',this.state.roundoff)
		formData.append('po_orddiscounttype',orddiscounttype)
		formData.append('po_orddiscountval',orddiscount)
		formData.append('paymentname',termsid)
		
		let optionsVal = this.state.pvdata;
		let sep1="";
		let optionValues="";
		let index=0;
		
var srearray=optionsVal.filter(value => value.op_isdeleted  < 1)

if(srearray.length-1 === 0)	
{
	 this.setState({errormessage:"Please Select Product ",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
}
		
		
	 
	 
    optionsVal.forEach(eachval => {
     
		   if(index!=0)
		   {
	   if(eachval.op_id<0 && eachval.op_isdeleted===1)
	   {
		   
	   }
	   else
	   {
 
 
   
 var strtax=eachval.pr_tx_id.split('~');
 optionValues=optionValues+sep1+eachval.pr_code+"~"+eachval.title+"~"+eachval.year+"~"+eachval.pr_salesrateincl+"~"+eachval.pr_salesrateexcl+"~"+eachval.tax+"~"+strtax[0]+"~"+eachval.ordwsediscount+"~"+eachval.discounttype+"~"+eachval.qty+"~"+eachval.amount+"~"+eachval.proddiscount;
 sep1="`"; 
	   }
		   
		   }
		   index=1;
		   
	 
    })
	
	
 /* optionValues="12345~product1~18~1~2~5~2~1~500,12321~product2~19~3~4~6~3~2~1000,12344~product3~20~7~8~9~40~3~1500";
		
	*/	
	
		formData.append('pp_barcode',optionValues)
	
		formData.append('userid',localStorage.getItem("Ruserid"))
		
		
		axios.post(Serverurl+"ajaxpurchasebillorders.php", formData, {
        }).then(res => {
			 if(res.data!="0"){
		
		var str=res.data;
		
  
  localStorage.setItem("RASBILpurchase",str);

 this.setState({payment:'',btndisabled:false,submitbtn:"Submit",redirect:true,pagename:'/Purchasedetails?poid='+localStorage.getItem("RASBILpurchase")})
			 }
			 else{
				 this.setState({btndisabled:false,submitbtn:"Submit"})			 }
 
   localStorage.removeItem("newcuid");
	
		});
	
} 
onInputChange = (event, newTextValue) => {

if(newTextValue!==" "){


  this.setState({custtags:newTextValue})
}
 
 }
 onInputprodChange = (event, newTextValue) => {

if(newTextValue!==" "){


  this.setState({productselectdtags:newTextValue})
}

  
 }

 onTagsChange = (event, values) => {
	 
	  
	
	console.log(values)
	  if(values!==" " && values!==null){
	 
	 
    this.setState({
    
	   customer: values,
	   selectedcustomerid:values["year"],
	    billingassress: values["billingassress"],
		 shippingaddress: values["shippingaddress"],
		 gstinno: values["gstinno"],
		 payment: values["payment"],
		 dest:values["destid"],
		
		 
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
	//alert(values["payment"])
	  }
	  
	//  alert(this.state.payment)
	  
  }
  
  
 
 
   escFunction= (event) => {
	  var self=this; 
        let nam = event.target.name;
        let val = event.target.value;
			val=val.trim();
 this.setState({[nam]: val});
 
  if(event.keyCode === 13) {

		
		if(nam==="scancode"){
			 
	setTimeout(() => {
            
				 
		
        var formData = new FormData();
		if(val!==""){
			
		
  

	
 axios.get(Serverurl+'ajaxproduct.php?action=vendorbarcodedetails&pr_code='+this.state.scancode)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			
	 let id = self.state.showField,strtotalqty=0,discal=0;			
	
 

		
		
 let purgst=Number(resp.data[0].pr_salesrateincl)-(Number(resp.data[0].pr_salesrateincl) * (100 / (100 + Number(resp.data[0].tx_rate) )));
 
 let purexc = (Number(resp.data[0].pr_salesrateincl)-Number(purgst)).toFixed(2);
 
   if(resp.data[0].discount!="0")
 {
 
  discal =((Number(purexc)*Number(resp.data[0].discount))/100);
	
 }
let strincdisamt = (Number(resp.data[0].pr_salesrateincl)*Number(1))

 if(resp.data[0].pr_unit!="2")
 {
 
 self.textRef.focus();	
 }
	
	let optionsRep = self.state.pvdata;
	
	var rowsArray = self.state.pvdata; 
	
		
let strqty=1,strsetarray=0,strsubtotal=0,streachdiscount=0,strtotalgst=0;
let strgrantotal=0,strtotaldiscount=0;


var newRow = rowsArray.sort((a, b) => a.op_id > b.op_id ? 1 : -1).map((row, i) => {
if(i!==0){  

                   
				 
					
		//alert(val);			
					
  if (row.pr_code === val && row.qty!==0) {
	  
  if(row.op_isdeleted===1){
		row.op_isdeleted=0;
		 row.qty=1;
		 //strsubtotal=0;
		
	
	
	}
	else{
		row.qty= (Number(row.qty)+Number(1));
	
	
	}
  
	
	row.amount=Number(Number(row.qty)*Number(purexc)).toFixed(2);
	  strtotalqty=1;
	
	  strsetarray=1;
	  
	  
	  

 //strsubtotal=Number(row.amount)+Number(strsubtotal);
let amt= (Number(row.pr_salesrateexcl)*Number(row.qty));
//alert(strsubtotal)
	  
	  if(row.discounttype === "2"){			
	
			
				 streachdiscount= (Number(row.discount));
				
							
			   
		   }
		   else if(row.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(row.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   }
	  
	  strtotalgst	=parseFloat(strtotalgst)+parseFloat(row.sgstamt)+parseFloat(row.sgstamt) ;  
		    strtotaldiscount=parseFloat(streachdiscount)+parseFloat(strtotaldiscount);
	  
	
//strgrantotal=Number(strsubtotal)-Number(strtotaldiscount)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)
  
 
  }}
  strsubtotal=Number(row.amount)+Number(strsubtotal);
  return row;

  });
	
	if(strsetarray === 1){
	  
  this.setState({pvdata:newRow,orderproduct:Number(this.state.orderproduct)+Number(strtotalqty),
  subtotal:strsubtotal,roundoff:0,
  ordtotaldiscount:Number(strtotaldiscount),
  scancode:'',
},()=>{
			
		setTimeout(() => this.DiscountPurCalculate(),500);		
			
			
		});
  }
  if(strsetarray === 0){
	
	
	

 optionsRep.sort((a, b) => a.op_id > b.op_id ? 1 : -1).forEach(eachrep => {
		//alert(resp.data[0].pr_tx_id)
		//alert(resp.data[0].tx_rate)
       if (eachrep.op_id === id)
	   {
		
		eachrep.title= resp.data[0].pr_name;
		   
			   eachrep.year= resp.data[0].pr_id;
			   eachrep.pr_code= resp.data[0].pr_code;
			  
			  eachrep.pr_salesrateexcl= purexc;
		       eachrep.pr_salesrateincl= resp.data[0].pr_salesrateincl;
		       eachrep.tax= resp.data[0].tx_rate;
			   eachrep.stock= resp.data[0].pr_currentstock;
			   //eachrep.pr_tx_id= resp.data[0].pr_tx_id;
			   
			  eachrep.pr_tx_id= resp.data[0].pr_tx_id+"~"+resp.data[0].tx_rate;
			   
		        eachrep.discount= resp.data[0].discount;
		       eachrep.discounttype="1";
		       eachrep.qty= "1";
			    strtotalqty=1;
			
			   eachrep.proddiscount= discal;
			  
		    eachrep.op_isdeleted= 0;
		    eachrep.unit=resp.data[0].pr_unit;
	
		   eachrep.inclamount=strincdisamt;
		  eachrep.amount=purexc;
		   
	
	}
	   
	  
    })
			
	 self.setState({  
			 pvdata:optionsRep,orderproduct:Number(this.state.orderproduct)+Number(strtotalqty),
			   totaldata:parseInt(resp.data[0].count),
			   selectedproduct:resp.data[0].pr_id,
			  scancode:'',roundoff:0,
			   
			}); 
			if(resp.data[0].pr_unit=="2")
 {
			this.fieldsave(id,"1");
 }else{
	
	 setTimeout(() => this.DiscountPurCalculate(),500);
 }
   
  }
  }
	 else{
		 
		 self.setState({ 
		
		 totaldata:0,
		 subtotal:0
		 })
	 
	 
	
	 }




});
		

		  
		  
		}
				
            
        }, 20);
	}
   
}

  }
 
 
 
 
  Onscancode = (event) => {
	  
	window.initOnScan();
			
	}
scanHandler = (event) => {
		console.log("[scanHandler]: Code: " + event.detail.code);
	}

	
 
 
    
  
onTagsproductChange = (event, values) => {
	
	//this.setState({ prodid:values["year"]})

	 let id = this.state.showField;
	
 
 let purgst=Number(values["pr_salesrateincl"])-(Number(values["pr_salesrateincl"]) * (100 / (100 + Number( values["tax"]) )));
 
 let purexc = (Number(values["pr_salesrateincl"])-Number(purgst)).toFixed(2);
 
 
 
 let strincdisamt = (Number(values["pr_salesrateincl"])*Number(1));
 
 
 let discal =((Number(purexc)*Number(values["discount"]))/100);
	
 
 
 
	
	let optionsRep = this.state.pvdata;
  optionsRep.sort((a, b) => a.op_id > b.op_id ? 1 : -1).forEach(eachrep => {
		
       if (eachrep.op_id === id)
	   {
		
		eachrep.title= values["title"];
		   
			   eachrep.year= values["year"];
			   eachrep.pr_code= values["pr_code"];
			  // eachrep.pr_salesrateexcl= values["pr_salesrateexcl"];
			 eachrep.pr_salesrateexcl= purexc;
		       eachrep.pr_salesrateincl= values["pr_salesrateincl"];
		       eachrep.tax= values["tax"];
			   eachrep.stock= values["stock"];
			   eachrep.pr_tx_id= values["pr_tx_id"]+"~"+values["tax"];
		       eachrep.discount= values["discount"];
		       eachrep.discounttype="1";
		       eachrep.qty= "1";
			    eachrep.proddiscount= discal;
			   
		    eachrep.op_isdeleted= 0;
		   
		      eachrep.unit= values["pr_unit"];
		   
		 //   eachrep.amount= Number(stramount).toFixed(2);
		     eachrep.inclamount=strincdisamt;
		    eachrep.amount= purexc;
	
	}
	   
	  
    })
  
this.setState({pvdata: optionsRep, roundoff:0})	




		
  }
  
 

 
myChangeHandlerPV = (event) => {
	
	const re = /^[0-9\b]+$/;
   let nam = event.target.name;
  let val = event.target.value;
  

  
  let strtotaldiscount="0";
  
  
  let id = this.state.showField;
  
  
  

   let optionsRep = this.state.pvdata;
   var rowsArray = this.state.pvdata; 
   
    optionsRep.forEach(eachrep => {
		
	
		
		
		 
       if (eachrep.op_id === id)
	   {
		   if(nam==="pr_salesrateexcl")
		   {
		eachrep.pr_salesrateexcl= val;
		let amt= (Number(val)*Number(eachrep.qty));
		   
		 let gstamt = ((Number(val)*Number(eachrep.tax)) /100) +Number(val);
		  eachrep.pr_salesrateincl= gstamt.toFixed(2);
eachrep.amount=amt.toFixed(2);
		   }
		   else if(nam==="pr_salesrateincl")
		   {
			eachrep.pr_salesrateincl= val;
			
			 let gstamt = Number(val)-(Number(val) * (100 / (100 + Number(eachrep.tax) )))
	
  let salexc = Number(val)-Number(gstamt)
			
			eachrep.pr_salesrateexcl= parseFloat(salexc).toFixed(2);
			
			let discal =((Number(salexc)*Number(eachrep.discount))/100);
	
		let strdisamt=(Number(salexc)-Number(discal));
 
			let excludedisamt= (Number(val)*Number(eachrep.qty));
		   let amt= (Number(strdisamt).toFixed(2))*(Number(eachrep.qty).toFixed(2));
eachrep.amount=amt.toFixed(2);
eachrep.inclamount=excludedisamt.toFixed(2);
		   }
		   
		   
		
		   
		    else if(nam==="pr_tx_id")
		   {
			   var strarr=val.split('~');
			eachrep.pr_tx_id = val;
			eachrep.tax= strarr[1];
		   }
		   
		    else if(nam==="qty")
		   {
			eachrep.qty= val;
			
			let amt= (Number(eachrep.pr_salesrateexcl).toFixed(2))*(Number(val).toFixed(2));

let discal =((Number(eachrep.pr_salesrateexcl)*Number(eachrep.discount))/100);
	
let strdisamt=(Number(eachrep.pr_salesrateexcl)-Number(discal));		  
		  let excludedisamt= (Number(eachrep.pr_salesrateincl)*Number(val));
		   let inclamt= (Number(strdisamt).toFixed(2))*(Number(val).toFixed(2));
eachrep.amount=amt.toFixed(2);
			   
		eachrep.inclamount=excludedisamt.toFixed(2);
			
			
		   }
		   
		  
			
			
			
			
		   
		      else if(nam==="discount")
		   {
			   eachrep.discount = val;
			   
			   if(eachrep.discounttype==="2")
			{   
			   
			   let rsdiscount=eachrep.discounttype;
				
	let rsamt =((Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty).toFixed(2))-Number(val));
	

	
 eachrep.amount=rsamt.toFixed(2);	
	 eachrep.inclamount=(parseFloat(eachrep.pr_salesrateincl)*parseFloat(eachrep.qty)).toFixed(2);			
				
			}
			   
			else{
				let discount=(Number(val));

	
	let disamt =Number((Number(eachrep.pr_salesrateexcl)*Number(discount))/100).toFixed(2);
	
 
 let amt=Number(Number(eachrep.pr_salesrateexcl).toFixed(2))*Number(Number(eachrep.qty).toFixed(2))-Number(Number(disamt).toFixed(2));
 
 eachrep.amount=amt.toFixed(2);
 eachrep.inclamount=(parseFloat(eachrep.pr_salesrateincl)*parseFloat(eachrep.qty)).toFixed(2);			
		
			} 
			
			
		   }
		   
		   
		   
		    else if(nam==="discounttype")
		   {
			eachrep.discounttype= val;
			
			
		   if(val==="2")
			{
				let rsdiscount=val;
				//let rsdiscount=val/this.state.orderproduct;
			//let rsamt =(Number(eachrep.pr_salesrateexcl)-Number(eachrep.discount));
	let rsamt =((Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty).toFixed(2))-Number(eachrep.discount));
	
 eachrep.amount=rsamt.toFixed(2);	
				
			}
			else{
				let discount=(Number(eachrep.discount));
				let disamt =Number((Number(eachrep.pr_salesrateexcl)*Number(discount))/100).toFixed(2);
	
		//let amt=(Number(eachrep.pr_salesrateexcl)-Number(disamt));
 let amt=Number(Number(eachrep.pr_salesrateexcl).toFixed(2))*Number(Number(eachrep.qty).toFixed(2))-Number(Number(disamt).toFixed(2));
 
 
 eachrep.amount=amt.toFixed(2);
 
			}
		
		   
		   }
		   		   else
		   {
			   
			   eachrep.amount= val;
		   }
		
	   }
	  

    })
this.setState({pvdata: optionsRep,})	
  
 // this.subtotal();

}
handlechangeroundoff = () => evt  => { 
	  var item = {    
                  id: evt.target.id,    
                  name: evt.target.name,    
                  value: evt.target.value    
              };
if(item.name==="roundoffval")
{
	
		
	 this.setState({roundoff: evt.target.value,
	 [item.name]: evt.target.value,
	 });
	

	
	
}
setTimeout(() => this.Taxcalculactionbind(),500);
	  
}
 handleboxChangee = () => evt  => {    


   try {    
              var item = {    
                  id: evt.target.id,    
                  name: evt.target.name,    
                  value: evt.target.value    
              }; 


this.setState({
[item.name]: evt.target.value,




	},()=>{
			
				
				setTimeout(() => this.DiscountPurCalculate(),700);  	
			
		});
	


		}
catch (error) {    
              console.log("Error in React Table handle change : " + error);    
          }  
		  

		
	
      };		

UsergetPVdata() {
	 
		let search = window.location.href;
let poid = this.getParameterByName('poid',search),strtotalqty=0;	
		
		let newrow={"op_id":this.state.newpcid,"year":"","title":"",
	 "pr_code":"","pr_salesrateexcl":"","pr_salesrateincl":"","stock":"","tax":"","pr_tx_id":"","discount":"","discounttype":1,"distypetitle":"%","qty":1,"amount":"","inclamount":"","op_isdeleted":0,"proddiscount":0,"ordwsediscount":"0",
	"unit":"","opstatus":"1",
	 "oldyear":"","oldtitle":"","oldpr_code":"","oldpr_salesrateexcl":"","oldpr_salesrateincl":"","oldstock":"","oldtax":"","oldpr_tx_id":"","olddiscount":"","olddiscounttype":2,"olddistypetitle":"%","oldqty":"","oldamount":""}
	
		
	  if(poid!=="" && poid!==null)
  {
	  
	   axios.get(Serverurl+'ajaxpurchasebillorders.php?action=purchaseproductlistbyppid&pp_po_id='+poid)
  .then(resp => {  
		let oldOptionspvdata=[]
    if(resp.data.length>0){
		
		  oldOptionspvdata=resp.data;//this.state.pvdata
   
	}
	
	  oldOptionspvdata.unshift(newrow);
	 
	
	 
	
	

	let strtotaldiscount="0",strgrantotal="0",streachdiscount="0",strsubtotal="0",strtotalgst=0;
let discountsum=0;

	 let optionsRep = oldOptionspvdata;
	 
	 optionsRep.forEach(eachrep => {
	 
       if (eachrep.op_isdeleted !== 1 && eachrep.year !== "")
	   {
	 
	 
	 
	  strsubtotal=Number(eachrep.amount)+Number(strsubtotal);

strtotalqty=Number(strtotalqty)+Number(eachrep.qty);
			
	   }
	 });

	
	
 this.setState({ 
	 pvdata: oldOptionspvdata ,
	 showField: this.state.newpcid,
	 subtotal:strsubtotal,
	    orderproduct:Number(strtotalqty)
	  
     
       
    
    });
   setTimeout(() => this.DiscountPurCalculate() ,500);
	
  
});


this.onloadCustomerBind();

	  
  } 
	
	else{
	
	   this.setState({ 
	 pvdata: this.state.pvdata.concat(newrow),
	  showField: this.state.newpcid ,
	taxdata: [{"taxamount":0,"cgstrate":0,"cgstamt":0,"sgstrate":0,"sgstamt":0,"totaoatax":0}] ,
	}); 
this.onloadCustomerBind();

	} 
	 
 }
 
 
 Roundoffautocalculation(){
	  
	
	let n = this.state.grandtotal;
	
let whole = Math.floor(n).toFixed(2);   

 //let fraction =  (whole - n).toFixed(2); 

	 
	
this.setState({grandtotal:whole , });
	 
 }
 

fieldedit(id,pr_id){
	
	this.setState({showField:id,editclick:1,subtotal:0,showtaxField:id })
	this.onloadproductbyid(pr_id);
}

onloadproductbyid(prid){
		
		 axios.get(Serverurl+'ajaxproduct.php?action=list&pr_id='+prid)
  .then(resp => {  
   if(resp.data.length>0){
	   
    
	
	 const proditems = {
			 
        title:resp.data[0].pr_name,
         year:resp.data[0].pr_id,
		 pr_name:resp.data[0].pr_name,
		 pr_salesrateexcl:resp.data[0].pr_salesrateexcl,
		 pr_salesrateincl:resp.data[0].pr_purchaserateincl,
		 tax:resp.data[0].tx_rate,
		 stock:resp.data[0].pr_currentstock,
		 pr_tx_id:resp.data[0].pr_tx_id,
		  qty:1,
           rowindex:1,
		   pr_code:resp.data[0].pr_code,
		   pr_unit:resp.data[0].unit,
		   discount:resp.data[0].pr_discount,
        };
	

	   this.setState({productselectdval:proditems,productselectdtags:resp.data[0].pr_name})
   }
   
   
  });

} 

/*
fielddelete(id){
	//alert()
	
	let strtotaldiscount="0",streachdiscount="0",strsubtotal="0";
	let r = window.confirm("Are you sure to Delete Product from Purchase?");
		 if(r == true){
			 
	 let optionsRep = this.state.pvdata;
	 let optionstax = this.state.taxdata;
    optionsRep.forEach(eachrep => {
		
		
		
		if(eachrep.opstatus === "1"){
       if (eachrep.op_id === id)
	   {
		  
		eachrep.op_isdeleted = 1;
		//this.state.orderproduct=this.state.orderproduct-1;
		 eachrep.qty=0;
		 eachrep.amount=0;
		 eachrep.pr_salesrateexcl=0;
		 eachrep.discount=0;
		 eachrep.tax=0;
		 eachrep.oldincgrossamt=0;
		   
		
		   } 
		}
	else{	 
       		
 if (eachrep.op_id === id)
	   {
		
		let opid = eachrep.op_id ;
		let prid = eachrep.year;
		let prqty = eachrep.qty;
		
			 var formData = new FormData(); 
					 formData.append('action','delete')
					 formData.append('userid',localStorage.getItem("Ruserid"))
					 formData.append('pp_id',opid)
					
					  axios.post(Serverurl+"ajaxpurchasebillorderproducts.php", formData, {
						}).then(res => {
							
					eachrep.op_isdeleted = 1;
		//this.state.orderproduct=this.state.orderproduct-1;
		 eachrep.qty=0;
		 eachrep.amount=0;
		 eachrep.pr_salesrateexcl=0;
		 eachrep.discount=0;
		 eachrep.tax=0;
		 eachrep.oldincgrossamt=0;
									
							});
							
							
							
	   }
	} 
	   if (eachrep.op_id !== id)
	   {
	    //strsubtotal=Number(eachrep.amount)+Number(this.state.subtotal);
		strsubtotal=Number(eachrep.amount)+Number(strsubtotal);
let amt= (Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty));

			if(eachrep.discounttype === "2"){			
	
			
				 streachdiscount= (Number(eachrep.discount));
				
							
			   
		   }
		   else if(eachrep.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(eachrep.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   }
		  
		    strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount)
	 
	   
	   }
    });
	this.setState({pvdata: optionsRep,orderproduct:Number(this.state.orderproduct)-1,subtotal:strsubtotal})	
setTimeout(() => this.Taxcalculactionbind(),500);		
		}
}*/


 
 
fieldsave(id,strStatus){

  

	let strtotaldiscount="0",strgrantotal="0",streachdiscount="0",strsubtotal=0,strtotalgst=0;
let discountsum=0, optionstax=[], r=false,strtotalqty=0;
 let optionsRep = this.state.pvdata;
 if(strStatus=="2")
	{
		 r = window.confirm("Are you sure to Delete Product from Purchase?");
		optionstax = this.state.taxdata;
	}

	
    optionsRep.sort((a, b) => a.op_id > b.op_id ? 1 : -1).forEach(eachrep => {
	   if(r == true && strStatus=="2" ){
			 
			
       if (eachrep.op_id === id)
	   {
		  
		eachrep.op_isdeleted = 1;
		// this.state.orderproduct=this.state.orderproduct-1;
		 eachrep.qty=0;
		 eachrep.amount=0;
		 eachrep.pr_salesrateexcl=0;
		 eachrep.discount=0;
		 eachrep.tax=0;
		 eachrep.oldincgrossamt=0;
		 
		   } 
		
			 
		 }
		 else if( strStatus=="1" ){
       if (eachrep.op_id === id)
	   {
			 
		 eachrep.oldyear= eachrep.year;
            eachrep.oldtitle = eachrep.title;	   
			
			eachrep.oldpr_code = eachrep.pr_code; 
			eachrep.oldpr_salesrateexcl = eachrep.pr_salesrateexcl ;
	    	 eachrep.pr_salesrateincl = eachrep.pr_salesrateincl;	
             eachrep.oldstock = eachrep.stock;	
              eachrep.tax = eachrep.tax;	

              eachrep.oldpr_tx_id = eachrep.pr_tx_id;	
              eachrep.olddiscount = eachrep.discount;	
           eachrep.oldqty = eachrep.qty;
		   eachrep.olddiscounttype =  eachrep.discounttype;
		   if(eachrep.discounttype==="1")
		   {
		   eachrep.olddistypetitle="%";
		   }else{
		   eachrep.olddistypetitle="Rs.";
		   }
		   eachrep.oldamount=eachrep.amount;
		  // this.state.orddiscount=eachrep.discount;
		  
		   
		   
		    discountsum =Number(discountsum)+Number(eachrep.discount);

				 	 var strtax=Number(eachrep.tax)/2;
		   var strtaxamount=(Number(eachrep.amount)*((eachrep.tax)/2)/100)
		  
		   var strtotaltaxamount=Number(eachrep.amount)+Number(strtaxamount)+Number(strtaxamount)
		   eachrep.taxamount=eachrep.amount;
		    eachrep.cgstrate=strtax;
		eachrep.cgstamt=strtaxamount;
		 eachrep.sgstrate=strtax;
		eachrep.sgstamt=strtaxamount;
		eachrep.totaoatax=strtotaltaxamount.toFixed(2);
	   }
	}
	    if (eachrep.op_isdeleted !== 1 && eachrep.year !== "")
	   {
	 
	 
	  strtotalqty=Number(strtotalqty)+Number(eachrep.qty)
	 
	  strsubtotal=Number(eachrep.amount)+Number(strsubtotal);
let amt= (Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty));

			if(eachrep.discounttype === "2"){			
	
			
				 streachdiscount= (Number(eachrep.discount));
				
							
			   
		   }
		   else if(eachrep.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(eachrep.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   }
		    
		  
		
	  
	
		strtotalgst	=Number(strtotalgst)+Number(eachrep.sgstamt)+Number(eachrep.sgstamt) ;  
		    strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount);
	   }

   
    }) 
	
	let vnewpcid=this.state.newpcid;
	if(strStatus=="1" ){
if(this.state.newpcid<0)
{
		 
	if(this.state.editclick!==1)
	{
	  vnewpcid=this.state.newpcid-1;
	  
	let newrow={"op_id":vnewpcid,"year":"","title":"",
	 "pr_code":"","pr_salesrateexcl":"","pr_salesrateincl":"","stock":"","tax":"","pr_tx_id":"","discount":"","discounttype":2,"qty":1,"amount":"","inclamount":"","op_isdeleted":0,"proddiscount":0,
	 "unit":"",
	 "oldyear":"","oldtitle":"","oldpr_code":"","oldpr_salesrateexcl":"","pr_salesrateincl":"","oldstock":"","oldtax":"","oldpr_tx_id":"","olddiscount":"","olddiscounttype":2,"oldqty":1,"oldamount":""}
	   optionsRep.unshift(newrow);
	   
	
	}
	
	   
	
	
}
	}
 //strgrantotal=Number(strsubtotal)-Number(strtotaldiscount)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)
 
 strgrantotal=parseFloat(strsubtotal)+parseFloat(strtotalgst.toFixed(2))-parseFloat(this.state.roundoff)
 
setTimeout(() => this.DiscountPurCalculate(),500);
 this.setState({taxdatacount: optionsRep.length,
	 pvdata: optionsRep ,orderproduct:Number(strtotalqty),
	 showField: vnewpcid,
	  newpcid:vnewpcid,editclick:0,
	 prodarray:[],productselectdval:[],productselectdtags:'',
     produserarray:[],
       strProduct:[]
	   ,subtotal:strsubtotal,
	 
	   
	   //discount:Number(this.state.orddiscount)+ Number(distotal)
    
    });

	 
	
 }
  
DiscountPurCalculate()
{

var rowsArray = this.state.pvdata; 

 var srearray=rowsArray.filter(value => value.op_isdeleted  < 1)

  var newRow = srearray.sort((a, b) => a.op_id > b.op_id ? 1 : -1).map((row, i) => {
	  
	  
if(i!==0){ 
//row["discounttype"]		=	this.state.orddiscounttype;
  if(this.state.orddiscounttype == "2"){
	
				
		 row["ordwsediscount"] = parseFloat((Number(this.state.orddiscount))/Number(this.state.orderproduct));	
			//	row["discount"]		=	row["ordwsediscount"]+row["proddiscount"];
				
			}
			else{
				//row["discount"]		=	Number(this.state.orddiscount)+Number(row["discount"]);
				if(this.state.orddiscount!=="")	
				{
		row["ordwsediscount"] = ((parseFloat(row.pr_salesrateincl)-parseFloat(row["proddiscount"]))*Number(this.state.orddiscount))/100; 	
			

				}else{
			row["ordwsediscount"] = 0; 	
			
			}
	
			

			}
			

			 
					}	
	  			
  });
  	setTimeout(() => this.Taxcalculactionbind(),800);  	
}
Taxcalculactionbind	(){
	
	
	 let optionstaxRep = this.state.pvdata;
	 	
		optionstaxRep  = [].concat(optionstaxRep).sort(function(a, b) {
    return a.tax > b.tax ? 1 : -1;
  })
		let previoustax="",strsubtotal=0,strcurnttax="",prerow=0,taxablevalue=0,rate="",totaltaxamt=0,taxamount=0,totalamount=0;
	let sumtax=0,strtotalqty=0, strgrantotal=0,strtotaldiscount=0;
	let taxarr=[];
		 
		   optionstaxRep.forEach(eachrep => {
			if(eachrep.inclamount!=""){
			  if(  eachrep.op_isdeleted !== 1){
			   strcurnttax=eachrep.tax;
			   
			
			    strtotaldiscount=parseFloat(strtotaldiscount)+ ((parseFloat(eachrep.ordwsediscount)+parseFloat(eachrep.proddiscount))*eachrep.qty);
			strsubtotal=parseFloat(strsubtotal)+parseFloat(eachrep.amount)
			 
			 
			  if(strcurnttax!==previoustax && previoustax!=="")
			  {
				
				var item = { 
				  taxamount:taxablevalue,
				  cgstrate:rate,
				  cgstamt:taxamount,
				  sgstrate:rate,
				  sgstamt:taxamount,
				  totaoatax:totalamount.toFixed(2),
				}
				
				
				 taxarr.push(item)

taxablevalue=0;
				  rate="";
				  taxamount=0;
				  totalamount=0;

previoustax=eachrep.tax
 
			  }
			
			
		
	
			 let taxablveamtqty=((parseFloat(eachrep.pr_salesrateexcl)-parseFloat(eachrep.ordwsediscount)-(parseFloat(eachrep.proddiscount).toFixed(2))));

			taxablveamtqty=parseFloat(taxablveamtqty)*eachrep.qty;
			  
			  taxablevalue=Number(taxablevalue)+parseFloat(taxablveamtqty);
		//let strtax=parseFloat(eachrep.amount)-(parseFloat(eachrep.amount) * (100 / (100 + parseFloat(((eachrep.tax)/2)) )));
	let strtax=((parseFloat(taxablveamtqty )* Number(eachrep.tax))/100)/2;
	
			 previoustax=eachrep.tax ;
			  
				  rate=Number(eachrep.tax)/2;
taxamount=Number(taxamount)+ parseFloat(strtax);
	//totaltaxamt=Number(totaltaxamt)+ parseFloat(strtax);			  
				   totalamount=parseFloat(totalamount )+parseFloat(taxablveamtqty )+(parseFloat(strtax)*2);
			sumtax=Number(sumtax)+(parseFloat(strtax)*2); 
		 
		 } 
		   }
		 })
		 var item = { 
				  taxamount:taxablevalue,
				  cgstrate:rate,
				  cgstamt:taxamount,
				  sgstrate:rate,
				  sgstamt:taxamount,
				  totaoatax:totalamount.toFixed(2)
				}
				
				
				 taxarr.push(item)

taxablevalue=0;
				  rate="";
				  taxamount=0;
				  totalamount=0;
	//strgrantotal=Number(this.state.subtotal)-Number(this.state.ordtotaldiscount)+Number(sumtax.toFixed(2))-Number(this.state.roundoff)
	
	  strgrantotal=parseFloat(strsubtotal)+parseFloat(sumtax)-parseFloat(strtotaldiscount)-parseFloat(this.state.roundoffval)

 this.setState({ ordgst:sumtax.toFixed(2),grandtotal:strgrantotal.toFixed(2),ordtotaldiscount:strtotaldiscount,  
	   taxdata:taxarr,taxdatacount:taxarr.length,subtotal :strsubtotal, },()=>{
			
				
		setTimeout(()=>this.Roundoffautocalculation(),500); 
			
		});
}
 
 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
 onInputChange (newTextValue)
 {  strCust=[{title:'',year:0}];
 }
 
 
 
 
 
 render() {
	
	
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
  
 
  	
    
  return (
   <Page layout="public">

<Section slot="menu"> / Purchase / Purchase Bill
</Section>

<Section slot="btn"><a href='#/Purchasereport' tabIndex="-1"> Purchase Bills</a>
</Section>

<Section slot="content">
    <React.Fragment>
	
	
<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
	
	
	
	
  <div className="mainorder"> <div className="headername">
		 <p><b> New Purchase Bill</b></p>
		 </div>
    <div className="ordtbl">

	  <div className="panelpad">
	
	
	<div className="purchase">

     
<ul>
       
		
		
		
		<li><p>
	
         <p>Purchase Bill No. <span className="validation">*</span></p>
			<TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "1",maxLength:150 }} 
            fullWidth
			value={this.state.billno}
            id="billno"
          
            name="billno" onChange={this.myChangeHandler}
            autoComplete="billno"
            
			
          /></p>
		
		</li>
		
				
		<li>
		
				

		
		  <p>Purchase Bill Date <span className="validation">*</span></p>
		<p className="datepicker"><MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        //value={this.state.fdate || Date.now()}
		 value={this.state.fdate}
        onChange={this.handfrChange}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
		id="dateborder"
		
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		
		</p></li>
		
		
		
		<li><p>
	
         <p>Reference No. <span className="validation">*</span></p>
			<TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "3",maxLength:150 }} 
            fullWidth
			value={this.state.refno}
            id="refno"
          
            name="refno" onChange={this.myChangeHandler}
            autoComplete="refno"
            
			
          /></p>
		
		</li>
		
		
		
		
		
		</ul>
		
	</div>	
		
		
	<div className="purchase">	

		<ul>
		
		
		<li>
		
				

		
		  <p>Reference Date <span className="validation">*</span></p>
		<p className="datepicker"><MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal" inputProps={{ tabIndex: "4"}} 
        //value={this.state.rdate || Date.now()}
		value={this.state.rdate}
        onChange={this.handrefChange}
            format="dd/MM/yyyy"
        autoOk name="rdate"
        ampm={false}
		id="dateborder"
		
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		
		</p></li>
		
		
		
		
		
		<li className="dropdown">
		
				
<p>Payment Terms <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native inputProps={{ tabIndex: "5" }} 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.invoicelabel, value: this.state.payment }}
          onChange={this.myChangeHandler}
          id="payment"
		   value={this.state.payment}
		  name="payment"
		
		
        >
		{this.state.paymentarray.map(payment => (
            <option  
            data-pname={payment.display}
              value={payment.value}
            >
              {payment.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		</li>
		
		
		<li>
		
				

		
		  <p>Due Date <span className="validation">*</span></p>
		<p className="datepicker"><MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal" inputProps={{ tabIndex: "6"}} 
     //   value={this.state.ddate || Date.now()}
	 value={this.state.ddate}
        onChange={this.handdueChange}
            format="dd/MM/yyyy"
        autoOk name="ddate"
        ampm={false}
		id="dateborder"
		
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		
		</p></li>
		
		</ul>
	</div>


<div className="purchaseleft">	
		
		 
		 
		<p>Vendor <span className="validation">*</span> <span className="addtitle"  onClick={()=>{this.Useraddbox(0)}}><AddIcon />Add New Vendor</span>
	<Newcustomer ref="Newcustomer"  updateDonenew={this.insertDonenewParent}   />					
    </p>
		 <p>
		 
		 <Autocomplete
      id="custautoextender" 
	 
	  defaultValue={this.state.customer}
		  inputValue={this.state.custtags}
		    onInputChange={this.onInputChange}
	  onChange={this.onTagsChange}  
     options={strCust}
      renderInput={(params) => (
        <TextField {...params}   variant="outlined" />
      )}
      getOptionLabel={(option) => option.title || ""}
      renderOption={(option) => (
        <React.Fragment>
          <table width='100%' >
            {option.rowindex==="1" &&(
          <thead>
          <tr>
            <th width='10%'>Name</th>
            <th width='10%'>Email</th>
			  <th width='10%'>Mobile No.</th>
			 
				  </tr> 
        </thead>)}
            <tbody>
              <tr>
                <td width='10%'>{option.title}</td>
                <td width='10%'>{option.email}</td>
				
                <td width='10%'>{option.mobileno}</td>
				
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      )}
    />
		 
		 
	
	  </p>
		
		
		
		
		</div>
	
	
	  <div className="purchaseright">
		 
		
		
						
<p className="dropdown"> Destination State/Country *</p>
		<p className="dropdown"><FormControl  className={useStyles.formControl} id="formdrp" >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.invoicelabel, value: this.state.dest }}
          onChange={this.myChangeHandler}
          id="dest"
		   value={this.state.dest}
		  name="dest"
		
		
        >
		{this.state.statarray.map(stat => (
            <option  
            
              value={stat.value}
            >
              {stat.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	
	</div>
	<div className="clear"></div>
	




	
	<div className="ordtbl">
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table"> 
 
 <TableHead> 
<TableRow>
  <TableCell width="25%"><b>Product Name</b></TableCell>     
  <TableCell width="10%"><b>P.Rate(Exc)</b></TableCell>  
  <TableCell width="10%"><b>P.Rate(Inc)</b></TableCell>  
  <TableCell width="20%" style={{"display":"none"}}><b>Discount</b></TableCell>  
  <TableCell width="10%"><b>Tax</b></TableCell>   
  <TableCell width="10%"><b>Qty</b></TableCell>  
  <TableCell width="10%"><b>Amount</b></TableCell>    
   <TableCell><SyncAltIcon /> </TableCell> 
						
                      </TableRow></TableHead>

		   
               
                      <TableBody>
					  
					  
					  
			
<TableRow>	
	 
			<TableCell colSpan="8" width="100%">	<TextField  
type="text"
 hinttext="Scan Code"
 InputLabelProps={{
            shrink: true,
          }} 
		  value={this.state.scancode}
 variant="outlined" 
 id="scancodestyle" 
 //label="Scan Code"   
 placeholder="Barcode"
 name="scancode" 
className="scancodestyle"
onChange={this.escFunction}
onFocus={this.escFunction}
 />	
  </TableCell>
  </TableRow>	



            {(this.state.pvdata).map((item,index) => {


				
           return ( <React.Fragment>
		   
	
		   
		   
		   {item.op_isdeleted!==1 && (	 
 <TableRow key={index} >



<TableCell>

{this.state.showField!==item.op_id && (


<p>{item.title}</p>

)}

 
 
 
 {this.state.showField===item.op_id && (
 
 
 <Autocomplete
      id="free-solo-demo"
      
      defaultValue={this.state.productselectdval}
		  inputValue={this.state.productselectdtags}
		   onInputChange={this.onInputprodChange}
      options={this.state.prodarrauto}
	   onChange={this.onTagsproductChange} 
	    value={this.state.selectedproduct}
      renderInput={(params) => (
        <TextField {...params} onChange={this.onloadProductsBind}  variant="outlined" />
      )}
   getOptionLabel={(option) => option.title || ""}
      renderOption={(option) => (
        <React.Fragment>
          <table width='200%' >
            {option.rowindex=="1" &&(
          <thead>
          <tr>
            <th width='10%'>Product Code</th>
            <th width='10%'>Product Name</th>
			  <th width='10%'>P.Rate(Exc)</th>
			   <th width='10%'>P.Rate(Inc)</th>
			    <th width='10%'>Tax</th>
				 <th width='10%'>Stock</th>
				  </tr> 
        </thead>)}
            <tbody>
              <tr>
                <td width='10%'>{option.pr_code}</td>
                <td width='10%'>{option.title}</td>
				
                <td width='10%'>{option.pr_salesrateexcl}</td>
				<td width='10%'>{option.pr_salesrateincl}</td>
				 <td width='10%'>{option.tax}</td>
			
                <td width='10%'>{option.stock}</td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      )}
    />
 
 
 
 )}
 
 </TableCell>  
 
 
          
 
  
<TableCell>


{this.state.showField!==item.op_id && (


<p>{item.pr_salesrateexcl}</p>

)}

 
 
 
 {this.state.showField===item.op_id && (
 


< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.pr_salesrateexcl}
            id="salesrateexcl"
          
			//type="number"
            name="pr_salesrateexcl"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 7
  }}
			
		 />
 
 )}
</TableCell>   

<TableCell>


{this.state.showField!==item.op_id && (


<p>{item.pr_salesrateincl}</p>

)}

 
 
 
 {this.state.showField===item.op_id && (
 

 
<TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.pr_salesrateincl}
            id="salesrateincl"
           
			//type="number"
            name="pr_salesrateincl"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 7
  }}
			
		 />

 )}
</TableCell>   

<TableCell style={{"display":"none"}}>


 
{this.state.showField!==item.op_id && (
<React.Fragment>

<p>{item.discount}  {item.olddistypetitle}</p>
</React.Fragment>
)}

 
 <div className="inputleft">
 
 {this.state.showField===item.op_id && (
 


< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.discount}
           // id="discount"
           id="inputwidth"
			//type="number"
            name="discount"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 />
	)}	
</div>

<div className="inputright">
{this.state.showField===item.op_id && (	
		 
	<FormControl className={useStyles.formControl} >
         
        <Select
         native
        variant="outlined"
		  defaultValue={{ label: this.state.rolelabel, value: item.discounttype}}
          onChange={this.myChangeHandlerPV}
          
		   value={item.discounttype}
		  name="discounttype"
        >
		
		  <option value="2"> Rs </option>
          <option value="1"> % </option>
          	 
		  </Select>
 </FormControl>


 )}
 
 </div>
 
 
 <div className="clear"></div>
</TableCell>  
<TableCell>


 {this.state.showField!==item.op_id && (


<p>{item.tax}</p>

)}

 
 
 
 {this.state.showField===item.op_id && (
 


<FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.invoicelabel, value: item.pr_tx_id }}
          onChange={this.myChangeHandlerPV}
          id="tax"
		   value={item.pr_tx_id}
		  name="pr_tx_id"
		
		
        >
		{this.state.taxarray.map(tax => (
            <option  
            
              value={tax.value}
            >
              {tax.display}
            </option  >
          ))}
		
        </Select>
 </FormControl>



 )}
</TableCell> 
 
 
<TableCell>




 {this.state.showField!==item.op_id && (


<p>{item.qty}</p>

)}

 
 
 
 {this.state.showField===item.op_id && (
 





 
< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.qty}
            id="qty"
           
			inputRef={element => (this.textRef = element)}
            name="qty"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 />

 
 )}
</TableCell> 



 <TableCell>
 


 {this.state.showField!==item.op_id && (


<p>{item.amount}</p>

)}

 
 
 
 {this.state.showField===item.op_id && (
 


 
 < TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.amount}
            id="amount"
           
			//type="number"
            name="amount"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 />
		 
 
 )}
</TableCell> 









{this.state.showField===item.op_id && (

<TableCell> 


<Tooltip title="Save">
 <IconButton aria-label="Save">
<div className="addbtns">
<AddIcon onClick={()=>{this.fieldsave(item.op_id,"1")}} />
</div>
 </IconButton>
 </Tooltip>
 
 
</TableCell> 
)}









{this.state.showField!==item.op_id && (	
<TableCell align="right">


{item.f !=="-1" &&(
<React.Fragment>


<Tooltip title="Edit">
 <IconButton aria-label="Edit">
<div className="primary">
 <EditIcon onClick={this.fieldedit.bind(this, item.op_id,item.year)} />
 </div>
 </IconButton>
 </Tooltip>
 
 
 </React.Fragment>  
  )}
 

 <Tooltip title="Delete">
 <IconButton aria-label="Delete">
  <div className="secondary">
 <DeleteIcon onClick={()=>{this.fieldsave(item.op_id,"2")}} />
  </div>
 </IconButton>
 </Tooltip>
 

 </TableCell>
)}



 <TableCell>

 </TableCell>

 </TableRow>
 
)}


 
 
				</React.Fragment>	
				);
					})}
					
					
					
					
					 <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell  style={{"display":"none"}}></TableCell>	
		
					 
 <TableCell ><b>Sub Total</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.subtotal.toFixed(2)}

 </TableCell>
<TableCell ></TableCell>
  </TableRow>
					
	
	
 <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell  style={{"display":"none"}}></TableCell>	
<TableCell>

<TextField
            variant="outlined"
            margin="normal"           
            
			    
            name="orddiscount" 
			  
	 value={this.state.orddiscount}
     onChange={this.handleboxChangee()}
      
			
          />

</TableCell>	
<TableCell>

<FormControl className={useStyles.formControl} >
         
        <Select
         native
        variant="outlined"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.orddiscounttype}}
          onChange={this.handleboxChangee()}
          
		   value={this.state.orddiscounttype}
		  name="orddiscounttype"
        >
		
		  <option value="2"> Rs </option>
          <option value="1"> % </option>
          	 
		  </Select>
 </FormControl>

</TableCell>	
		
					 
 <TableCell ><b>Discount</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.ordtotaldiscount.toFixed(2)}

 </TableCell>
<TableCell ></TableCell>	
  </TableRow>
	
	
	
	 <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell  style={{"display":"none"}}></TableCell>	
		
					 
 <TableCell ><b>GST</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.ordgst}

 </TableCell>
<TableCell ></TableCell>	
  </TableRow>
  
  
   <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell  style={{"display":"none"}}></TableCell>		
<TableCell>

 <TextField
            variant="outlined"
            margin="normal"           
            id="roundoffval"
			value={this.state.roundoffval}        
            name="roundoffval"   onChange={this.handlechangeroundoff()}
            autoComplete="roundoffval"
			
          />


</TableCell>	
		
					 
 <TableCell ><b>Round off</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.roundoff}

 </TableCell>
<TableCell ></TableCell>	
  </TableRow>
  
  
  
   <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell  style={{"display":"none"}}></TableCell>	
		
					 
 <TableCell ><b>Grand Total</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.grandtotal}

 </TableCell>
<TableCell ></TableCell>	
  </TableRow>
                      </TableBody>



</Table>
  </TableContainer> 
 
  </Paper>
  {this.state.taxdata.length!==0 && (
  
  <table className="tabbg" cellSpacing="1" cellPadding="5">
    <tr><th></th><th>Taxable</th><th colSpan="2">	CGST</th><th colSpan="2">	SGST</th><th>	Total</th></tr>
	 <tr><th>#</th><th>Taxable</th><th >	Rate</th><th >	Amount</th><th >		Rate</th><th >	Amount</th><th align="center">	Total</th></tr>
	
  {(this.state.taxdata).map((item,index) => {


let j=index+1;
		  
					
           return ( <React.Fragment>
		    {item.op_isdeleted!==1 && (
  <tr><td>{j}</td><td align="right">{item.taxamount.toFixed(2)}</td><td align="right">{item.cgstrate}%</td>
<td align="right">Rs.{item.cgstamt.toFixed(2)}</td><td align="right">{item.sgstrate}%</td>
<td align="right">Rs.{item.sgstamt.toFixed(2)}</td><td align="right">Rs.{item.totaoatax}</td></tr>
 )}
  </React.Fragment>)
		
  
  }
  )}
    </table>
  )}
   </div>
 </div>

<div className="buttons">





<p className="btnleft"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="discard"
            className={useStyles.submit}
			
			onClick={this.handleDiscard}
			
          >
           Discard
          </Button></p>




<p className="btnright">

<Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="searchform" 
            className={useStyles.submit}
			
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
          >
        {this.state.submitbtn}
          </Button></p>



<div className="clear"></div>
</div>


   </div></div>
 </React.Fragment>
		</Section></Page>
  );
  }	
   

}




export  class  Newcustomer extends Component {



 constructor(props) {
    super();
	
	
	
	 this.state = {
	 
	 
	search:'',order:'desc' ,orderBy:'cu_id',cu_id:0,contactname:'',email:'',mobileno:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',payment:'',
	place:'',placearray:[],paymentarray:[],payment:'',cuid:'',open1:false,open:false,
	 
	  
        
      }
	 };
  
 
  onloadplacebind(){

    axios.get(Serverurl+"ajaxstate.php?action=list")
    
      .then((response) => {    
     
      this.setState({  placearray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.placearray.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      
		
        this.setState({
          placearray: [{value: '0', display: 'Select State'}].concat(roleFromApi)
        });
      })


}

onloadpaymentbind(){

    axios.get(Serverurl+"ajaxpaymentterms.php?action=list")
    
      .then((response) => {    
     
      this.setState({  paymentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.paymentarray.map(role => {
                            
          return {value: role.pt_id, display:role.pt_title}
        });
      
		
        this.setState({
          paymentarray: [{value: '0', display: 'Select Payment'}].concat(roleFromApi)
        });
      })


}
componentDidMount() {
 
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 
   let search = window.location.search;
let params = new URLSearchParams(search);
let cuid = params.get('cuid');


if(cuid!=null)
{
	
	setTimeout(()=>this.Usergetdata(cuid),500); 
	
	
}


} 
  
handleErrorclose=(event)=>{
	  
	this.setState({open1: false});  
  }  
  
  
  Usergetdata(cuid) {
	
 
	  
	   axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
  
      cu_id:resp.data[0].cu_id,
	
	
	contactname:resp.data[0].cu_contactname, 
   
	email:resp.data[0].cu_email,
	mobileno:resp.data[0].cu_mobileno,
	
	panno:resp.data[0].cu_panno,
	
	gstinno:resp.data[0].cu_gstinno,
	billingassress:resp.data[0].cu_billingassress,
	shippingaddress:resp.data[0].cu_shippingaddress,
	
	
	payment:resp.data[0].cu_payment,
	place:resp.data[0].cu_pl_id,
	
	
       	  
	  
       
    
    });

  }
  
});
	  
  
  
    }

   
handleClickOpen = (e) => {
 
  this.setState({ open:!this.state.open,cu_id:0,contactname:'',email:'',mobileno:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',payment:'',
	place:'',placearray:[],paymentarray:[],payment:''
	  
		 });
	
		
this.onloadplacebind();

this.onloadpaymentbind();



};
myChangeHandler = (event) => {
	
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
  


}

 


handleClose = (e) => {

 
 
 
  
	let contactname = this.state.contactname;
    let email = this.state.email;
	let mobileno = this.state.mobileno;
  
	let panno = this.state.panno;
	
	
	let gstinno = this.state.gstinno;
    let billingassress = this.state.billingassress;
	let shippingaddress = this.state.shippingaddress;
    
	

	let payment = this.state.payment;
	
	let place = this.state.place;
	
	
 var formData = new FormData();

	

if(contactname==="")
{
	this.setState({errormessage:"Please Enter Contact Name.",open1:true});
	return false;
}	


if(mobileno==="")
{
	this.setState({errormessage:"Please Enter mobileno.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}




if(panno==="")
{
	this.setState({errormessage:"Please Select panno.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(gstinno==="")
{
	this.setState({errormessage:"Please Enter gstinno.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(billingassress==="")
{
	this.setState({errormessage:"Please Enter Billing Address.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}


if(shippingaddress==="")
{
	this.setState({errormessage:"Please Enter Shipping Address.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}




  
  
  
  
	  
	 
	 	

  formData.append('action','insertorupdate') 


 formData.append('cu_id',0) 
 formData.append('cu_contacttype',2)
formData.append('cu_contactname',contactname)
formData.append('cu_email',email)
formData.append('cu_mobileno',mobileno)

formData.append('cu_panno',panno)


formData.append('cu_gstinno',gstinno)
formData.append('cu_billingassress',billingassress)
formData.append('cu_shippingaddress',shippingaddress)


formData.append('cu_payment',payment)
formData.append('cu_pl_id',place)



		
		
		/*formData.append('userid',1)
		  
		axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
    
        
    
        });
  
	this.setState({ open:false });*/
	
	formData.append('userid',localStorage.getItem("Ruserid"))	
	
	 axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
			
			//this.props.updateDonenew();
			
			
		  if(res.data==-1)
{ 
	 

		this.setState({errormessage:"Email Already Exist.",open1:true
		});
		

	}else{
		this.setState({errormessage:"Record Updated Successfuly",open1:true	,
		
			
			
			
			
			
			
	
		open:false,
		contactname:'',
		email:'',
		mobileno:'',		
		panno:'',		
		gstinno:"",
		billingassress:'',
		shippingaddress:'',
		payment:'',
		place:'',			
		cu_id:0
		});
	if(res.data[0].cuid !== "")
 {
	localStorage.setItem("newcuid",res.data[0].cuid);
	this.props.updateDonenew();
		/*this.setState({cuid:res.data[0].cuid,
		cuname:res.data[0].cuname})*/
		
 }
			
	}		
            });
	
	
	
};

render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} fullWidth={true } onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Add  Vendor</DialogTitle>
      <DialogContent>
	  
	  
	  <p>  <Collapse in={this.state.open1}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	  
		<div className="newcustomer">				  
						 
      <ul>


		  

       
		  
		<li>  
		<p>Contact Name <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.contactname}
            id="contactname"
          
            name="contactname" onChange={this.myChangeHandler}
            autoComplete="contactname"
            
			
          /></p></li>
		  
	 
		  
		<li>  
		<p>Email </p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.email}
            id="email"
          
            name="email" onChange={this.myChangeHandler}
            autoComplete="email"
			
            
			
          /></p></li>
		  
		 
		 
		 <li>  
		 <p>Mobile.No <span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.mobileno}
            id="mobileno"
           
            name="mobileno" onChange={this.myChangeHandler}
            autoComplete="mobileno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		  
		 
		 
		 
		 
		 
		 <li>  
		 <p>Pan.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.panno}
            id="panno"
           
            name="panno" onChange={this.myChangeHandler}
            autoComplete="panno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		 
		
		 
		 <li> 
<p>GSTIN.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.gstinno}
            id="gstinno"
          
            name="gstinno" onChange={this.myChangeHandler}
            autoComplete="gstinno"
			
            
			
          /></p></li>
		  
		 
		 
		  <li> 
<p>Payment Terms <span className="validation">*</span></p>
		  <p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.payment }}
          onChange={this.myChangeHandler}
          id="payment"
		   value={this.state.payment}
		  name="payment"
		
		
        >
		{this.state.paymentarray.map(payment => (
            <option  
            
              value={payment.value}
            >
              {payment.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></li>
		  
		 
		 
		 
		 <li> 
		 <p>State <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.place }}
          onChange={this.myChangeHandler}
          id="place"
		   value={this.state.place}
		  name="place"
		
		
        >
		{this.state.placearray.map(place => (
            <option  
            
              value={place.value}
            >
              {place.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>
		<li></li>
		<br/>
		
		
		<li>
<p>Billing Address</p>
		 <p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.billingassress}
            id="billingassress"
			
            fullWidth
           
            name="billingassress" onChange={this.myChangeHandler}
            autoComplete="billingassress"
            autoFocus
          />  </p></li>
		  
		  
		  
		<li> 
<p>Shipping Address</p>
		<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.shippingaddress}
            id="shippingaddress"
			
            fullWidth
           
            name="shippingaddress" onChange={this.myChangeHandler}
            autoComplete="shippingaddress"
            autoFocus
          />  </p></li>
		  
		  
		
		
		
		
		
		
		</ul>
		</div>
     
						  
			
                 
               </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button>
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		
		 
		
      </DialogActions>
    </Dialog>
</React.Fragment>





) }
  

} 