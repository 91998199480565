import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';

import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableFooter from '@material-ui/core/TableFooter';
let strCus=[{title:'',year:0}];
const strCusdeflt=[];



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [




{ id: 'so_id', numeric: false,  label: 'shipping Order No' },
{ id: 'so_invoiceno', numeric: false,  label: 'Invoice No' },

{ id: 'so_shipdate', numeric: false,  label: 'Ship Date' },
{ id: 'so_customername', numeric: false,  label: 'Customer' },
{ id: '', numeric: false,  label: 'Total' },
{ id: 'so_invoiceno', numeric: false,  label: '' },

]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class  Invoicereport extends Component {
		constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',shipdata:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:30,rowsPaging:[],
	 Redirect:false,
	 
	search:'',order:'desc' ,orderBy:'so_id',value:'',invoice:'',createddate:'',contactname:'',
	discount:'',rate:'',roundoff:'',searcharray:[],custtags:[],customer:'',
	 btndisabled:false,editredirect:false,generate:'',
submitbtn:"Submit",  
	   
	  
 
        
      }
	  
	   this.onTagsChange = this.onTagsChange.bind(this);
	  
	 };
	 
onloadCustomerBind=(event)=>{
	// axios.get(Serverurl+'ajaxcustomer.php?action=searchextender')
	  axios.get(Serverurl+'ajaxcustomer.php?action=searchextender&cu_contactname='+event.target.value)

  .then(resp => { 
  this.setState({ searcharray:resp.data});

	 var rowsArray1 = this.state.searcharray;
	 rowsArray1.map((row, k) => {
			 
		 const items = {
			 
        title:row.title,
         year:row.year
          
        };
         strCus.push( items)
		
		 });
  });
  
  
}	
	 
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 } 
 
 handleSubmit= (event) => {
    
    event.preventDefault();
	
  let value = this.state.value;
	let invoice = this.state.invoice;
	let customer = this.state.customer;
	
	
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
}	
	 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}



componentDidMount() {
	
	document.title = "";
		
		
	if(localStorage.getItem("Ruserid")===null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "4"  ||  localStorage.getItem("Rrole") === "2"   )
{
	this.setState({
            logredirect: false,
			pagename:'Shippingreport'
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}
	
	
		

		
		
 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 

}	 

apicall(page,rowsPerPage){
	
	
	
this.setState({btndisabled:true,submitbtn:"Processing..."})
	
	 axios.get(Serverurl+'ajaxshippingorderproduct.php?action=shippingreport&so_invoiceno='+this.state.invoice+'&so_customername='+this.state.customer+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  
	// axios.get(Serverurl+'ajaxorders.php?action=invoicereport&StartIndex='+page+'&PageSize='+rowsPerPage)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  shipdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[];
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}
			
	
	  this.setState({rowsPaging:copy,
btndisabled:false,submitbtn:"Search"});
	  
	 
     }
	 else{
		 
		   this.setState({shipdata:[],totaldata:0,
btndisabled:false,submitbtn:"Search"});
	  
	 }
  
    });
}


handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
}


onTagsChange = (event, values) => {
	
	let optionsval= values["year"];
	let optionsval1= values["title"];

	
	 
    this.setState({
      custtags: values,
	   customer: values["title"]
	   
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
  }









render() {
	
		if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	
	let grandtotal=0;
	
	let totalamount=0,strsubtotal=0,strgrndtotal=0,strtotal=0,strroundoff=0;
	
	return (
	
	
	 <Page layout="public">

<Section slot="menu"> / Configuration / Shipping Report
</Section>
<Section slot="btn"><a href='#/Customerinvoice' tabIndex="-1">New </a>
</Section>
<Section slot="content">
  
   <React.Fragment>
   
   
    <div className="main">
	
	<br></br>
 
 <div className="headername">
		 <p><b>Shipping Report</b></p>
		 </div>
		
	
	
	<div className="search">
	
	<ul>
	
	
		  
		 <li> <p><TextField
            //variant="outlined"
            margin="normal"
            variant="outlined"
            fullWidth
			value={this.state.invoice}
            id="invoice"
           
            name="invoice" onChange={this.myChangeHandler}
            autoComplete="invoice"
			placeholder="Invoice No"
            autoFocus
			
          />
		  </p></li>
		  
		  
		  
		  
		  
		 <li> <Autocomplete
       
        
      
       margin="normal"
      
            fullWidth
	   options={this.state.searcharray}
        getOptionLabel={option => option.title}
		//options={strCus}
   //defaultValue={strCusdeflt}
	  onChange={this.onTagsChange}  
        renderInput={params => (
          <TextField {...params} onChange={this.onloadCustomerBind}  variant="outlined" id="search" placeholder="Customer" />
        )}
      /></li>
		  
		  
		  
 
 </ul>
		
		  
		 <p className="btncenter"> <Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
           id="searchform"
            className={useStyles.submit}
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
          >
            {this.state.submitbtn}
          </Button></p>	</div>
	
	
	
	
	
	
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.shipdata.length}
            />
                      <TableBody>

                     {stableSort(this.state.shipdata, getSorting(this.state.order, this.state.orderBy)).map((item,index) => {
						 
            grandtotal=Math.round(parseFloat(item.subtotal)-parseFloat(item.or_discount)-parseFloat(item.or_roundoff))
			
		let totalamount=Number(item.subtotal).toFixed(2)
		
	
		
	
	

	 
 strsubtotal=(Number(strsubtotal)+Number(item.subtotal)).toFixed(2);
		strgrndtotal=(Number(strgrndtotal)+Number(grandtotal)).toFixed(2);
		strtotal=(Number(strtotal)+Number(totalamount)).toFixed(2);
		strroundoff=(Number(strroundoff)+Number(item.or_roundoff)).toFixed(2);



		 return ( <React.Fragment>
 <TableRow key={index} >






<TableCell><a className="link" href={"#/Shippingviewmore?soid="+item.so_id+""}>{item.so_id}</a></TableCell>
<TableCell><a className="link" href={"#/Shippingviewmore?soid="+item.so_id+""}>{item.so_invoiceno}</a></TableCell>
<TableCell><a className="link" href={"#/Shippingviewmore?soid="+item.so_id+""}>{item.shipdate}</a></TableCell>
<TableCell><a className="link" href={"#/Shippingviewmore?soid="+item.so_id+""}>{item.so_customername}</a></TableCell>
<TableCell><a className="link" href={"#/Shippingviewmore?soid="+item.so_id+""}>{item.subtotal}</a></TableCell>
<TableCell><a className="link" href={"#/Shippingviewmore?soid="+item.so_id+""}>{item.genaratetxt}</a></TableCell>
	




	
	

 </TableRow>


				</React.Fragment>	
				);
					})}
					
					
				{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="2">No Records</TableCell>
					</TableRow>
					) }	
					
					
                      </TableBody>
					  
					  
 	{this.state.totaldata!==0 &&(				  

<TableFooter className="tbltot">
<TableCell >Total</TableCell><TableCell></TableCell><TableCell></TableCell>
<TableCell></TableCell>
<TableCell>Rs. {strsubtotal}</TableCell>


</TableFooter>

) }

</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
   
 

   </div>
 </React.Fragment>
		

		 </Section></Page>	 	


);
 
  }	


}
 
		 