import React ,{Component} from 'react';


import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import login_pagelogo from './images/logo.png';

import loginbgImage from "./images/blue-bg.png";
import submitarrow from "./images/submit-arrow.png";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default class Login extends Component {


  constructor(props) {
    super();

    this.state = { us_id: '',username: '', password: '',pagename:'',strreturn:'', redirect: false,checked:true,checkedlogin:false};
   
}
   myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val});
  
}

handleChckbxChange = (event) => {
   
	this.setState({checkedlogin: !this.state.checkedlogin});

  };
  
  
handleChange = (event) => {
   
	this.setState({checked: !this.state.checked});
  };
componentDidMount() {
	if(localStorage.getItem("Rusername")!=null)
	{
		 this.setState({
            username: localStorage.getItem("Rusername"),
			password:localStorage.getItem("Rpassword"),
          })
	}
	
	
	
	
	
	
	if(localStorage.getItem("Ruserid")!=null)
	{
           
		 if(localStorage.getItem("Rrole")!=null)
		  {  
		   
		   if(localStorage.getItem("Rrole")=="1")
		  {
		   
		  
		   this.setState({
            redirect: true,
			pagename:'dashboard'
       	   })
	} 
		  }	
		  
		  else  if(localStorage.getItem("Rrole")=="2")
		  {
			  
			 
			 
		   this.setState({
            redirect: true,
			pagename:'Addinvoice'
          })
			  
			  
		  }
		  
		  
		  
		  
	}
}

 
   handleSubmit= (event) => {
    event.preventDefault();
	
    let username = this.state.username;
    let password = this.state.password;
	let ischeck = this.state.checked;
   var formData = new FormData();
    formData.append('action','userlogin')
	
	formData.append('us_username',username)
    formData.append('us_password',password)
    axios.post(Serverurl+"ajaxuser.php", formData, {
        }).then(res => {


		let strreturn=res.data 	;	 
 if(res.data.length<=0)
 {
	 alert("Username/ Password is not valid");
	 return false;
 }else
 {

          localStorage.setItem("Ruserid",res.data[0].us_id);
		 localStorage.setItem("Rrole",res.data[0].us_ma_id);
		   

	 if(ischeck==true)
  {
	   localStorage.setItem("Rusername",this.state.username);
	  localStorage.setItem("Rpassword",this.state.password);
	
  }else{
	 localStorage.removeItem("Rusername");
	  localStorage.removeItem("Rpassword"); 
  }
  	 
	 this.dailystockupdates();
	 	this.setState({redirect:true,	pagename:'dashboard'});   

		  
 }
 
		
            });
  
  }
  
  
  
  
  dailystockupdates(){
	  
	 axios.get(Serverurl+'ajaxdailystock.php?action=dailystockdetails')
  .then(resp => {  
		
    
	
  
    }); 
	  
	  
  }
  
  
   /* 
   
  */
  
  render() {
	  
	 if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	  
  
  return (
	 <React.Fragment>  
		
<div className="signintab">
<div className="signinmain">
<div className="signinleft">

<p>	<img src={login_pagelogo} className="loginlogoimage" /></p>


</div>



<div className="signinright">


<div className="background"  className="bg_image"
        style={{
          backgroundImage: 'url('+loginbgImage+')',
          backgroundSize: "100% 100% ",
        
          color: "#f5f5f5"
        }}  >



<div className="signinform">




<div className="formright">




 <form className={useStyles.form}   onSubmit={this.handleSubmit} >
<h1> LOGIN </h1>
<div className="textinput">
		 
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
			color="#ffffff"
           autoFocus
            fullWidth
			value={this.state.username}
			inputProps={{ tabIndex: "1",maxLength:150 }} 
            id="usernamelogin"
			placeholder="Username"
			 InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
                       type="username"
            name="username" onChange={this.myChangeHandler}
            
			
          /></p>
		  
		  	  
		 
		  <br/>
		  
		 
		   <p>
		  <TextField
            variant="outlined"
			className="bordercolor"
            margin="normal"
           inputProps={{ tabIndex: "2",maxLength:150 }} 
            fullWidth
			value={this.state.password}
             type="password"
            id="passwordlogin"
			placeholder="Password"
            name="password" onChange={this.myChangeHandler}
            	 InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="iconcolor" >
              <LockIcon />
            </InputAdornment>
          ),
        }}
			
          /></p>
		  
		 </div> 
		  
          
		  
		<div className="remember"> <Grid container>
            <Grid item xs>
              
            </Grid>
            <FormControlLabel
            control={<Checkbox value="remember" inputProps={{ tabIndex: "3" }}  checked={this.state.checked} color="primary" onChange={this.handleChange} />}
            label="Keep Signed in"
          />
		
          </Grid>  </div>
	
          <p className="submitlogin"><Button
            type="submit"
            fullWidth
            variant="contained" 
           // color="primary"    
		   inputProps={{ tabIndex: "4" }} 
			id="submitlogin" 
            className="submitlogin"
          >
            Submit <img src={submitarrow} />
          </Button></p>
         
      </form></div></div>

      
 </div></div>
 
 <div className="clear"></div>
 
 </div>
	 </div>		
	</React.Fragment>
	



  );
  }
}



