import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';

import Uploadsurl from './Apiurl';

import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import TableFooter from '@material-ui/core/TableFooter';



import ReactToPrint from 'react-to-print';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';





function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [

{ id: 'op_createddate', numeric: false,  label: 'Date' },
{ id: 'pr_name', numeric: false,  label: 'Product Name' },
{ id: 'ca_name', numeric: false,  label: 'Category Name' },

{ id: 'pr_code', numeric: false,  label: 'Product Code' },
{ id: 'pr_salesrateexcl', numeric: false,  label: 'Salesrateecxl' },
{ id: 'pr_salesrateincl', numeric: false,  label: 'Salesrateincl' },
{ id: 'op_quantity', numeric: false,  label: 'Quality' },

]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class  Invoicegstreport extends Component {
	
	
	constructor(props) {
    super();
	
	
	var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:-1,totaldata:0,rowsPerPage:50,rowsPaging:[],
	 
	 
	search:'',order:'asc' ,orderBy:'or_id',name:'',or_id:0,productname:'',categoryname:'',date:'',
	product:'',productarray:[],category:'',categoryarray:[],InvoiceData:[],tax:'', amount:'',
	mavalue:'',exportData:[],btndisabled:false,disinvoice:'',
submitbtn:"Submit", orlogo:'', orphonenum:'',dispfromtodate:'',
	 
	
	 
	date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1	
  
	  
	 
        
      }
	 };
	 
	 
handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }	 
	 
	
handtoChange = (date) => {
	  var month="",date1="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }

	
	 
	 
onloadproductbind(){

    axios.get(Serverurl+"ajaxproduct.php?action=list")
    
      .then((response) => {    
     
      this.setState({  productarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.productarray.map(product => {
                            
          return {value: product.pr_name , display:product.pr_name}
        });
      
		
        this.setState({
          productarray: [{value: '0', display: 'Product Name'}].concat(roleFromApi)
        });
      })
this.onloadcategorybind();
}	


onloadcategorybind(){

    axios.get(Serverurl+"ajaxcategories.php?action=list")
    
      .then((response) => {    
     
      this.setState({  categoryarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.categoryarray.map(category => {
                            
          return {value: category.ca_name, display:category.ca_name}
        });
      
		
        this.setState({
          categoryarray: [{value: '0', display: 'Category Name'}].concat(roleFromApi)
        });
      })


}


	 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  
  localStorage.removeItem("fromdate");
   localStorage.removeItem("todate");
  
  this.setState({[nam]: val});

 } 
 
 

 
 
 
handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
  
	let product = this.state.product;
	let category = this.state.category;
	
	let fdate = this.state.fdate1;
	let tdate = this.state.tdate1;
	
	
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
} 


 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

componentDidMount() {
	
	document.title = "";
	
	if(localStorage.getItem("Ruserid")===null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "4" ||  localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2" || localStorage.getItem("Rrole") === "21" )
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}
	
	
		
 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 

this.onloadproductbind();
 this.orgviewmore();
}	




orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	this.setState({		
    orname:resp.data[0].or_name,	
	oraddress:resp.data[0].or_address, 
   
	orcity:resp.data[0].or_city,
	oremail:resp.data[0].or_email,
	ormobilenum:resp.data[0].or_mobilenum,
	orpannum:resp.data[0].or_pannum,
	
	orgstinnum:resp.data[0].or_gstinnum,
	orlogo:resp.data[0].or_logo,
	orphonenum:resp.data[0].or_phonenum,
	
	 }); 
	 
     }
 // this.viewmoreorder();
    });
}



 

apicall(page,rowsPerPage){
	
	
	
  	this.setState({btndisabled:true,submitbtn:"Processing..."})	
	axios.get(Serverurl+'invoicegstreport.php?fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&sorting='+this.state.orderBy +' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
		var strcnt=resp.data[0].count;
		let arrcnt=parseFloat(resp.data.length)-1;
		strcnt=parseFloat(strcnt-1)
	
		var str=this.state.fdate1.split('-');
		var str1=this.state.tdate1.split('-');
			this.setState({  
			  dispfromtodate:"Date: "+str[2]+"/"+str[1]+"/"+str[0]+" to " +str1[2]+"/"+str1[1]+"/"+str1[0],
               InvoiceData: resp.data,  
disinvoice:	"Invoice: "+resp.data[0].or_invoice +" to "+resp.data[arrcnt].or_invoice,	   
			   totaldata:parseInt(resp.data[0].count),
			   tax:resp.data[0].op_tax_value,
			   amount:resp.data[0].amount,
			   
			});
			/*let cnt=this.state.totaldata;
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);*/
		let copy=[50,100,150,200,250,300];
		
			/*for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}
			*/
	
	  this.setState({rowsPaging:copy,btndisabled:false,submitbtn:"Search"});
	  
	 
     }else{this.setState({  
	 InvoiceData:[],totaldata:0,dispfromtodate:'',disinvoice:'',btndisabled:false,submitbtn:"Search"
	// btnsearch:"Search",
	// loadingpanel:'0',
	
	//tax:resp.data[0].op_tax_value,
	
	 });}
  
    });
	
	this.exportapicall();
}


exportapicall(){
	
		
	axios.get(Serverurl+'invoicegstreport.php?fromdate='+this.state.fdate1+'&todate='+this.state.tdate1)
  	
  
	 .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportData: resp.data,  
			
			  
			   totaldata:parseInt(resp.data[0].count),
			   
			   
			   
			});
			
		
	 
     }else{this.setState({  
			  exportData: [] }); 
			}
  
    });
	

	
}



handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
}



/*
 <ExcelFile element={<button className="expbtn">Export</button>}>
        <ExcelSheet data={this.state.exportData} name="Invoice GST Report">
		<ExcelColumn label="Invoice Type" value="mavalue"/>
        <ExcelColumn label="Invoicve NO." value="or_invoice"/>
        <ExcelColumn label="Invoice Date" value="or_createddate"/>
		
		<ExcelColumn label="Customer" value="cu_contactname"/>
        <ExcelColumn label="GSTIN" value="cu_gstinno"/>
        <ExcelColumn label="Grandtotal" value="grandtotal"/>
		
	   
    
                </ExcelSheet>                
            </ExcelFile>

*/


render() {
		if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	return (
	
	
	 <Page layout="public">

<Section slot="menu"> / <a href='#/Reports'>Report</a> / Invoice GST Report
</Section>

<Section slot="btn"><a href='#/Reports'>Reports</a>


<ReactToPrint
            trigger={() => <p id="print-barcode" className="printreport"> Print </p>}
            content={() => this.componentRef}
			
          />
		  
		  
		  
	 <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="expbtn"
                    table="purreport"
                    filename="InvoiceGST Report"
                    sheet="Invoice GST Report"
                    buttonText="Export"/>	  
		  
		  
		  
		  

</Section>

<Section slot="content">
  
   <React.Fragment>
   
   
   
	
	<br></br>
 
 
 <div className="main">
 
 
 <div className="search">
 
  <ul>
  
  <li><p>From :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
        value={this.state.fdate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
	id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</li>
 
 <li><p>To :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
      <DatePicker fullWidth
        value={this.state.tdate || addDays(moment().toDate(),1)}
		maxDate={addDays(moment().toDate(),0)}
        onChange={this.handtoChange}
            format="dd/MM/yyyy"
        autoOk name="tdate"
        ampm={false}
		id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	
		</li>
 
 

 <li className="lialignment" style={{"display":"none"}}>
		 <FormControl  className={useStyles.formControl} >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.product }}
          onChange={this.myChangeHandler}
        
		   value={this.state.product}
		  name="product"
		
		
        >
		{this.state.productarray.map(product => (
            <option  
            
              value={product.value}
            >
              {product.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></li>
 
 
 <li style={{"display":"none"}}>
		 <FormControl  className={useStyles.formControl} >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.category }}
          onChange={this.myChangeHandler}
          //id="state"
		   value={this.state.category}
		  name="category"
		
		
        >
		{this.state.categoryarray.map(category => (
            <option  
            
              value={category.value}
            >
              {category.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></li>
 
 
 
 </ul>
		
	
	
	<br/>
	
	<p className='submitrep'><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
             id="searchform"
            className={useStyles.submit}
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
          >
            {this.state.submitbtn}
          </Button>	</p> 
	
	<br/>
	<br/>
	
	
		<div className="">
	
		<div ref={el => (this.componentRef = el)} >
	
	 <div className="reportheading">
		
       <b>INVOICE GST REPORT</b>
           </div>
		   
		<div className="repborder">   
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  	
	
	
	
	<TableRow >  
<TableCell colSpan={2} >


<div className="reportmain">
<div className="repfromdetails">


	
	<p><b>{this.state.orname}</b><br/>
	{this.state.oraddress} <br/> 
	{this.state.orcity} <br/>

{this.state.orgstinnum}<br/>
	{this.state.orphonenum} , {this.state.ormobilenum}

	<br/>{this.state.oremail}</p>
	</div>
	
	
	

	<div className="repdates">{this.state.dispfromtodate}<br/>{this.state.disinvoice}</div>
	<div className="replogo">
	
	{this.state.orlogo!=="" &&(
 <p> <img  src={Uploadsurl+"uploads/"+this.state.orlogo} /> </p>
 )}
 
	</div>
	
	<div clssName="clear"></div>
	
	
	</div>
	</TableCell>
	
	


	
	
	
	
	
 </TableRow> 
 

 </Table>
 </TableContainer>
 </Paper>
 
 </div>
	
	<div style={{"display":"none"}}>
	
<Paper className={useStyles.root}>  
      <TableContainer className={useStyles.container}>  
        <Table stickyHeader aria-label="sticky table" id="purreport">  
        <TableHead>  
		
	
		
		
            <TableRow> 
		
		<TableCell><b>Invoice Type</b></TableCell>  
         <TableCell><b>Invoice No.</b></TableCell>  
<TableCell><b>Invoice Date</b></TableCell>  
<TableCell><b>Customer</b></TableCell>  
 		 
<TableCell><b>GSTIN</b></TableCell>  			 
 <TableCell><b>Grand Total</b></TableCell>        	
   
            </TableRow>  
          </TableHead>  
          <TableBody>  
            {this.state.exportData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => { 
			
               let	grandtotal=0;
let srate=0,taxableamount=0,taxablevalue=0,taxamount=0;
let crate=0	
let cgstamount=0;
let sgstamount=0;
let totalamount=0;
let totaltaxableamount=0;
let cgsttotal=0;
let sgsttotal=0;
let overalltotal=0;
let totalsgstamount=0;
let totalcgstamount=0;
		  
			   return ( 
                 <React.Fragment>
           <TableRow >  
               
			<TableCell>{row.mavalue}</TableCell>
            <TableCell><b style={{"font-size":"16px"}}>{row.or_invoice}</b></TableCell>
           <TableCell><b style={{"font-size":"16px"}}>{row.or_createddate}</b></TableCell>
		   
		    <TableCell>{row.cu_contactname}</TableCell>
			 <TableCell>{row.cu_gstinno}</TableCell>
			  <TableCell> {row.grandtotal}</TableCell>
			
			
              </TableRow> 
              
  <TableRow className="expandable" ref="expanderBody" key={"tr-expander"}> 
      <TableCell className="uk-background-muted" colSpan={6}> 
      <TableContainer className={useStyles.container}>  
        <Table className="intable" stickyHeader aria-label="sticky table"> 
        <TableHead>  
            <TableRow>   
            
			
               
            
			  <th></th>
			  <th>Taxable Amount</th>
			   {(row.pl_name).toUpperCase()==="KARNATAKA" && (
   <React.Fragment>
			  <th align="center" colSpan={2}>CGST</th>
			  <th align="center"colSpan={2}>SGST</th>
			     </React.Fragment>
			   )}
			    {(row.pl_name).toUpperCase()!=="KARNATAKA" && (
   <React.Fragment>
    <th align="center"colSpan={4}>IGST</th>
    </React.Fragment>
			   )}
   
			  <th>Total Amount</th>
			
			 
			  </TableRow>
			  
			  <TableRow>
			  
	
	 <th>#</th>
	 <th>Taxable</th>
	 {(row.pl_name).toUpperCase()==="KARNATAKA" && (
   <React.Fragment>
	 <th>Rate</th>
	 <th>Amount</th>
	 </React.Fragment>
			   )}
	 <th>Rate</th>
	 <th>Amount</th>
	 <th>Total</th>
	
	  
               
            </TableRow>  
          </TableHead>  
		  
		  
		  
     	  
      {this.state.exportData[index].subinvoice.map((roweach) => {

 grandtotal=(Number(grandtotal)+Number(roweach.totalamount)).toFixed(2)
 
 
 
 cgsttotal=(Number(cgsttotal)+Number(roweach.cgstamount)).toFixed(2)
 
 sgsttotal=(Number(sgsttotal)+Number(roweach.cgstamount)).toFixed(2);
 
taxablevalue=0;taxamount=0;

let taxablveamtqty=(parseFloat(roweach.amount));
			
			taxablveamtqty=((parseFloat(taxablveamtqty) * (100 / (100 + parseFloat(((roweach.op_tax_value))) ))));
			  
			  taxablevalue=parseFloat(taxablveamtqty);
		//let strtax=parseFloat(eachrep.amount)-(parseFloat(eachrep.amount) * (100 / (100 + parseFloat(((eachrep.tax)/2)) )));
	let strtax=((parseFloat(taxablveamtqty )* Number(roweach.op_tax_value))/100)/2;
		
 cgstamount=(strtax).toFixed(2);
 taxamount=parseFloat(taxamount)+ parseFloat(strtax);
 srate=(parseFloat(roweach.op_tax_value)/2).toFixed(2);

 
 sgstamount=strtax
 
totaltaxableamount=(Number(totaltaxableamount)+Number(taxablevalue)).toFixed(2)
 totalamount=(parseFloat(taxablevalue)+parseFloat(cgstamount)+parseFloat(cgstamount)).toFixed(2)
 totalsgstamount=(Number(totalsgstamount)+Number(cgstamount)).toFixed(2)
 totalcgstamount=(Number(totalcgstamount)+Number(cgstamount)).toFixed(2)
 overalltotal=(Number(overalltotal)+Number(totalamount)).toFixed(2)
              return ( 
     
        
          <TableBody>
		  
          <TableRow> 
		
	
	 <th>#</th>
	 <td> {(taxablevalue).toFixed(2)}</td>
	  {(row.pl_name).toUpperCase()==="KARNATAKA" && (
   <React.Fragment>
	 <td>{srate}%</td>
	 <td> {cgstamount}</td>
	 <td>{srate}%</td>
	 <td>{cgstamount}</td>
	  </React.Fragment>
			   )}
			    {(row.pl_name).toUpperCase()!=="KARNATAKA" && (
   <React.Fragment>
    <td colSpan={2}>{roweach.op_tax_value}%</td>
	 <td colSpan={2}> {(parseFloat(cgstamount)+parseFloat(cgstamount)).toFixed(2)}</td>
   </React.Fragment>
			   )}
	 <td align="center"> {totalamount}</td>

	  
        </TableRow>
		
		
		
		
		
		
		 
		
		
		
		
	</TableBody> 
        
              )})}
			  
				  
			<TableRow> 
		

	 <th>Total</th>
	 <td> {totaltaxableamount}</td>
	 {(row.pl_name).toUpperCase()==="KARNATAKA" && (
   <React.Fragment> 
	 <td></td>
	 <td> {totalcgstamount}</td>
	 <td></td>
	 <td> {totalsgstamount}</td>
	      </React.Fragment>  )}
	 {(row.pl_name).toUpperCase()!=="KARNATAKA" && (
   <React.Fragment> 
   <td  colSpan={2}></td>
	 <td colSpan={2}>Rs. {(parseFloat(totalcgstamount)+parseFloat(totalsgstamount)).toFixed(2)}</td>
     </React.Fragment>  )}
	 <td align="center"> {Math.floor(overalltotal)}</td>
	
	  
        </TableRow>  
			  
			  
              </Table> 
           </TableContainer>
                 </TableCell>
           </TableRow> 
     
              </React.Fragment>   
              );  
            })}  
			
			
		{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="13">No Records</TableCell>
					</TableRow>
					) }
			
          </TableBody>  
        </Table>  
      </TableContainer>  
       
    </Paper> 
	</div>
	
	<div className="main">
	
	
<Paper className={useStyles.root}>  
      <TableContainer className={useStyles.container}>  
        <Table stickyHeader aria-label="sticky table" id="purreport">  
        <TableHead>  
		
	
		
		
            <TableRow> 
		
		<TableCell><b>Invoice Type</b></TableCell>  
         <TableCell><b>Invoice No.</b></TableCell>  
<TableCell><b>Invoice Date</b></TableCell>  
<TableCell><b>Customer</b></TableCell>  
 		 
<TableCell><b>GSTIN</b></TableCell>  			 
 <TableCell><b>Grand Total</b></TableCell>        	
   
            </TableRow>  
          </TableHead>  
          <TableBody>  
            {this.state.InvoiceData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => { 
			
               let	grandtotal=0;
let srate=0,taxableamount=0,taxablevalue=0,taxamount=0;
let crate=0	
let cgstamount=0;
let sgstamount=0;
let totalamount=0;
let totaltaxableamount=0;
let cgsttotal=0;
let sgsttotal=0;
let overalltotal=0;
let totalsgstamount=0;
let totalcgstamount=0;
		  
			   return ( 
                 <React.Fragment>
           <TableRow >  
               
			<TableCell>{row.mavalue}</TableCell>
            <TableCell><b style={{"font-size":"16px"}}>{row.or_invoice}</b></TableCell>
           <TableCell><b style={{"font-size":"16px"}}>{row.or_createddate}</b></TableCell>
		   
		    <TableCell>{row.cu_contactname}</TableCell>
			 <TableCell>{row.cu_gstinno}</TableCell>
			  <TableCell>Rs. {row.grandtotal}</TableCell>
			
			
              </TableRow> 
              
  <TableRow className="expandable" ref="expanderBody" key={"tr-expander"}> 
      <TableCell className="uk-background-muted" colSpan={6}> 
      <TableContainer className={useStyles.container}>  
        <Table className="intable" stickyHeader aria-label="sticky table"> 
        <TableHead>  
            <TableRow>   
            
			
               
            
			  <th></th>
			  <th>Taxable Amount</th>
			    {(row.pl_name).toUpperCase()==="KARNATAKA" && (
   <React.Fragment>
			  <th align="center" colSpan={2}>CGST</th>
			  <th align="center"colSpan={2}>SGST</th>
			   </React.Fragment>
				)}
			 {(row.pl_name).toUpperCase()!=="KARNATAKA" && (
   <React.Fragment>
			  <th align="center" colSpan={4}>IGST</th>
		
			   </React.Fragment>
				)}
			  <th>Total Amount</th>
			
			 
			  </TableRow>
			  
			  <TableRow>
			  
	
	 <th>#</th>
	 <th>Taxable</th>
	 {(row.pl_name).toUpperCase()==="KARNATAKA" && (
   <React.Fragment> 
	 <th>Rate</th>
	 <th>Amount</th>
	 <th>Rate</th>
	 <th>Amount</th>
	  </React.Fragment>
				)}
	  {(row.pl_name).toUpperCase()!=="KARNATAKA" && (
   <React.Fragment> 
	 <th colSpan={2}>Rate</th>
	 <th colSpan={2}>Amount</th>

	  </React.Fragment>
				)}
	 <th>Total</th>
	
	  
               
            </TableRow>  
          </TableHead>  
		  
		  
		  
      {this.state.InvoiceData[index].subinvoice.map((roweach) => {

 grandtotal=(Number(grandtotal)+Number(roweach.totalamount)).toFixed(2)
 
 
 
 cgsttotal=(Number(cgsttotal)+Number(roweach.cgstamount)).toFixed(2)
 
 sgsttotal=(Number(sgsttotal)+Number(roweach.cgstamount)).toFixed(2);
 
taxablevalue=0;taxamount=0;

let taxablveamtqty=(parseFloat(roweach.amount));
			
			taxablveamtqty=((parseFloat(taxablveamtqty) * (100 / (100 + parseFloat(((roweach.op_tax_value))) ))));
			  
			  taxablevalue=parseFloat(taxablveamtqty);
		//let strtax=parseFloat(eachrep.amount)-(parseFloat(eachrep.amount) * (100 / (100 + parseFloat(((eachrep.tax)/2)) )));
	let strtax=((parseFloat(taxablveamtqty )* Number(roweach.op_tax_value))/100)/2;
		
 cgstamount=(strtax).toFixed(2);
 taxamount=Number(taxamount)+ parseFloat(strtax);
 srate=(Number(roweach.op_tax_value)/2).toFixed(2);

 
 sgstamount=strtax
 
totaltaxableamount=(Number(totaltaxableamount)+Number(taxablevalue)).toFixed(2)
 totalamount=(parseFloat(taxablevalue)+parseFloat(cgstamount)+parseFloat(cgstamount)).toFixed(2)
 totalsgstamount=(Number(totalsgstamount)+Number(cgstamount)).toFixed(2)
 totalcgstamount=(Number(totalcgstamount)+Number(cgstamount)).toFixed(2)
 overalltotal=(Number(overalltotal)+Number(totalamount)).toFixed(2)
              return ( 
     
        
          <TableBody>
		  
          <TableRow> 
		
	
	 <th>#</th>
	 <td>Rs. {(taxablevalue).toFixed(2)}</td>
	  {(row.pl_name).toUpperCase()==="KARNATAKA" && (
   <React.Fragment> 
	 <td>{srate}%</td>
	 <td>Rs. {cgstamount}</td>
	 <td>{srate}%</td>
	 <td>Rs. {cgstamount}</td>
	 </React.Fragment>  )}
	  {(row.pl_name).toUpperCase()!=="KARNATAKA" && (
   <React.Fragment> 
    <td colSpan={2}>{roweach.op_tax_value}%</td>
	
	  <td colSpan={2}> {(parseFloat(cgstamount)+parseFloat(cgstamount)).toFixed(2)}</td>
    </React.Fragment>  )}
	 <td align="center">Rs. {totalamount}</td>

	  
        </TableRow>
		
		
		
		
		
		
		 
		
		
		
		
	</TableBody> 
        
              )})}
			  
				  
			<TableRow> 
		

	 <th>Total</th>
	 <td>Rs. {totaltaxableamount}</td>
	 {(row.pl_name).toUpperCase()==="KARNATAKA" && (
   <React.Fragment> 
	 <td></td>
	 <td>Rs. {totalcgstamount}</td>
	 <td></td>
	 <td>Rs. {totalsgstamount}</td>
	  </React.Fragment>  )}
	   {(row.pl_name).toUpperCase()!=="KARNATAKA" && (
   <React.Fragment> 
   <td  colSpan={2}></td>
	 <td colSpan={2}>Rs. {(parseFloat(totalcgstamount)+parseFloat(totalsgstamount)).toFixed(2)}</td>
     </React.Fragment>  )}
	 <td align="center">Rs. {Math.floor(overalltotal)}</td>
	
	  
        </TableRow>  
			  
			  
              </Table> 
           </TableContainer>
                 </TableCell>
           </TableRow> 
     
              </React.Fragment>   
              );  
            })}  
			
			
		{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="13">No Records</TableCell>
					</TableRow>
					) }
			
          </TableBody>  
        </Table>  
      </TableContainer>  
      <TablePagination  
        rowsPerPageOptions={[50, 100, 150]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />  
    </Paper>  
	
	</div>
	
	</div></div></div></div>
	
</React.Fragment>


</Section>
</Page>
   
 

	 	


);
 
  }	
   
   

}
 
		 