import React ,{Component} from 'react';


import { Page, Section } from 'react-page-layout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
  
 
  
  
}));
export default class customer extends Component {
constructor(props) {
    super(props);
   
    this.state = {logredirect:false,pagename:''
    
	 
	  
	          
      }
       this.insertDonenewParent = this.insertDonenewParent;
	   
	}


 

 Useraddbox(val){
       // this.setState({ cuid: val });  
  
         setTimeout(() => this.refs.Newcustomer.handleClickOpen(),500);

      }


render() {

  return (
  

	<React.Fragment>

<p className="title"  onClick={()=>{this.Useraddbox(0)}}><AddIcon className={useStyles.icon}/>Add New Customer</p>
	<Newcustomer ref="Newcustomer"  updateDonenew={this.insertDonenewParent}/>					
   
 </React.Fragment>


 );
}

	
	
}

export  class  Newcustomer extends Component {



 constructor(props) {
    super();
	
	
	
	 this.state = {
	 
	 
	search:'',order:'desc' ,orderBy:'cu_id',cu_id:0,contactname:'',email:'',mobileno:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',payment:'',
	place:'',placearray:[],paymentarray:[],payment:'',cuid:'',open:false,
	 
	  
        
      }
	 };
  
 
  onloadplacebind(){

    axios.get(Serverurl+"ajaxstate.php?action=list")
    
      .then((response) => {    
     
      this.setState({  placearray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.placearray.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      
		
        this.setState({
          placearray: [{value: '0', display: 'Select State'}].concat(roleFromApi)
        });
      })


}

onloadpaymentbind(){

    axios.get(Serverurl+"ajaxpaymentterms.php?action=list")
    
      .then((response) => {    
     
      this.setState({  paymentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.paymentarray.map(role => {
                            
          return {value: role.pt_id, display:role.pt_title}
        });
      
		
        this.setState({
          paymentarray: [{value: '0', display: 'Select Payment'}].concat(roleFromApi)
        });
      })


}
componentDidMount() {
 
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
 
 
   let search = window.location.search;
let params = new URLSearchParams(search);
let cuid = params.get('cuid');


if(cuid!=null)
{
	
	setTimeout(()=>this.Usergetdata(cuid),500); 
	
	
}


} 
  
handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }  
  
  
  Usergetdata(cuid) {
	
 
	  
	   axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
  
      cu_id:resp.data[0].cu_id,
	
	
	contactname:resp.data[0].cu_contactname, 
   
	email:resp.data[0].cu_email,
	mobileno:resp.data[0].cu_mobileno,
	
	panno:resp.data[0].cu_panno,
	
	gstinno:resp.data[0].cu_gstinno,
	billingassress:resp.data[0].cu_billingassress,
	shippingaddress:resp.data[0].cu_shippingaddress,
	
	
	payment:resp.data[0].cu_payment,
	place:resp.data[0].cu_pl_id,
	
	
       	  
	  
       
    
    });

  }
  
});
	  
  
  
    }

   
handleClickOpen = (e) => {
 
  this.setState({ open:!this.state.open,cu_id:0,contactname:'',email:'',mobileno:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',payment:'',
	place:'',placearray:[],paymentarray:[],payment:''
	  
		 });
		this.Usergetdata();
		
this.onloadplacebind();

this.onloadpaymentbind();



};
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

}



handleClose = (e) => {

 
 
 
  
	let contactname = this.state.contactname;
    let email = this.state.email;
	let mobileno = this.state.mobileno;
  
	let panno = this.state.panno;
	
	
	let gstinno = this.state.gstinno;
    let billingassress = this.state.billingassress;
	let shippingaddress = this.state.shippingaddress;
    
	

	let payment = this.state.payment;
	
	let place = this.state.place;
	
	
 var formData = new FormData();

	

if(contactname==="")
{
	this.setState({errormessage:"Please Enter Contact Name.",open:true});
	return false;
}	


if(mobileno==="")
{
	this.setState({errormessage:"Please Enter mobileno.",open:true});
	return false;
}




if(panno==="")
{
	this.setState({errormessage:"Please Select panno.",open:true});
	return false;
}

if(gstinno==="")
{
	this.setState({errormessage:"Please Enter gstinno.",open:true});
	return false;
}

if(billingassress==="")
{
	this.setState({errormessage:"Please Enter Billing Address.",open:true});
	return false;
}


if(shippingaddress==="")
{
	this.setState({errormessage:"Please Enter Shipping Address.",open:true});
	return false;
}




  
  
  
  
	  
	 
	 	

  formData.append('action','insertorupdate') 


 formData.append('cu_id',0) 
 formData.append('cu_contacttype',1)
formData.append('cu_contactname',contactname)
formData.append('cu_email',email)
formData.append('cu_mobileno',mobileno)

formData.append('cu_panno',panno)


formData.append('cu_gstinno',gstinno)
formData.append('cu_billingassress',billingassress)
formData.append('cu_shippingaddress',shippingaddress)


formData.append('cu_payment',payment)
formData.append('cu_pl_id',place)



		
		
		/*formData.append('userid',1)
		  
		axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
    
        
    
        });
  
	this.setState({ open:false });*/
	
	formData.append('userid',1)	
	
	 axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
			
			//this.props.updateDonenew();
			
			
		  if(res.data==-1)
{ 
	 

		this.setState({errormessage:"Email Already Exist.",open:true
		});

	}else{
		this.setState({errormessage:"Record Udated Successfuly",open:true	,
		
			
			
			
			
			 redirect: true,
			
	
		
		contactname:'',
		email:'',
		mobileno:'',
		
		panno:'',
		
		gstinno:"",
		billingassress:'',
		shippingaddress:'',
		
		
		
		
		payment:'',
		place:'',
		
		
		cu_id:0
		});
			
	}		
            });
	
	
	
};

render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Add  Customer</DialogTitle>
      <DialogContent>
	  
	  
	  <p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	  
		<div className="newcustomer">				  
						 
      <ul>


		  

       
		  
		<li>  
		<p>Contact Name <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.contactname}
            id="contactname"
          
            name="contactname" onChange={this.myChangeHandler}
            autoComplete="contactname"
            
			
          /></p></li>
		  
	 
		  
		<li>  
		<p>Email </p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.email}
            id="email"
          
            name="email" onChange={this.myChangeHandler}
            autoComplete="email"
			
            
			
          /></p></li>
		  
		 
		 
		 <li>  
		 <p>Mobile.No <span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.mobileno}
            id="mobileno"
           
            name="mobileno" onChange={this.myChangeHandler}
            autoComplete="mobileno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		  
		 
		 
		 
		 
		 
		 <li>  
		 <p>Pan.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.panno}
            id="panno"
           
            name="panno" onChange={this.myChangeHandler}
            autoComplete="panno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		 
		
		 
		 <li> 
<p>GSTIN.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.gstinno}
            id="gstinno"
          
            name="gstinno" onChange={this.myChangeHandler}
            autoComplete="gstinno"
			
            
			
          /></p></li>
		  
		 
		 
		  <li> 
<p>Payment Terms <span className="validation">*</span></p>
		  <p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.payment }}
          onChange={this.myChangeHandler}
          id="payment"
		   value={this.state.payment}
		  name="payment"
		
		
        >
		{this.state.paymentarray.map(payment => (
            <option  
            
              value={payment.value}
            >
              {payment.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></li>
		  
		 
		 
		 
		 <li> 
		 <p>State <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.place }}
          onChange={this.myChangeHandler}
          id="place"
		   value={this.state.place}
		  name="place"
		
		
        >
		{this.state.placearray.map(place => (
            <option  
            
              value={place.value}
            >
              {place.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>
		
		
		
		<li>
<p>Billing Address</p>
		 <p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.billingassress}
            id="billingassress"
			
            fullWidth
           
            name="billingassress" onChange={this.myChangeHandler}
            autoComplete="billingassress"
            autoFocus
          />  </p></li>
		  
		  
		  
		<li> 
<p>Shipping Address</p>
		<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.shippingaddress}
            id="shippingaddress"
			
            fullWidth
           
            name="shippingaddress" onChange={this.myChangeHandler}
            autoComplete="shippingaddress"
            autoFocus
          />  </p></li>
		  
		  
		
		
		
		
		
		
		</ul>
		</div>
     
						  
			
                 
               </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button>
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		
		 
		
      </DialogActions>
    </Dialog>
</React.Fragment>





) }
  

} 


  