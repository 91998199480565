import React ,{Component} from 'react';

import { Page, Section } from 'react-page-layout';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';



import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';


const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));

export default class addcustomer extends Component {
	
	constructor(props) {
    super();
	
	var today = new Date();
	var todayMonth=(today.getMonth() + 1);
	if(todayMonth<10)
		todayMonth='0'+todayMonth;
    var        cudate = today.getFullYear() + '-' + todayMonth + '-' + today.getDate();
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],redirect: false,
	 
	 
	search:'',order:'desc' ,orderBy:'cu_id',cu_id:0,contactplace:'',contactname:'',email:'',mobileno:'',phonenum:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',billigaddress1:'',shippingaddress1:'',cityifield:'',pincode:'',accountnum:'',ifsccode:'',bankname:'',branchname:'',dob:'',weddinganniversary:'',payment:'',
	place:'',type:'',placearray:[],fdate:cudate,fdate1:'',adate:cudate,adate1:'',paymentarray:[],payment:'',cityifield:'',pincode:'',shiplabel:'',shipstate:'',shippincode:'',shipcntlabel:'',shipcountry:'',shipcity:'',
		btndisabled:false,countrylabel:'',country:'',state:'',statedata:[],shipstatedata:[],shipcountrydata:[],
submitbtn:"Submit",
	 
	 
	  
        
      }
	 };


 


onloadStatebind(){

    axios.get(Serverurl+"ajaxstate.php?action=list&pl_type=2")
    
      .then((response) => {    
     
      this.setState({  statedata: response.data });
      })   
    
      .then(data => {
        let roleFromApi = this.state.statedata.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      this.setState({
          statedata: [{value: '0', display: 'State'}].concat(roleFromApi),
		  
		  shipstatedata:[{value: '0', display: 'State'}].concat(roleFromApi),
        });
      })

}

onloadplacebind(){

    axios.get(Serverurl+"ajaxstate.php?action=list&pl_type=1")
    
      .then((response) => {    
     
      this.setState({  placearray: response.data,  });
	  
	  this.onloadStatebind();
      })   
    
      .then(data => {
        let roleFromApi = this.state.placearray.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      this.setState({
          placearray: [{value: '0', display: 'Country'}].concat(roleFromApi),
		  shipcountrydata: [{value: '0', display: 'Country'}].concat(roleFromApi),
        });
		
		
      })

}


onloadpaymentbind(){

    axios.get(Serverurl+"ajaxpaymentterms.php?action=list")
    
      .then((response) => {    
     
      this.setState({  paymentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.paymentarray.map(role => {
                            
          return {value: role.pt_id, display:role.pt_title}
        });
      
		
        this.setState({
          paymentarray: [{value: '0', display: 'Payment'}].concat(roleFromApi)
        });
      });


}


handfrChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }
  



handanChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ adate:date,
adate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }

getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


componentDidMount() {
 
 	if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2" || localStorage.getItem("Rrole") === "4")
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}
	
 
 /*
   let search = window.location.search;
let params = new URLSearchParams(search);
let cuid = params.get('cuid');*/

	let search = window.location.href;
			let cuid = this.getParameterByName('cuid',search);
if(cuid!=null)
{
	
	setTimeout(()=>this.onloaduserbyid(cuid),500); 
	//this.onloaduserbyid(cuid);
	
}


this.onloadplacebind();
//alert (this.state.fdate1);
this.onloadpaymentbind();

}

myChangeHandler = (event) => {
	
	const re = /^[0-9\b]+$/; 
	
  let nam = event.target.name;
  let val = event.target.value;
  
  if(nam==="mobileno" || nam==="phonenum")
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }
  
  
  
  
 

 }  

myRoleChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 this.setState({[nam]: val});
  


}

handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
  
  
onloaduserbyid(cuid){
		
		 axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
   if(resp.data.length>0){
	   
    this.setState({ 
   	cu_id:resp.data[0].cu_id,
	
	contactplace:resp.data[0].contacttype,	
	contactname:resp.data[0].cu_contactname, 
   
	email:resp.data[0].cu_email,
	mobileno:resp.data[0].cu_mobileno,
	phonenum:resp.data[0].cu_phonenum,
	panno:resp.data[0].cu_panno,
	
	gstinno:resp.data[0].cu_gstinno,
	billingassress:resp.data[0].cu_billingassress,
	shippingaddress:resp.data[0].cu_shippingaddress,
	billigaddress1:resp.data[0].cu_billingaddress1,
	shippingaddress1:resp.data[0].cu_shippingaddress1,
	
	fdate:resp.data[0].cu_dob,
	
	
	adate:resp.data[0].cu_weddinganniversary,
	payment:resp.data[0].cu_payment,
	state:resp.data[0].cu_pl_id,
	country:resp.data[0].cu_billingcountryid,
	cityifield:resp.data[0].cu_billingcity,
	pincode:resp.data[0].cu_billingpincode,
	accountnum:resp.data[0].cb_accountnum,
	
	ifsccode:resp.data[0].cb_ifsccode,
	bankname:resp.data[0].cb_bankname,
	branchname:resp.data[0].cb_branchname,
	shipstate:resp.data[0].cu_shippingstate,
	shipcity:resp.data[0].cu_shippingcity,
	shipcountry:resp.data[0].cu_shippingcountryid,
  shippincode:resp.data[0].cu_shippingpincode,
	});
	
   }
  });

} 


handleCustomerSubmit= (event) => {
    
   
	
    
  
    let contactplace = this.state.contactplace;
	let contactname = this.state.contactname;
    let email = this.state.email;
	let mobileno = this.state.mobileno;
    let phonenum = this.state.phonenum;
	let panno = this.state.panno;
	
	
	let gstinno = this.state.gstinno;
    let billingassress = this.state.billingassress;
	let shippingaddress = this.state.shippingaddress;
	let billingaddress1 = this.state.billigaddress1;
	let shippingaddress1 = this.state.shippingaddress1;
    
	
	let fdate = this.state.fdate1;
	let adate = this.state.adate1;
	let payment = this.state.payment;
	let cityifield = this.state.cityifield;
	let pincode = this.state.pincode;
	
	let state = this.state.state;
	let country=this.state.country;
	let accountnum = this.state.accountnum;
	let ifsccode = this.state.ifsccode;
    let bankname = this.state.bankname;
	let branchname = this.state.branchname;
		let shipcity = this.state.shipcity;
   	let shipstate = this.state.shipstate;
	let shippincode = this.state.shippincode;
		let shipcountry = this.state.shipcountry;
	 const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
	
	


if(contactplace==="")
{
	
	this.setState({errormessage:"Please Select Contact Type.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	

if(contactname==="")
{
	this.setState({errormessage:"Please Enter Contact Name.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	

/*
if (! remail.test(email)) {
	  this.setState({errormessage:"Please Enter valid Email.",open:true});
	  window.scrollTo({
          top: 0
      })
	return false;
  }
*/

/*if(mobileno==="")
{
	this.setState({errormessage:"Please Enter Mobile No.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}*/

/*
if(phonenum==="")
{
	this.setState({errormessage:"Please Enter Phone Number.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}*/
/*
if(panno==="")
{
	this.setState({errormessage:"Please Select panno.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(gstinno==="")
{
	this.setState({errormessage:"Please Enter gstinno.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(billingassress==="")
{
	this.setState({errormessage:"Please Enter Billing Address.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}


if(shippingaddress==="")
{
	this.setState({errormessage:"Please Enter Shipping Address.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}*/

if(payment==="")
{
	this.setState({errormessage:"Please Select Payment.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(state==="")
{
	this.setState({errormessage:"Please Select Billing State.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(country==="")
{
	this.setState({errormessage:"Please Select Billing Country.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}
	  if(cityifield==="")
{
	this.setState({errormessage:"Please Enter Billing City.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	
	  if(pincode==="")
{
	this.setState({errormessage:"Please Enter Billing Pincode.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(shipstate==="")
{
	this.setState({errormessage:"Please Select Shipping State.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(shipcountry==="")
{
	this.setState({errormessage:"Please Select Shipping Country.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}
	  if(shipcity==="")
{
	this.setState({errormessage:"Please Enter Shipping City.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	
	  if(shippincode==="")
{
	this.setState({errormessage:"Please Enter  Shipping Pincode.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

	  var formData = new FormData();
	 if(this.state.cu_id===0)
	  {
		  formData.append('cu_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('cu_id',this.state.cu_id)
		
          
	  }

	  		 
	 //alert(this.state.cu_id)
	
//alert(name);

this.setState({btndisabled:true,submitbtn:"Processing..."})
formData.append('action','insertorupdate') 


formData.append('cu_contacttype',contactplace)
formData.append('cu_contactname',contactname)
formData.append('cu_email',email)
formData.append('cu_mobileno',mobileno)
formData.append('cu_phonenum',phonenum)
formData.append('cu_panno',panno)


formData.append('cu_gstinno',gstinno)
formData.append('cu_billingassress',billingassress)
formData.append('cu_shippingaddress',shippingaddress)
formData.append('cu_billingaddress1',billingaddress1)
formData.append('cu_shippingaddress1',shippingaddress1)


formData.append('cu_dob',fdate)
formData.append('cu_weddinganniversary',adate)
formData.append('cu_payment',payment)
formData.append('cu_pl_id',state)
formData.append('cu_billingcountryid',country)
formData.append('cu_billingcity',cityifield)
formData.append('cu_billingpincode',pincode)

formData.append('cb_accountnum',accountnum)
formData.append('cb_ifsccode',ifsccode)
formData.append('cb_bankname',bankname)
formData.append('cb_branchname',branchname)

formData.append('cu_shippingstate',shipstate)
formData.append('cu_shippingcountryid',shipcountry)
formData.append('cu_shippingcity',shipcity)
formData.append('cu_shippingpincode',shippincode)


	   
formData.append('userid',localStorage.getItem("Ruserid"))	
	 
	   	  axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
			
			
		  if(res.data==-1)
{ 
	 

		this.setState({errormessage:"Email Already Exist.",open:true,btndisabled:false,submitbtn:"Submit"
		});

	}else{
		this.setState({errormessage:"Record Updated Successfuly",open:true,
		btndisabled:false,submitbtn:"Submit",
		
			shipstate:'',shipcountry:'',shipcity:'',shippincode:'',
			
			
			
			 redirect: true,
			
	
		contactplace:"",
		contactname:'',
		email:'',
		mobileno:'',
		phonenum:'',
		panno:'',
		
		gstinno:"",
		billingassress:'',
		shippingaddress:'',
		billigaddress1:'',
		shippingaddress1:'',
		
		
		dob:'',
		weddinganniversary:'',
		payment:'',
		state:'',
		country:'',
		cityifield:'',
		pincode:'',
		accountnum:'',
		ifsccode:'',
		bankname:'',
		branchname:'',
		
		cu_id:0
		});
			
	}		
            });
			
};




 handleClickdiscard = (e) => {
 
  this.setState({ open:!this.state.open,  cu_id:0,contactplace:'',contactname:'',email:'',mobileno:'',phonenum:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',accountnum:'',ifsccode:'',bankname:'',branchname:'',dob:'',weddinganniversary:'',payment:'',
	place:'',type:'',placearray:[],paymentarray:[],payment:'',dob:'',cityifield:'',pincode:'',state:'',country:'',
		weddinganniversary:'',
	 
	  
		 });
		


};

	

render() {
	if (this.state.redirect === true) {
      return <Redirect to='/admincustomer' />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	
	
	return (
	
	
		<Page layout="public">

<Section slot="menu"> / <a href='#/configuration' tabIndex="-1">Configuration</a> / Contacts
</Section>

<Section slot="btn"><a href='#/admincustomer' tabIndex="-1">Back</a>
</Section>

<Section slot="content">
	
	
<div className="customerform">

<div className="main">


<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>



<div className="headername">
		 <p><b>  New Customer</b></p>
		 </div>

<div className="formbgclr">



      <ul>


        <li>  
		<p>Contact Type <span className="validation">*</span></p>
		<p> <FormControl  className={useStyles.formControl} >
   
        <Select
          native 	inputProps={{ tabIndex: "1" }} 
		  variant="outlined"
            margin="normal"
         value={this.state.contactplace}
          onChange={this.myRoleChangeHandler}
        name='contactplace'
         id='contactplace'>
		 autoFocus
          <option value={0}>Type</option>
		  <option value={1}>Customer</option>
		  <option value={2}>Vendor</option>
		 
		  
          
        </Select>
      </FormControl>
		  </p>
		   
		
		</li>
		  
		<li>  
		<p>Contact Name <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
         	inputProps={{ tabIndex: "2",maxLength:150 }} 
            fullWidth
			value={this.state.contactname}
            id="contactname"
          
            name="contactname" onChange={this.myChangeHandler}
            autoComplete="contactname"
            
			
          /></p></li>
		  
	 
		  
		<li>  
		<p>Email </p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           	inputProps={{ tabIndex: "3",maxLength:150 }} 
            fullWidth
			value={this.state.email}
            id="email"
          
            name="email" onChange={this.myChangeHandler}
            autoComplete="email"
			
            
			
          /></p></li>
		  
		 
		 
		 <li>  
		 <p>Mobile.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           	inputProps={{ tabIndex: "4",maxLength:12 }} 
            fullWidth
			value={this.state.mobileno}
            id="mobileno"
           
            name="mobileno" onChange={this.myChangeHandler}
            autoComplete="mobileno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		  
		 
		 <li> 
<p>Phone.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
              	inputProps={{ tabIndex: "5",maxLength:12 }} 
            fullWidth
			value={this.state.phonenum}
            id="phonenum"
           
            name="phonenum" onChange={this.myChangeHandler}
            autoComplete="phonenum"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		 
		 
		 
		 <li>  
		 <p>Pan.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
              	inputProps={{ tabIndex: "4",maxLength:150 }} 
            fullWidth
			value={this.state.panno}
            id="panno"
           
            name="panno" onChange={this.myChangeHandler}
            autoComplete="panno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		 
		
		 
		 <li> 
<p>GSTIN.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "5",maxLength:150 }} 
            fullWidth
			value={this.state.gstinno}
            id="gstinno"
          
            name="gstinno" onChange={this.myChangeHandler}
            autoComplete="gstinno"
			
            
			
          /></p></li>
		  
		  	 <div className="clear"></div> 
		  <li>
		 <p>Billing State <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "14"}}
		  defaultValue={{ label: this.state.rolelabel, value: this.state.state }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.state}
		  name="state"
		
		
        >
		{this.state.statedata.map(state => (
            <option  
            
              value={state.value}
            >
              {state.display}
            </option>
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>
		
		 <li> 
		 <p>Billing Country <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "13"}}
		  defaultValue={{ label: this.state.countrylabel, value: this.state.country }}
          onChange={this.myChangeHandler}
          id="country"
		   value={this.state.country}
		  name="country"
		
		
        >
		{this.state.placearray.map(country => (
            <option  
            
              value={country.value}
            >
              {country.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>	<li> 
<p>Billing City<span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.cityifield}
            id="cityifield"
           inputProps={{ tabIndex: "15" ,maxLength:150}}
            name="cityifield" onChange={this.myChangeHandler}
            autoComplete="cityifield"
			
            
			
          /></p></li> <li> 
<p>Billing Pincode<span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.pincode}
            id="pincode"
           inputProps={{ tabIndex: "16" ,maxLength:10}}
            name="pincode" onChange={this.myChangeHandler}
            autoComplete="pincode"
			
            
			
          /></p></li>
		   <div className="customeraddress">
		  <ul>
		 <li>
<p>Billing Address1</p>
		 <p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
             rows={3}
		  variant="outlined"
            value={this.state.billingassress}
            id="billingassress"
			     	inputProps={{ tabIndex: "6"}}
            fullWidth
           
            name="billingassress" onChange={this.myChangeHandler}
            autoComplete="billingassress"
            autoFocus
          />  </p></li>
		  
		  <li>
<p>Billing Address2</p>
		 <p>
		   <TextField
            id="outlined-multiline-static"
         multiline
            rows={3}
          
		  variant="outlined"
            value={this.state.billigaddress1}
            id="billigaddress1"
			     	inputProps={{ tabIndex: "8"}}
            fullWidth
           
            name="billigaddress1" onChange={this.myChangeHandler}
            autoComplete="billigaddress1"
            autoFocus
          />  </p></li>
		  
		
		  </ul>
		 </div>
		<li> 
		 <p>Shipping State <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "14"}}
		  defaultValue={{ label: this.state.shiplabel, value: this.state.shipstate }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.shipstate}
		  name="shipstate"
		
		
        >
		{this.state.shipstatedata.map(state => (
            <option  
            
              value={state.value}
            >
              {state.display}
            </option>
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>
		
		 <li> 
		 <p>Shipping Country <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "13"}}
		  defaultValue={{ label: this.state.shipcntlabel, value: this.state.shipcountry }}
          onChange={this.myChangeHandler}
          id="shipcountry"
		   value={this.state.shipcountry}
		  name="shipcountry"
		
		
        >
		{this.state.shipcountrydata.map(country => (
            <option  
            
              value={country.value}
            >
              {country.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>
		
	
		  <li> 
<p>Shipping City<span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.shipcity}
            id="shipcity"
           inputProps={{ tabIndex: "15" ,maxLength:150}}
            name="shipcity" onChange={this.myChangeHandler}
            autoComplete="cityifield"
			
            
			
          /></p></li>
		 
		  <li> 
<p>Shipping Pincode<span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.shippincode}
            id="shippincode"
           inputProps={{ tabIndex: "16" ,maxLength:10}}
            name="shippincode" onChange={this.myChangeHandler}
            autoComplete="pincode"
			
            
			
          /></p></li>
		  
		 
		  
		  <div className="customeraddress">
		  <ul>
		 
		  <li> 
<p>Shipping Address1</p>
		<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
            rows={3}
		  variant="outlined"
            value={this.state.shippingaddress}
            id="shippingaddress"
				inputProps={{ tabIndex: "7"}}
            fullWidth
           
            name="shippingaddress" onChange={this.myChangeHandler}
            autoComplete="shippingaddress"
            autoFocus
          />  </p></li>
		  
		  
		<li> 
<p>Shipping Address2</p>
		<p>
		   <TextField
            id="outlined-multiline-static"
         
         multiline
          rows={3}
		  variant="outlined"
            value={this.state.shippingaddress1}
            id="shippingaddress1"
				inputProps={{ tabIndex: "9"}}
            fullWidth
           
            name="shippingaddress1" onChange={this.myChangeHandler}
            autoComplete="shippingaddress1"
            autoFocus
          />  </p></li>
		  </ul>
		 </div>
		  
		  
		  
		   <li> 
<p>Date of Birth</p>
		   <p>
		<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	 
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal"
        value={this.state.fdate }
        onChange={this.handfrChange}
           format="dd/MM/yyyy"
         name="fdate"
       inputProps={{ tabIndex: "10"}}
		id="dateborder"
		 className="dateborder"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</p></li>
	
	<li>  
		   <p>Wedding Anniversary</p>
		   <p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal"
        value={this.state.adate}
        onChange={this.handanChange}
            format="dd/MM/yyyy"
        autoOk name="adate"
        ampm={false}  inputProps={{ tabIndex: "11"}}
		id="dateborder"
		 className="dateborder"
		 
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</p>
		</li>
		  
		  <li> 
<p>Payment Terms <span className="validation">*</span></p>
		  <p><FormControl  className={useStyles.formControl} >
         
        <Select
         native inputProps={{ tabIndex: "12"}}
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.payment }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.payment}
		  name="payment"
		
		
        >
		{this.state.paymentarray.map(payment => (
            <option  
            
              value={payment.value}
            >
              {payment.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></li>
		  
		 
		 
		 <div className="clear"></div> 
		
		
		
		
<h3> Customer Bank Account Details : </h3>	
	
		<li> 
<p>Account.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.accountnum}
            id="accountnum"
           inputProps={{ tabIndex: "17" ,maxLength:150}}
            name="accountnum" onChange={this.myChangeHandler}
            autoComplete="accountnum"
			
            
			
          /></p></li>
		  
		  
		   <li>  
		   <p>IFSC Code</p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "18" ,maxLength:150}}
            fullWidth
			value={this.state.ifsccode}
            id="ifsccode"
           
            name="ifsccode" onChange={this.myChangeHandler}
            autoComplete="ifsccode"
			
            
			
          /></p></li>
		  
		  
		   <li> 
<p>Bank Name</p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
             inputProps={{ tabIndex: "19" ,maxLength:150}}
            fullWidth
			value={this.state.bankname}
            id="bankname"
          
            name="bankname" onChange={this.myChangeHandler}
            autoComplete="bankname"
			
            
			
          /></p></li>
		  
		  
		  
		   <li> 
<p>Branch Name</p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
                 inputProps={{ tabIndex: "20" ,maxLength:150}}
            fullWidth
			value={this.state.branchname}
            id="branchname"
          
            name="branchname" onChange={this.myChangeHandler}
            autoComplete="branchname"
			
            
			
          /></p></li>
		
		
		
		   </ul>
		   
		   
		  	   
		  
		
		  
	
		  
		  
		<div className="formbtns">
		  <ul>

		  
		  <li> <p> <Button
            place="submit"
            halfWidth
            variant="contained" 
            color="primary"
            id="discard"                 inputProps={{ tabIndex: "21" }}
            className={useStyles.submit}
			onClick={this.handleClickdiscard}
          >
           Discard
          </Button></p></li>
		  
		  
		  <li> <p> <Button
            place="submit"
            halfWidth
            variant="contained" 
			disabled={this.state.btndisabled}
			onClick={this.handleCustomerSubmit}
            color="primary"  inputProps={{ tabIndex: "22" }}
             id="searchform" 
            className={useStyles.submit}
			
          >
            {this.state.submitbtn}
          </Button></p></li>
		  </ul>
		  </div>
		  
		 
		  </div> 
		   


  
</div>
          
</div>


		  
		   
	 </Section></Page>	  	
		  
		 
		  




  );
  }
}










