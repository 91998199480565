import React, { Component } from 'react';
import { Slot } from 'react-page-layout';

import './styles.css';


import home from './images/home.png';
import configuration from './images/configuration.png';

import user from './images/user.png';
import contacts from './images/contacts.png';
import categories from './images/categories.png';
import warehouse from './images/warehouse.png';




class Displaymenu extends Component {
	
	constructor(props) {
		

    super();

	 this.state = {open:false,islog:false,redirect:false,pagename:''}
	}
	

	render() {

		return (
		  <React.Fragment>
		  

	
	{(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2") && (
		
 <div className="menulist">
 <ul>
 

	
 
 <li><a href='#/dashboard'><img src={home} /> Home</a></li>
 <li><a href='#/configuration'><img src={configuration} /> Configuration</a></li>
 <li>PEOPLE</li>
 <li><a href='#/adminuser'><img src={user} /> Users</a></li>
 <li><a href='#/changepassword'><img src={configuration} /> Change Password</a></li>
 <li><a href='#/admincustomer'><img src={contacts} /> Contacts</a></li>
 <li>INVENTORY</li>
 <li><a href='#/admincategories'><img src={categories} /> Categories</a></li>
 
 <li><a href='#/adminproduct'><img src={configuration} /> Products</a></li>
 <li>PURCHASE</li>
 
 <li><a href='#/invoicereport'><img src={configuration} /> Invoice</a></li>

<li><a href='#/Shippingreport'><img src={configuration} /> Generate Shipping</a></li>
<li><a href='#/Purchasereport'><img src={configuration} /> Purchase Bills</a></li>
<li><a href='#/Paymentmasterreport'><img src={configuration} /> Payments</a></li>
<li><a href='#/Receiptmasterreport'><img src={configuration} /> Receipts</a></li>

<li><a href='#/Quotationreport'><img src={configuration} /> Quotations</a></li>

<li><a href='#/Reports'><img src={configuration} /> Reports</a></li>
<li><a href='#/Reprint'><img src={configuration} /> Invoice Reprint</a></li>
<li><a href='#/Datewisereprint'><img src={configuration} /> Datewise Reprint</a></li>
<li><a href='#/Logout'><img src={configuration} /> Logout</a></li>

	
 


</ul>
 </div>
 
)} 
{(localStorage.getItem("Rrole") === "4") &&(
		
 <div className="menulist">
 <ul>
 

	
 
 <li><a href='#/dashboard'><img src={home} /> Home</a></li>

 <li>PEOPLE</li>

 <li><a href='#/changepassword'><img src={configuration} /> Change Password</a></li>
 <li><a href='#/admincustomer'><img src={contacts} /> Contacts</a></li>
 <li>INVENTORY</li>

 

 <li>PURCHASE</li>
 
 <li><a href='#/invoicereport'><img src={configuration} /> Invoice</a></li>
<li><a href='#/Customerinvoice'><img src={configuration} /> Generate Shipping</a></li>
<li><a href='#/Purchasereport'><img src={configuration} /> Purchase Bills</a></li>
<li><a href='#/Paymentmasterreport'><img src={configuration} /> Payments</a></li>
<li><a href='#/Receiptmasterreport'><img src={configuration} /> Receipts</a></li>

<li><a href='#/Quotationreport'><img src={configuration} /> Quotations</a></li>

<li><a href='#/Reports'><img src={configuration} /> Reports</a></li>
<li><a href='#/Reprint'><img src={configuration} /> Invoice Reprint</a></li>
<li><a href='#/Datewisereprint'><img src={configuration} /> Datewise Reprint</a></li>
<li><a href='#/Logout'><img src={configuration} /> Logout</a></li>

	
 


</ul>
 </div>
 
)} 
	
	{localStorage.getItem("Rrole") === "21" &&(
	
	<div className="menulist">
 <ul>
	
<li><a href='#/invoicereport'><img src={configuration} /> Invoice</a></li>

<li><a href='#/Quotationreport'><img src={configuration} /> Quotations</a></li>

 <li><img src={configuration} /> Reports</li>
 
<li><a href='#/Quotationbillreport'>Quotation Report</a></li>
<li><a href='#/Invoicebillreport'>Invoice Report</a></li>
<li><a href='#/Invoicegstreport'>Invoice GST Report</a></li>

<li><a href='#/Billgstreport'> GST Report</a></li>
<li><a href='#/Stockreport'>Stock Report</a></li>
<li><a href='#/Categoryreport'>Category Report</a></li>
<li><a href='#/Stockinventoryreport'>Stock Inventory Report</a></li>
<li><a href='#/Reprint'><img src={configuration} /> Invoice Reprint</a></li>
<li><a href='#/Datewisereprint'><img src={configuration} /> Datewise Reprint</a></li>
<li><a href='#/changepassword'><img src={configuration} /> Change Password</a></li>
<li><a href='#/Logout'><img src={configuration} /> Logout</a></li>

</ul>
 </div>
	
	)} 	
	
		  </React.Fragment>
		);
	}
}

export default Displaymenu;