import React ,{Component,useRef, useEffect} from 'react';

import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import Serverurl from './Apiurl';
import Uploadsurl from './Apiurl';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';


import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import SchoolIcon from '@material-ui/icons/School';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ReactToPrint from 'react-to-print';
import { Page, Section } from 'react-page-layout';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}




const headCells = [




{ id: 'pr_code', numeric: false,  label: <b>Product Code</b> },
{ id: 'op_title', numeric: false,  label: <b>Product Name</b> },
{ id: 'op_srateinc', numeric: false,  label: <b>S.Rate(Inc)</b> },
{ id: 'op_srateexc', numeric: false,  label: <b>S.Rate(Exc)</b> },


{ id: 'op_tax_value', numeric: false,  label: <b>Tax</b> },
{ id: 'op_quantity', numeric: false,  label: <b>Qty</b> },
{ id: 'op_grossamount', numeric: false,  label: <b>Amount</b> },

]




function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  row:{border:0},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 

 baseText: {
    fontFamily: "Cochin"
  },
  InvoicePrintpreview: {
    fontSize: 20,
    fontWeight: "bold"
  }

  
});



class customerviewmore extends Component {
	
	constructor(props) {
    super(props);
	
	this.state = {logredirect:false,pagename:'',customerviewmore:[],order:'desc',orderBy:'or_id',page: 0 ,
	prevpage:0,totaldata:0,rowsPerPage:20,cuid:'',orid:'',or_taxamount:0,sessionid:'',discount:'',roundoff:'',subtaotal:'',amount:0,disamt:0,
	grandtotal:'',orddiscount:0,proddiscount:0,
	redirect:false,vredirect:false,orgviewmore:[],invoiceviewmore:[],opviewmore:[]
}
	
	this.updateDonenewParent = this.updateDonenewParent;
	 
	  
	}
	
	 updateDonenewParent = (text) => {this.componentDidMount();  }
	myChangeHandler = (event) => {
	const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
 
}

componentDidUpdate() {
		
  if(this.state.prevpage != this.state.page) {
    this.setState({   prevpage: this.state.page});
 
  }
}


 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

componentDidMount() {
	


if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  
		  
		  
		  }
	
	
 let itnumb=this.props.orid;
 localStorage.setItem("page",this.state.rowsPerPage);
 
   
  this.orgviewmore(); 
  // this.opviewmore(orid); 
  

}


 



orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  this.viewmoreorder();
    });
}


viewmoreorder(){
		/* let search = window.location.search;
let params = new URLSearchParams(search);

let orid = params.get('orid');*/



let search = window.location.href;
let orid = this.getParameterByName('orid',search);


	 axios.get(Serverurl+'ajaxorders.php?action=invoicereport&or_id='+orid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  invoiceviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			    or_taxamount:resp.data[0].or_taxamount,
			   discount:resp.data[0].or_discount,
			   orddiscount:resp.data[0].or_discountval,proddiscount:(parseFloat(resp.data[0].or_discount)-parseFloat(resp.data[0].or_discountval)),
			   roundoff:Number(resp.data[0].or_roundoff).toFixed(2),
			   sid:resp.data[0].or_sessionid,
			});
			
	   this.opviewmore(resp.data[0].or_sessionid); 
	 
     }
  
    });
}

opviewmore(sessionid){

	// axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_id='+opid)
	 
	 axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_sessionid='+sessionid)
  
	 
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  opviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	 
	this.refs.Printfeedetailscopy.printcall();
     }
   
    });
	
}

 

		
		
 render() {
	
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	 /* let search = window.location.search;
let params = new URLSearchParams(search);
let orid = params.get('orid');*/

let search = window.location.href;
let orid = this.getParameterByName('orid',search);

let subtaotal = 0;
let grandtotal = 0;




	  
  return ( 
  
  
   <Page layout="public">

<Section slot="menu">Sale / Invoice
</Section>

<Section slot="btn"><a href='#/Invoicereport'>Back</a>
</Section>

<Section slot="content">
  
  <React.Fragment>
 

<div ref={el => (this.componentRef = el)} >

<div className="invoiceview">


<div className="organization">

{this.state.orgviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
	
<ul>
<p>From :</p>
<li><b>{item.or_name}</b> </li>
<li>{item.or_address}</li>
<li>{item.or_city}</li>

<li>Email : {item.or_email}</li>

<li>Phone : {item.or_mobilenum}</li>
<li>PAN : {item.or_pannum}</li>
<li>GSTIN : {item.or_gstinnum}</li>






</ul>


	 
 </React.Fragment>
 )
  })}
  
  </div>





	 







{this.state.invoiceviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
		   
		   <div className="customerdetails">
		   
		   
<ul>
<p>To :</p>
<li><b>{item.or_name} </b> </li>
 
  
<li>Email  :  {item.or_email} </li>

<li>Phone No  :  {item.or_phone}  </li>   
{item.cu_panno!=="" &&(
<li>Pan No :  {item.cu_panno} </li>
)}
	{item.or_gstn!=="" &&(
<li>GSTN No : {item.or_gstn} </li>
)}
</ul>

		   </div> 
	<div className="orderdetails">
<ul>
<p>Details :</p>
<li> Invoice Type : <b>{item.ma_value}</b> </li>

<li> Invoice No : <b>{item.or_invoice}</b></li>

<li> Invoice Date : {item.or_createddate}</li>


<li> Sales Person :<b> {item.us_name}</b></li>

</ul>

			
	</div> 
 </React.Fragment>
 )
  })}
  

  
  
  
  
  		<div className="clear"></div>
  
 
  
  <customerviewPrintpreview  ref="customerviewPrintpreview"  updateDonenew={this.updateDonenewParent} />
  <div className="orderproduct">
  
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  
  
   <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              
            />
  
  
   <TableBody>
  
  {this.state.opviewmore.map((item,index) => { 

		subtaotal=Number(subtaotal)+Number(item.netamt)
       
        	   
		
           return ( <React.Fragment>
	
<TableRow key={index} >



<TableCell>{item.pr_code}</TableCell>
<TableCell>{item.op_title}</TableCell>
<TableCell>{item.op_srateinc}</TableCell>
<TableCell>{item.op_srateexc}</TableCell>

<TableCell>{item.op_tax_value}%</TableCell>

<TableCell>{item.op_quantity}</TableCell>
<TableCell align="right">Rs.{item.netamt}</TableCell>

	
</TableRow>	
	


 </React.Fragment>
 )
  })}	
	<TableRow  >
	

<TableCell colSpan="6" align="right">Sub Total</TableCell>
<TableCell align="right">Rs.{subtaotal.toFixed(2)}</TableCell>

	</TableRow>	
		<TableRow  >
	

<TableCell colSpan="6" align="right">Discount</TableCell>
<TableCell align="right">Rs.{Number(this.state.discount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colSpan="6" align="right">GST</TableCell>
<TableCell align="right">Rs.{Number(this.state.or_taxamount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colSpan="6" align="right">Round off</TableCell>
<TableCell align="right">Rs.{Number(this.state.roundoff).toFixed(2)}
</TableCell>

	</TableRow>
		<TableRow>
	

<TableCell colSpan="6" align="right">Grand Total</TableCell>
<TableCell align="right">Rs.{Math.floor(parseFloat(subtaotal)-parseFloat(this.state.discount) -parseFloat(this.state.roundoff) )}</TableCell>

	</TableRow> 
   </TableBody>
  
</Table>
  </TableContainer>   
  
  
  </div>
  

  
  <Printfeedetailscopy ref="Printfeedetailscopy"  id={this.state.invoiceno}   updateDonenew={this.updateDonenewParent} />

   </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  </div>
  
   
  
  
   
</React.Fragment>

		 </Section></Page>	 
 
 	) ;

 }
} 



 class InvoicePrintpreview extends Component {
	
	constructor(props) {
    super(props);
	
	this.state = {logredirect:false,pagename:'',customerviewmore:[],order:'desc',orderBy:'or_id',page: 0 ,
	totaldata:0,cuid:'',orid:'',or_taxamount:0,sessionid:'',discount:'',roundoff:'',subtaotal:'',amount:0,disamt:0,statename:'',
	grandtotal:'',displayprod:'',gstno:'',orddiscount:0,proddiscount:0,
	redirect:false,vredirect:false,orgviewmore:[],invoiceviewmore:[],opviewmore:[],cname:'',cinvoice:''
}
	
	
	 
	  
	}
	
	
	myChangeHandler = (event) => {
	const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
 
}

getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

componentDidMount() {
	


if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
	
	let itnumb=this.props.orid;
 
 localStorage.setItem("page",this.state.rowsPerPage);
 
   
  this.orgviewmore(); 
  // this.opviewmore(orid); 
  

}


 



orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  this.viewmoreorder();
    });
}


viewmoreorder(){
		/* let search = window.location.search;
let params = new URLSearchParams(search);
let orid = params.get('orid');*/


let search = window.location.href;
let orid = this.getParameterByName('orid',search);



	 axios.get(Serverurl+'ajaxorders.php?action=invoicereport&or_id='+orid)
  .then(resp => {  
		
    if(resp.data.length>0){
	
			this.setState({  
			  invoiceviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   cname:resp.data[0].or_name,
			   gstno:resp.data[0].or_gstn,
			   cinvoice:resp.data[0].or_invoice,
			     orddiscount:resp.data[0].or_discountval,
				 statename:resp.data[0].pl_name,
			    or_taxamount:resp.data[0].or_taxamount,
			 discount:resp.data[0].or_discount,
			   roundoff:Number(resp.data[0].or_roundoff).toFixed(2),
			   taxgst:Number((resp.data[0].or_taxamount)/2).toFixed(2),
			   createddate:resp.data[0].or_createddate,
			   invdate:resp.data[0].date,
			   sp:resp.data[0].us_name
			});
			
	   this.opviewmore(resp.data[0].or_sessionid); 
	 
     }
  
    });
}

opviewmore(sessionid){

	var stramount=0, strdiscount=0;
	// axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_id='+opid)
	 
	 axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_sessionid='+sessionid)
  
	 
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  opviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let previoustax="",strcurnttax="",productdiscount=0,orddisval=0;
			let strsubtaotal=0,taxablevalue=0,taxamount=0,totalamount=0,strcontent="",strincgrossval=0,strincgrossamt=0,strgrandtotal=0;
		var strRowsarr=resp.data;

		
	strRowsarr.map((row, i) => {
	 
	 strsubtaotal=parseFloat(strsubtaotal)+parseFloat(row.op_grossamount)
		
		strincgrossval= parseFloat(row.op_srateinc)*parseFloat(row.op_quantity)
		
		strincgrossamt = parseFloat(strincgrossamt)+parseFloat(strincgrossval)
       
        strgrandtotal=parseFloat(strgrandtotal)+parseFloat(row.op_grossamount)
		strcurnttax=row.op_tax_value;
			   
			 
			productdiscount=parseFloat(productdiscount)+ parseFloat(row.op_proddiscountval)
		
		let strtax=parseFloat(row.taxablemt);
				strtax=((parseFloat(strtax) * (100 / (100 + parseFloat(((row.op_tax_value))) ))))*row.op_quantity;
			 taxablevalue=Number(taxablevalue)+parseFloat(strtax);
		
			taxamount=Number(taxamount)+ parseFloat(taxablevalue);
				  
			totalamount=parseFloat(taxablevalue)+(parseFloat(taxamount)*2)
			
		let j=Number(i)+1
	    let k= resp.data.length;
	 
	 if(i===0)
	 {
	 strcontent+=' <div style="margin-top:60px;height: 265px"><table style="width:100%;font-size:12px" cellPadding="0" cellSpacing="0">'
	 
	 }
	 
	 
	 strcontent+=' <tr   key={index} >';

 strcontent+='<td style="width:30px;padding-left:45px" align="left" >'+row.op_quantity+'</td>';
strcontent+='<td   style="width:153px;padding-left:65px"  align="left" >'+row.op_title+'</td>';
strcontent+='<td ><p style="width:90px;padding-left:30px;text-align:left" >'+row.op_srateinc+' ('+row.op_tax_value+'%)</p></td>';
strcontent+='<td style="width:113px" align="right" >'+strincgrossval.toFixed(2)+'</td></tr>';
if(k===j && k<8)
	 {
		 
		strcontent+='</table></div>'; 
	 }
if(j===k)
{
	
	if(j !== 0 && k>8)  {
		strcontent+='</table></div>';
	}
	 strcontent+=' <tr  ><td colSpan="4" align="right" >';
strcontent+='<table style="width:100%;margin-top:0px;margin-left:20px;font-size:12px;fontFamily:Arial" >';
	
	
strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:132px" ></td>';
strcontent+='<td  style="width:96px;padding-left:22px;text-align:right"  >Sub Total</td>';
strcontent+='<td style="width:113px" align="center" >'+strincgrossamt.toFixed(2)+'</td></tr>';

	


orddisval=parseFloat(this.state.discount)-parseFloat(productdiscount)
if(productdiscount !== 0 ){

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';

strcontent+='<td   style="width:96px" ,"padding-left:22px;text-align:right"  >Product Discount</td >';
strcontent+='<td style="width:113px" align="center" >'+parseFloat(productdiscount).toFixed(2)+'</td ></tr>';

}

if( this.state.orddiscount!=="0" ){

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';

strcontent+='<td   style="width:96px" ,"padding-left:22px;text-align:right"  >Special Discount</td >';
strcontent+='<td style="width:113px" align="center" >'+parseFloat(orddisval).toFixed(2)+'</td ></tr>';

}
strcontent+='<tr><td style="width:46px"></td><td  style="width:153px;margin-left:20px" ></td>';
strcontent+='<td  style="width:96px;padding-left:22px;text-align:right"  >'+parseFloat(taxablevalue).toFixed(2)+'</td ><td style="width:113px" align="center"></td ></tr>';
if((this.state.statename).toUpperCase()==="KARNATAKA")
{
strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';
strcontent+='<td   style="width:96px;padding-left:22px;text-align:right"  >'+parseFloat(this.state.taxgst).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px" align="center"></td ></tr>	';
	

strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';
strcontent+='<td   style="width:96px;padding-left:22px;text-align:right"  >'+parseFloat(this.state.taxgst).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px" align="center" ></td ></tr>';
}else{
	strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td>';
strcontent+='<td   style="width:96px;padding-left:22px;text-align:right"  >'+(parseFloat(this.state.taxgst)+parseFloat(this.state.taxgst)).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px" align="center" ></td ></tr>';
}
	
strcontent+='<tr><td style="width:46px"></td><td   style="width:153px;margin-left:20px" ></td><td  style="width:96px"  align="right">'+Math.floor(parseFloat(strincgrossamt)-parseFloat(this.state.discount)-parseFloat(this.state.roundoff)).toFixed(2)+'</td >';
strcontent+='<td  style="width:113px"  align="center" >'+Math.floor(parseFloat(strincgrossamt)-parseFloat(this.state.discount)-parseFloat(this.state.roundoff)).toFixed(2)+' </td>';
strcontent+=' </tr></table ></td></tr>';
	
}
	if(j !== 0 && k>8)  {
	
	
	if(j % 8 == 0 ){
strcontent+='</table></div><div className="page-break" style="margin-top:330px;height:265px" >';
strcontent+='<table style="width:100%;font-size:12px" cellPadding="0" cellSpacing="0">';

}

	


	}
	 });
	
	this.setState({ displayprod: strcontent});

	 
	
     }
   document.getElementById('print-button').click();
    });
	
}



		
		
 render() {
	let strmargin=0;
let displayprod=this.state.displayprod;
	 /*let search = window.location.search;
let params = new URLSearchParams(search);*/
let j=0,k=0;
let search = window.location.href;
let orid = this.getParameterByName('orid',search);

let subtaotal = 0;
let grandtotal = 0;
let dis = 0;
let incgrossamt = 0;
let incgrossval=0;


	  
  return ( 
  
  
   
 

<div>


<div className="printfont" style={{"font-size":"12px"}}  >

<table style={{"width": "472px","font-size ":"12px","fontFamily":"Arial"}}  >

<tr>
<td>

<div style={{"margin-top": "190px"}}>
<table style={{"width": "100%"}}>

<tr>

<td style={{"width": "220px","padding-left":"60px"}} ><p style={{"font-size ":"12px"}}>{this.state.cinvoice}</p><p>{this.state.cname}
	{this.state.gstno!=="" && (<React.Fragment>
<br/>GST:{this.state.gstno}
</React.Fragment>)}</p></td>

<td style={{"width": "200px" }}><p style={{"text-align": "right"}}>{this.state.invdate}</p><p style={{"text-align": "left","font-size ":"12px"}}>{this.state.sp}</p>
</td>
</tr>
 


</table>
</div>
</td>
 </tr>


<tr>
<td> 
  
  
  
 
    <div dangerouslySetInnerHTML={ { __html: displayprod}} />
  
 
  
 
  
  </td>
  </tr>
  


  </table>


 
  

  
 
  
  
   </div> 
  </div>  
  
  
  
 
  
  
   
 
 
 	) ;

 }
} 
			
class Printfeedetailscopy extends Component {
	

	 
	  constructor(props) {
        super();
        this.state =  {view:false}
			
			
		
	  }
   printcall = (e) => {
	
document.getElementById('print-button').click();	
}

    render() {
		
	
      return (
	
 <React.Fragment>
        <div className="printdetails">
		 <div className="printdetails1">
		 
		<InvoicePrintpreview ref="InvoicePrintpreview" ref={el => (this.componentRef = el)} /> 
         
	   </div>
	   
         
  <p>  <ReactToPrint 
            trigger={() => <p id="print-button" className="Primarybuttons">Print </p>}
			content={() =>  this.componentRef} />
            
         
		 
		 
		 
		 
		 
		 
		 
		 
		 
		 </p>
        </div>
		 </React.Fragment>
      );
    }
  }
  
  

  	
export default customerviewmore;		
	
	
	 
  





		