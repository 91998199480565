import React ,{Component,useRef, useEffect} from 'react';

import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import Serverurl from './Apiurl';
import Uploadsurl from './Apiurl';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';


import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import SchoolIcon from '@material-ui/icons/School';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ReactToPrint from 'react-to-print';
import { Page, Section } from 'react-page-layout';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}




const headCells = [




{ id: 'pr_code', numeric: false,  label: <b>Product Code</b> },
{ id: 'pp_title', numeric: false,  label: <b>Product Name</b> },
{ id: 'pp_prateinc', numeric: false,  label: <b>P.Rate(Inc)</b> },
{ id: 'pp_prateexc', numeric: false,  label: <b>P.Rate(Exc)</b> },


{ id: 'pp_tax_value', numeric: false,  label: <b>Tax</b> },
{ id: 'pp_quantity', numeric: false,  label: <b>Qty</b> },
{ id: 'pp_grossamount', numeric: false,  label: <b>Amount</b> },

]




function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  row:{border:0},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});



class purchaseviewmore extends Component {
	
	constructor(props) {
    super(props);
	
	this.state = {logredirect:false,pagename:'',customerviewmore:[],order:'desc',orderBy:'or_id',page: 0 ,
	prevpage:0,totaldata:0,rowsPerPage:20,cuid:'',orid:'',or_taxamount:0,sessionid:'',discount:'',roundoff:'',subtaotal:'',
	grandtotal:'',
	redirect:false,vredirect:false,orgviewmore:[],invoiceviewmore:[],opviewmore:[]
}
	
	this.updateDonenewParent = this.updateDonenewParent;
	 
	  
	}
	
	 updateDonenewParent = (text) => {this.componentDidMount();  }
	myChangeHandler = (event) => {
	const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
 
}

componentDidUpdate() {
		
  if(this.state.prevpage != this.state.page) {
    this.setState({   prevpage: this.state.page});
 
  }
}


 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

componentDidMount() {
	


if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
	
	
 let itnumb=this.props.poid;
 localStorage.setItem("page",this.state.rowsPerPage);
 
   
  this.orgviewmore(); 
  // this.opviewmore(poid); 
  

}


 



orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  this.viewmoreorder();
    });
}


viewmoreorder(){
		/* let search = window.location.search;
let params = new URLSearchParams(search);

let orid = params.get('orid');*/



let search = window.location.href;
let poid = this.getParameterByName('poid',search);


	 axios.get(Serverurl+'ajaxpurchasebillorders.php?action=purchasebillordersreport&po_id='+poid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  invoiceviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			    or_taxamount:resp.data[0].po_taxamount,
			   discount:resp.data[0].po_discount,
			   roundoff:Number(resp.data[0].po_roundoff).toFixed(2),
			   sid:resp.data[0].po_sessionid,
			});
			
	   this.opviewmore(resp.data[0].po_sessionid); 
	 
     }
  
    });
}

opviewmore(sessionid){

	// axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_id='+opid)
	 
	 axios.get(Serverurl+'ajaxpurchasebillorderproducts.php?action=ppviewmore&pp_sessionid='+sessionid)
  
	 
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  opviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	 
	document.getElementById('print-button').click();	
     }
   
    });
	
}

 

		
		
 render() {
	

	 /* let search = window.location.search;
let params = new URLSearchParams(search);
let orid = params.get('orid');*/

let search = window.location.href;
let poid = this.getParameterByName('poid',search);

let subtaotal = 0;
let grandtotal = 0;




	  
  return ( 
  
  
   <Page layout="public">

<Section slot="menu"> / Purchase / Purchase Bill
</Section>

<Section slot="btn"><a href='#/Purchasereport'>Back</a>
</Section>

<Section slot="content">
  
  <React.Fragment>
 

<div ref={el => (this.componentRef = el)} >

<div className="purview">
<h2 style={{"text-align": "center"}}>Purchase Bill</h2>
<table width="100%">
<tr>
<td align="left" style={{"width": "30%"}}>
<div className="quotesview">

{this.state.orgviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
	
<ul>
<p>For :</p>
<li><b>{item.or_name}</b> </li>
<li>{item.or_address}</li>
<li>{item.or_city}</li>

<li>Email : {item.or_email}</li>

<li>Phone : {item.or_mobilenum}</li>
<li>PAN : {item.or_pannum}</li>
<li>GSTIN : {item.or_gstinnum}</li>






</ul>


	 
 </React.Fragment>
 )
  })}
  
  </div>



</td>

	 







{this.state.invoiceviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
		   <td align="left" style={{"width": "30%"}}>
		   <div className="quotesview">
		   
		   
<ul>
<p>From :</p>

{item.po_name !==""  && (<React.Fragment>
<li><b>{item.po_name} </b> </li>
</React.Fragment>)}
 
{item.po_email !==""  && (<React.Fragment> 
<li>Email  :  {item.po_email} </li>
</React.Fragment>)}

{item.or_phone !==""  && (<React.Fragment>
<li>Phone No  :  {item.or_phone}  </li>   
</React.Fragment>)}

{item.cu_panno !==""  && (<React.Fragment>
<li>Pan No :  {item.cu_panno} </li>
</React.Fragment>)}

{item.po_gstn !==""  && (<React.Fragment>
<li>GSTN No : {item.po_gstn} </li>
</React.Fragment>)}
</ul>

		   </div> </td><td align="left" style={{"width": "30%"}}>
	<div className="quotesview">
<ul>
<p>Details :</p>


<li> Bill No : <b>{item.po_billno}</b></li>

<li> Bill Date : {item.po_createddate}</li>

<li> Ref No : {item.po_referenceno}</li>

<li> Ref Date : {item.po_referencedate} </li>

<li> Due Date : {item.po_duedate} </li>

</ul>

			
	</div>  </td>
 </React.Fragment>
 )
  })}
  
</tr></table>
  
  
  
  
  		<div className="clear"></div>
  
 
  
  <customerviewPrintpreview  ref="customerviewPrintpreview"  updateDonenew={this.updateDonenewParent}/>
  <div className="purprd">
  
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  
  
   <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              
            />
  
  
   <TableBody>
  
  {this.state.opviewmore.map((item,index) => { 

		subtaotal=Number(subtaotal)+Number(item.pp_grossamount)
       
        grandtotal=Number(grandtotal)+Number(item.pp_grossamount)	   
		
           return ( <React.Fragment>
	
<TableRow key={index} >



<TableCell>{item.pr_code}</TableCell>
<TableCell>{item.pp_title}</TableCell>
<TableCell>{item.pp_prateinc}</TableCell>
<TableCell>{item.pp_prateexc}</TableCell>



<TableCell>{item.pp_tax_value}%</TableCell>

<TableCell>{item.pp_quantity}</TableCell>
<TableCell align="right">Rs.{item.pp_grossamount}</TableCell>

	
</TableRow>	
	


 </React.Fragment>
 )
  })}	
	<TableRow  >
	

<TableCell colSpan="6" align="right">Sub Total</TableCell>
<TableCell align="right">Rs.{subtaotal.toFixed(2)}</TableCell>

	</TableRow>	
		<TableRow  >
	

<TableCell colSpan="6" align="right">Discount</TableCell>
<TableCell align="right">Rs.{Number(this.state.discount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colSpan="6" align="right">GST</TableCell>
<TableCell align="right">Rs.{Number(this.state.or_taxamount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colSpan="6" align="right">Round off</TableCell>
<TableCell align="right">Rs.{Number(this.state.roundoff).toFixed(2)}</TableCell>

	</TableRow>
		<TableRow  >
	

<TableCell colSpan="6" align="right">Grand Total</TableCell>
<TableCell align="right">Rs.{Math.ceil(parseFloat(subtaotal)+parseFloat(this.state.or_taxamount)-parseFloat(this.state.discount)-parseFloat(this.state.roundoff) )}</TableCell>





	</TableRow> 
   </TableBody>
  
</Table>
  </TableContainer>   
  
  
  </div>
  


  
  
  
   </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  </div>
  
   
    <p>  <ReactToPrint 
            trigger={() => <p id="print-button" className="Primarybuttons">Print </p>}
			content={() =>  this.componentRef}
            
         /> 
		 </p>
  
   
</React.Fragment>

		 </Section></Page>	 
 
 	) ;

 }
} 



 class InvoicePrintpreview extends Component {
	
	constructor(props) {
    super(props);
	
	this.state = {logredirect:false,pagename:'',customerviewmore:[],order:'desc',orderBy:'or_id',page: 0 ,
	totaldata:0,cuid:'',orid:'',or_taxamount:0,sessionid:'',discount:'0',roundoff:'',subtaotal:'',
	grandtotal:'',
	redirect:false,vredirect:false,orgviewmore:[],invoiceviewmore:[],opviewmore:[],cname:'',cinvoice:''
}
	
	
	 
	  
	}
	
	
	myChangeHandler = (event) => {
	const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
 
}

getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

componentDidMount() {
	


if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
	
	let itnumb=this.props.poid;
 
 localStorage.setItem("page",this.state.rowsPerPage);
 
   
  this.orgviewmore(); 
  // this.opviewmore(orid); 
  

}


 



orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  this.viewmoreorder();
    });
}


viewmoreorder(){
		/* let search = window.location.search;
let params = new URLSearchParams(search);
let orid = params.get('orid');*/


let search = window.location.href;
let poid = this.getParameterByName('poid',search);



	 axios.get(Serverurl+'ajaxpurchasebillorders.php?action=purchasebillordersreport&po_id='+poid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  invoiceviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   cname:resp.data[0].po_name,
			   cinvoice:resp.data[0].po_billno,
			    or_taxamount:resp.data[0].po_taxamount,
			 discount:resp.data[0].po_discount,
			   roundoff:Number(resp.data[0].po_roundoff).toFixed(2),
			   taxgst:Number((resp.data[0].po_taxamount)/2).toFixed(2),
			   createddate:resp.data[0].po_createddate,
			  
			});
			
	   this.opviewmore(resp.data[0].po_sessionid); 
	 
     }
  
    });
}

opviewmore(sessionid){

	// axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_id='+opid)
	 
	 axios.get(Serverurl+'ajaxpurchasebillorderproducts.php?action=ppviewmore&pp_sessionid='+sessionid)
  
	 
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  opviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
   document.getElementById('print-button').click();
    });
	
}



		
		
 render() {
	

	 /*let search = window.location.search;
let params = new URLSearchParams(search);*/

let search = window.location.href;
let poid = this.getParameterByName('poid',search);

let subtaotal = 0;
let grandtotal = 0;
let dis = 0;




	  
  return ( 
  
  
   
 

<div ref={el => (this.componentRef = el)} >


<div className="printfont" style={{"fontSize":"10px"}}>

<table style={{"width": "472px","fontSize ":"10px","fontFamily":"Arial"}}  >

<tr>
<td>

<div style={{"margin-top": "185px"}}>
<table style={{"width": "100%"}}>

<tr>

<td style={{"width": "220px","padding-left":"60px"}} ><p>{this.state.cinvoice}</p><p>{this.state.cname}</p></td>

<td  style={{"width": "200px" }}><p style={{"text-align": "right"}}>{this.state.createddate}</p><p style={{"text-align": "left"}}></p></td>
</tr>
 


</table>
</div>
</td>
 </tr>


<tr>
<td> 
  
  
  
 
  
  
  
  <div style={{"margin-top": "57px","height": "230px"}}>
  
   <table style={{"width": "100%"}} cellPadding="0" cellSpacing="0">
   <div style={{}}>
  
  {this.state.opviewmore.map((item,index) => { 

		subtaotal=Number(subtaotal)+(parseFloat(item.pp_prateinc)*Number(item.pp_quantity))
       
        grandtotal=Number(grandtotal)+Number(item.taxable)

let rate=parseFloat(item.pp_prateinc)*Number(item.pp_quantity)
		
		
           return ( <React.Fragment>
	
<tr   key={index} >

<td style={{"width": "46px"}} align="right" >{item.pp_quantity}</td>
<td   style={{"width": "153px","padding-left":"60px"}}  align="right"  >{item.pp_title}</td>
<td > <p style={{"width": "90px","padding-left":"30px","text-align":"right"}} >{item.pp_srateinc} ({item.pp_tax_value}%)</p></td>
<td style={{"width": "113px"}} align="right"  >Rs.{rate}</td>


	
</tr>	
	


 </React.Fragment>
 )
  })}
  
   </div> 
   </table>
  </div> 
  
 
  
  </td>
  </tr>
  
  <tr>
  <td>

  

	<table style={{"width": "100%","margin-top": "0px","margin-left": "10px"}}>
	
	
<tr>
<td style={{"width": "46px"}}></td>
<td   style={{"width": "153px","margin-left":"132px"}}></td>
<td  style={{"width": "96px","padding-left":"22px","text-align":"right"}}  >Sub Total</td>
<td style={{"width": "113px"}} align="center" >Rs.{subtaotal.toFixed(2)}</td></tr>

	
<tr>	
<td style={{"width": "46px"}}></td>
<td   style={{"width": "153px","margin-left":"20px"}} ></td>
{this.state.discount !== "0.00" && ( <React.Fragment>


<td   style={{"width": "96px" ,"padding-left":"22px","text-align":"right"}}   >Discount</td >
<td style={{"width": "113px"}} align="center">Rs.{parseFloat(this.state.discount).toFixed(2)}</td >
</React.Fragment>)}

</tr>
	

<tr>
<td style={{"width": "46px"}}></td>
<td   style={{"width": "153px","margin-left":"20px"}}  ></td>
<td style={{"width": "96px" ,"padding-left":"22px","text-align":"right"}} >Rs.{parseFloat(grandtotal).toFixed(2)}</td >
<td style={{"width": "113px"}} align="center"></td >
</tr>
	
	
<tr>
<td style={{"width": "46px"}}></td>
<td   style={{"width": "153px","margin-left":"20px"}}></td>
<td style={{"width": "96px" ,"padding-left":"22px","text-align":"right"}}  >Rs.{parseFloat(this.state.taxgst).toFixed(2)}</td >
<td  style={{"width": "113px"}} align="center"></td >
</tr>	
	

<tr>
<td style={{"width": "46px"}}></td>
<td  style={{"width": "153px","margin-left":"20px"}} ></td>
<td style={{"width": "96px" ,"padding-left":"22px","text-align":"right"}} >Rs.{parseFloat(this.state.taxgst).toFixed(2)}</td >
<td  style={{"width": "113px"}} align="center"></td >
</tr>
	
	
<tr>
<td style={{"width": "46px"}}></td>
<td   style={{"width": "153px","margin-left":"20px"}} ></td>
<td  style={{"width": "96px"}} ></td >
<td  style={{"width": "113px"}}  align="center">Rs.{Math.ceil(parseFloat(subtaotal)-parseFloat(this.state.roundoff) )}</td >

	
  
  
  </tr>
 	</table >
	
	

 
 
  
</td>
</tr>

  </table>

 </div>
 </div> 
  
  
  
 
  
  
   
 
 
 	) ;

 }
} 
			
class Printfeedetailscopy extends Component {
	

	 
	  constructor(props) {
        super();
        this.state =  {view:false}
			
			
		
	  }
   printcall = (e) => {
	

}

    render() {
		
	
      return (
	
 <React.Fragment>
        <div className="printdetails">
		 <div className="printdetails1">
      
	   </div>
          

        </div>
		 </React.Fragment>
      );
    }
  }

  	
export default purchaseviewmore;		
	
	
	 
  





		