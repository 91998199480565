import React ,{Component} from 'react';

import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Serverurl from './Apiurl';
import Uploadsurl from './Apiurl';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';


import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import SchoolIcon from '@material-ui/icons/School';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

import { Page, Section } from 'react-page-layout';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [



{ id: 'rb_billno', numeric: false,  label: <b>Invoice No.</b> },
{ id: 'rb_billdate', numeric: false,  label: <b>Invoice Date</b> },
{ id: 'totalamount', numeric: false,  label: <b>Total Amount</b> },
{ id: 'rb_paidamount', numeric: false,  label: <b>Paid Amount</b> },
{ id: 'dueamount', numeric: false,  label: <b>Due Amount</b> },

]




function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  row:{border:0},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});

export default class Receiptmasterviewmore extends Component {
	
	constructor(props) {
    super(props);
	
	this.state = {logredirect:false,pagename:'',customerviewmore:[],order:'desc',orderBy:'or_id',page: 0 ,
	prevpage:0,totaldata:0,rowsPerPage:20,cuid:'',orid:'',or_taxamount:0,sessionid:'',discount:'',roundoff:'',subtaotal:'',
	grandtotal:'',
	redirect:false,vredirect:false,orgviewmore:[],invoiceviewmore:[],opviewmore:[],prdphoto:[],primg:'',
}
	
	
	 
	  
	}
	
	
	myChangeHandler = (event) => {
	const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
 
}

componentDidUpdate() {
		
  if(this.state.prevpage != this.state.page) {
    this.setState({   prevpage: this.state.page});
 
  }
}



 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}




componentDidMount() {
	

if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }

	
	
 
 localStorage.setItem("page",this.state.rowsPerPage);
 
   
  this.orgviewmore(); 
  // this.opviewmore(orid); 
  
}


 



orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  this.viewmoreorder();
    });
}


viewmoreorder(){
		/* let search = window.location.search;
let params = new URLSearchParams(search);
let orid = params.get('orid');*/


	
let search = window.location.href;
let rmid = this.getParameterByName('rmid',search);

	 axios.get(Serverurl+'ajaxreceiptmaster.php?action=receiprmasterreport&rm_id='+rmid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  invoiceviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   // or_taxamount:resp.data[0].pm_taxamount,
			  // discount:resp.data[0].pm_discount,
			  // roundoff:Number(resp.data[0].pm_roundoff).toFixed(2),
			});
			
	   this.opviewmore(resp.data[0].rm_sessionid); 
	 
     }
  
    });
}

opviewmore(sessionid){



	// axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_id='+opid)
	 
	 axios.get(Serverurl+'ajaxreceiptbills.php?action=rmviewmore&rb_sessionid='+sessionid)
  
	 
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  opviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
   // this.productphoto();
    });
	
	
}



	
		
 render() {
	

	 let search = window.location.search;
let params = new URLSearchParams(search);

let subtaotal = 0;
let grandtotal = 0;
	  
  return ( 
  
  
   <Page layout="public">

<Section slot="menu"> / Sales / Receipts
</Section>

<Section slot="btn"><a href='#/Receiptmasterreport'>Back</a>
</Section>

<Section slot="content">
  
  <React.Fragment>
 



<div className="invoiceview">


<div className="organization">

{this.state.orgviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
	
<ul>
<p>From :</p>
<li><b>{item.or_name}</b> </li>
<li>{item.or_address}</li>
<li>{item.or_city}</li>

<li>Email : {item.or_email}</li>

<li>Phone : {item.or_mobilenum}</li>
<li>PAN : {item.or_pannum}</li>
<li>GSTIN : {item.or_gstinnum}</li>






</ul>


	 
 </React.Fragment>
 )
  })}
  
  </div>





	 







{this.state.invoiceviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
		   
		   <div className="customerdetails">
		   
		   
<ul>
<p>To :</p>


{item.cu_contactname !=="" && ( <React.Fragment>
<li><b>{item.cu_contactname} </b> </li>
</React.Fragment>)}
 
 
 {item.cu_billingassress !=="" && ( <React.Fragment>
  <li>Address : {item.cu_billingassress} </li>
  </React.Fragment>)}
  
  {item.cu_email !=="" && ( <React.Fragment>
<li>Email  :  {item.cu_email} </li>
</React.Fragment>)}

{item.cu_mobileno !=="" && ( <React.Fragment>
<li>Phone No  :  {item.cu_mobileno}  </li>   
</React.Fragment>)}


{item.cu_panno !=="" && ( <React.Fragment>
<li>Pan No :  {item.cu_panno} </li>
</React.Fragment>)}

{item.cu_gstinno !=="" && ( <React.Fragment>
<li>GSTN No : {item.cu_gstinno} </li>
</React.Fragment>)}



</ul>

		   </div> 
	<div className="orderdetails">
<ul>
<p>Details :</p>
<li> Receipt No : <b>{item.rm_receiptno}</b></li>

<li> Receipt Date : {item.rm_receiptdate}</li>



<li> Payment Mode : {item.pt_title} </li>

<li> Ref No. : {item.rm_referenceno} </li>

<li> Ref Date : {item.rm_referencedate} </li>

</ul>

			
	</div> 
 </React.Fragment>
 )
  })}
  

  
  
  
  
  		<div className="clear"></div>
  
  </div>
  
  
  <div className="orderproduct">
  
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  
  
   <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              
            />
  
  
   <TableBody>
  
  {this.state.opviewmore.map((item,index) => { 

	 let balamt=(Number(item.totalamount)-Number(item.totalpaid)).toFixed(2)   
		
           return ( <React.Fragment>
	
<TableRow key={index} >



<TableCell>{item.rb_billno}</TableCell>
<TableCell>{item.invoicedate}</TableCell>
<TableCell>Rs. {item.totalamount}</TableCell>
<TableCell>Rs. {item.totalpaid}</TableCell>
<TableCell>Rs. 	{balamt}</TableCell>

	
</TableRow>	
	
	


 </React.Fragment>
 )
  })}

   </TableBody>
  
</Table>
  </TableContainer>   
  
  
  <br/>
  
 
  </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
   
</React.Fragment>

		 </Section></Page>	 
 
 	) ;

 }
} 
			
			
			
	
	
	 
  





		