import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import PersonIcon from '@material-ui/icons/Person';
import './styles.css';  
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [




{ id: 'cu_contacttype', numeric: false,  label: 'Contact Type' },
{ id: 'cu_contactname', numeric: false,  label: 'Contact Name' },
{ id: 'cu_email', numeric: false,  label: 'Email' },
{ id: 'cu_mobileno', numeric: false,  label: 'Mobile No' },

{ id: '', numeric: false,  label: '' },

]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};





const useStyles = makeStyles(theme =>({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
 
form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
})
)

export default class admincustomer extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'cu_id',name:'',cu_id:0,contacttype:'',contactname:'',email:'',mobileno:'',phonenum:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',
	cusemail:'',btndisabled:false,
submitbtn:"Submit",
	 
	 
	  
	 
        
      }
	 };
	 
	 
  myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  


componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}


componentDidMount() {
	
if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "4" || localStorage.getItem("Rrole") === "2")
{
	this.setState({
            logredirect: false,
			pagename:''
          })
		  
}
	else{
	
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
}
	  

	}  

 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 


 
	}
	


apicall(page,rowsPerPage){
		 let search = window.location.search;
let params = new URLSearchParams(search);
let cuid = params.get('cu');
let type="";
if(cuid==="1")
{ this.setState({contacttype:"1"});
	type=1;
}
else if(cuid==="2")
{ this.setState({contacttype:"2"});
	type=2;
}
else
{
	type=this.state.contacttype;
	
}
this.setState({btndisabled:true,submitbtn:"Processing..."})
axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_email='+this.state.cusemail+'&cu_contacttype='+type+'&StartIndex='+page+'&PageSize='+rowsPerPage)

 
   .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[];
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}
			
	
	  this.setState({rowsPaging:copy,btndisabled:false,submitbtn:"Search"});
	  
	 
     }
	 else{
		 this.setState({  
			  StoreData: [],  
			   totaldata:0,btndisabled:false,submitbtn:"Search"
			});
		 
	 }
  
    });
}


handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
   
    
   // let name = this.state.name;
	let cusemail = this.state.cusemail;
	let contacttype = this.state.contacttype;
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
} 



handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  }; 


handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });
   

   };


  

defaultuser(cuid){  
  
		let r = window.confirm("Do you really want to Change Default User?");
		 if(r === true){ //sign Out logic
				 var formData = new FormData(); 
					 formData.append('action','update')
					
					 formData.append('ao_id',3)
					 formData.append('ao_type',cuid)
				
					  axios.post(Serverurl+"ajaxadminoption.php", formData, {
						}).then(res => {
					
							 this.setState({ prevpage: -1,});
   
 
        setTimeout(() =>  this.componentDidUpdate() ,500); 
				  
					
					
							});
		 }
 }
userdelete(cuid){  
  
		let r = window.confirm("Do you really want to Delete?");
		 if(r === true){ //sign Out logic
				 var formData = new FormData(); 
					 formData.append('action','delete')
					
					 formData.append('cu_id',cuid)
					 formData.append('userid',localStorage.getItem("Ruserid"))
				
					  axios.post(Serverurl+"ajaxcustomer.php", formData, {
						}).then(res => {
					
							 this.setState({ prevpage: -1,});
   
 
        setTimeout(() =>  this.componentDidUpdate() ,500); 
				  
					
					
							});
		 }
 }



/*userupdate(cuid){
	
	 axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		this.setState({  
		contactcode:resp.data[0].cu_contactcode,
		contacttype:resp.data[0].contacttype,
		contactname:resp.data[0].cu_contactname,
		email:resp.data[0].cu_email,
		mobileno:resp.data[0].cu_mobileno,
		phonenum:resp.data[0].cu_phonenum,
		panno:resp.data[0].cu_panno,
		
		
		gstinno:resp.data[0].cu_gstinno,
		billingassress:resp.data[0].cu_billingassress,
		shippingaddress:resp.data[0].cu_shippingaddress,
		
		
		cu_id:resp.data[0].cu_id
		});
	}
	
	
  })

 }*/
 
 userupdate(id) {
        localStorage.setItem("cuid", id);
       
		 this.setState({ editredirect: true})
		
 }
 
 myRoleChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 this.setState({[nam]: val});
  


}
 
 
 
	

 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
 
 
userinsertorupdate(id) {
        localStorage.setItem("cuid", id);
       
		 this.setState({ editredirect: true})
		
 }
 
 
 
 
 
 
 
 
 render() {
	
	
if (this.state.editredirect === true) {
      return <Redirect to='#/addcustomer' />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
  
  
  	 
    
  return (
  
    <Page layout="public">

<Section slot="menu"> / <a href='#/configuration' tabIndex="-1">Configuration</a> / Contacts
</Section>

<Section slot="btn"><a href='#/addcustomer' tabIndex="-1">New Contacts</a>
</Section>

<Section slot="content">
  
  
    <React.Fragment>

 <div className="main">
 <div className="headername">
		 <p><b>Contacts</b></p>
		 </div>
    <div className="customersearch">
	
	
	<ul>
	 <li><p><TextField
           
            margin="normal"
            
            fullWidth
			value={this.state.cusemail}
            id="cusemail"
            variant="outlined"
            name="cusemail" onChange={this.myChangeHandler}
            autoComplete="cusemail"
			placeholder=" Name / Mobile / E-Mail"
            autoFocus
			
          />
		  </p></li>
		  
		  
		 
	<li>	<p> <FormControl  className={useStyles.formControl} >
   
        <Select
          native
		  variant="outlined"
            margin="normal"
         value={this.state.contacttype}
          onChange={this.myRoleChangeHandler}
        name='contacttype'
         id='contacttype'>
		 
          <option value={0}>Type</option>
		  <option value={1}>Customer</option>
		  <option value={2}>Vendor</option>
		 
		  
          
        </Select>
      </FormControl>
		  </p></li>
  
		
		
		<li><p className="btncenter"><Button
            type="submit"
            fullWidth
            variant="contained" 
			disabled={this.state.btndisabled}
            color="primary"
            id="searchform" 
            className={useStyles.submit}
			onClick={this.handleSubmit}
			
          >
            {this.state.submitbtn}
          </Button>	</p></li>
	
	</ul>  
	
	
   
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.StoreData.length}
            />
                      <TableBody>

                     {stableSort(this.state.StoreData, getSorting(this.state.order, this.state.orderBy)).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.cu_contacttype}</TableCell>
<TableCell>{item.cu_contactname}</TableCell>
<TableCell>{item.cu_email}</TableCell>
<TableCell>{item.cu_mobileno}</TableCell>


<TableCell >

<div className="primary">
<a className="link" href={"#/addcustomer?cuid="+item.cu_id+""}>
<EditIcon  />  
</a>

</div>
{item.orcount==="0" && (
<div className="secondary">
<DeleteIcon onClick={()=>{this.userdelete(item.cu_id)}}/>
</div>
)}
{item.deftcust==="0" && (
<div className="primary">

<PersonAddIcon onClick={()=>{this.defaultuser(item.cu_id)}}/>
</div>)}

{item.deftcust!=="0" && (
<div className="otherbtns">

<PersonIcon />
</div>)}
</TableCell>

 </TableRow>


				</React.Fragment>	
				);
					})}
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="6">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
 rowsPerPageOptions={[50, 100, 150,200]} 
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
   
 

   </div></div>
 </React.Fragment>
		

 </Section></Page>	 






  );
  }	
   

}

