import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';
import CheckIcon from '@material-ui/icons/Check';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight 
    from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft 
    from "@material-ui/icons/KeyboardArrowLeft";
import MobileStepper 
    from "@material-ui/core/MobileStepper";
import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableFooter from '@material-ui/core/TableFooter';
let strCus=[{title:'',year:0}];
const strCusdeflt=[];
const headCells = [

]



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});

export default class Customerinvoice extends Component {
    constructor(props) {
        super();
        this.state = {grandweight:'',
		billaddress1:'',billaddress2:'',billstate:'',billstateid:'',billcity:'',billcountry:'',billcountryid:'',shipaddress1:'',shipaddress2:'',erroropen: false,	errormessage:'',
	    shipstate:'',shipstateid:'',shipcity:'',shipcountry:'',shipcountryid:'',cu_contactname:'',boxno:'',INDEX:0,prevActiveStep:0,
		billpincode:'',or_invoice:'',txtaddress1:'',ShipButtonorder:'Save',btnshipdisabled:false,discount:0,or_taxamount:0,roundoff:0,
			txtaddress2:'',txtpincode:'',txtstateid:'',txtcountryid:'',txtcity:'',	
	  logredirect: false, pagename: '', id: '', page: 0, prevpage: 0,totaldata: 0,
            rowsPerPage: 30,rowsPaging: [],billingaddress: '',search: '',type:0,phone:'',email:'',currencycode:'',invdate:'',pdflink:'',alerttyep:"error",
            order: 'desc', shippincode:'',ProductData:[],textboxValues: [],numTextboxes:0,or_id:'',
			numprevboxes:0,boxarraydata:[],
        };
             this.updateDonenewParent = this.updateDonenewParent.bind(this);

    }
	  updateDonenewParent = (text) => {
        
		this.updateInvoiceData();
		this.apicall(this.state.page, this.state.rowsPerPage);
		this.ProductApi(this.state.page,this.state.rowsPerPage)
    }
	updateInvoiceData = (data) => {
		if(data.type===1)
		{
        this.setState({
           billaddress1:data.txtaddress1,
           billaddress2:data.txtaddress2,
           billcountry:data.billcountry,
           billcity:data.txtcity,
           billstate:data.billstate,
           billpincode:data.txtpincode,
          billstateid:data.txtstateid,
			billcountryid:data.txtcountryid
        });
		}
		else{
			 this.setState({
           shipaddress1:data.txtaddress1,
           shipaddress2:data.txtaddress2,
           shipcountry:data.billcountry,
           shipcity:data.txtcity,
           shipstate:data.billstate,
           shippincode:data.txtpincode,
          shipstateid:data.txtstateid,
			shipcountryid:data.txtcountryid
			
        });
		}
    }
	  myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }
	 handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            orderBy: property,
            order: isAsc ? 'desc' : 'asc'
        });
    };

    handleChangePage = (event, newPage) => {
        let pageval = localStorage.getItem("page");
        this.setState({
            page: newPage,
            rowsPerPage: parseInt(pageval)
        });
        setTimeout(() => this.componentDidUpdate(), 500);
    };

    handleChangeRowsPerPage = event => {
        let pageval = event.target.value;
        localStorage.setItem("page", pageval);
        setTimeout(() => this.componentDidUpdate(), 500);
        this.setState({
            page: 0,
            rowsPerPage: parseInt(pageval),
            prevpage: -1
        });
    }

	

	getOrderAddresscall() {
       // this.setState({ btndisabled: true, submitbtn: "Processing..." });
        axios.get(Serverurl + 'ajaxorders.php?action=getaddress&or_invoice=' + this.state.or_invoice )
            .then(resp => {
                if (resp.data.length > 0) {
					
					
					
					this.OrderProductApi(resp.data[0]["or_id"]);
                    this.setState({
				    cu_contactname: resp.data[0]["cu_contactname"],
				    billaddress1: resp.data[0]["or_billingaddress"],
				    billaddress2: resp.data[0]["cu_billingaddress1"],
				    billstate: resp.data[0]["billstate"],
				    billstateid: resp.data[0]["cu_pl_id"],
				    billcity: resp.data[0]["cu_billingcity"],
				    billcountry: resp.data[0]["billcountry"],
				    billcountryid: resp.data[0]["cu_billingcountryid"],
				    billpincode: resp.data[0]["cu_billingpincode"],				
					discount: resp.data[0]["or_discount"],
					or_taxamount: resp.data[0]["or_taxamount"],
					phone: resp.data[0]["or_phone"],
					email: resp.data[0]["or_email"],
					invdate: resp.data[0]["invdate"],
					
					or_id:resp.data[0]["or_id"],
					roundoff: resp.data[0]["or_roundoff"],
				    shipaddress1: resp.data[0]["or_billingaddress"],
				    shipaddress2: resp.data[0]["cu_billingaddress1"],
				    shipstate: resp.data[0]["shipstate"],
				    shipstateid: resp.data[0]["cu_shippingstate"],
				    shipcity: resp.data[0]["cu_shippingcity"],
				    shipcountry: resp.data[0]["shipcountry"],
				    shipcountryid: resp.data[0]["cu_shippingcountryid"],
				    shippincode: resp.data[0]["cu_shippingpincode"],
					



					
						
                    });
					
					
					
                } 
				
            });
    }
	
	OrderProductApi(orid) {
    this.setState({ btndisabled: true, submitbtn: "Processing..." });

    axios.get(Serverurl + 'ajaxorderproduct.php?action=getorderproduct&or_id=' + orid)
        .then(resp => {
        if (resp.data.length > 0) {
          const updatedData = resp.data.map(item => ({
            ...item,
            totalqty:  item.op_quantity,remainqty:0,
          }));
          this.setState({
            ProductData: updatedData,
           
          });
        } else {
          this.setState({
            totalprice: this.state.price * this.state.quantity,
            ProductData: []
          });
        }
      });

}

	 
 setActiveStep(val)
 {
	 this.setState({INDEX:val,prevActiveStep:val},()=>{console.log(this.state.boxarraydata[this.state.INDEX])});
 }
    
 
   forwardButton = () => {
	   
	    let prev=Number( this.state.prevActiveStep) -1;
		
         this.setActiveStep(prev);
    };
     previousButton = () => {
	
		 let prev=Number( this.state.prevActiveStep) +1;
		
        this.setActiveStep(prev);
    };
	
handleSearchClick = () => {
    

    this.getOrderAddresscall();
   
    this.setState({ editbuttontext: 'Edit' });
}
handleboxChange = (firstrow,secondrow) => (evt) => {
	var self=this;
	let lastremainqty=0;
	const re = /^[0-9.\b]+$/;
	
	 let newPrice = evt.target.value;
	  
	   let keyname = evt.target.name;
if(keyname==="pr_salesrateexcl" || keyname==="pr_salesrateincl" || keyname==="op_quantity" || keyname==="grandweight")
  {if (evt.target.value === '' || re.test(evt.target.value)) {

let reqty=0;
   
	 
			
			
  
  var item = {    
                  id: evt.target.id,    
                  name: evt.target.name,    
                  value: evt.target.value    
              };  
		  var strset="";
              var rowsArray = this.state.boxarraydata;    
			
  var newRow = rowsArray.map((row, i) => { 
 var ordproduct;
if(i=== firstrow)
{	
   var innerrowsArray = row.ordproduct.map((eachrow, index) => {    


if(index===secondrow)
{
                 
if(keyname==="pr_salesrateexcl")
{
	
return {
                ...eachrow,
                pr_salesrateexcl: newPrice
              };

}
else if(keyname==="pr_salesrateincl")
{
	let rowtotal= (parseFloat(newPrice)*parseFloat(eachrow.op_quantity));
return { ...eachrow, pr_salesrateincl: newPrice ,total:rowtotal};
}
else if(keyname==="op_quantity")
{
	
	if(parseFloat(newPrice) <= parseFloat(eachrow.totalqty))
	{
	
	let rowtotal= (parseFloat(newPrice)*parseFloat(eachrow.pr_salesrateincl));
	let reqty=parseFloat(eachrow.totalqty)-parseFloat(newPrice);
	lastremainqty=reqty;
return { ...eachrow, op_quantity: newPrice ,total:rowtotal,remainqty:reqty };



	}else{
		let rowtotal= (parseFloat(eachrow.totalqty)*parseFloat(eachrow.pr_salesrateincl));
		alert("You can not enter more than ordered Qty")

	return { ...eachrow, op_quantity: eachrow.totalqty ,total:rowtotal,};	
		
		return false;
	}
}	
else if(keyname==="grandweight")
{
	
return { ...eachrow, opweight: newPrice  };
}
	
}

 return eachrow;

	})
	
return {
            ...row,
            ordproduct: innerrowsArray
          };	

}

 return row;				  
              });
			 

			
this.setState({ boxarraydata: newRow }, () => {
	
	console.log("Updated Array:", this.state.boxarraydata);

    
  });
}

  } 
  
}

		
Addfieldsave (firstrow,secondrow,opid) {
	
	
	  var rowsArray = this.state.boxarraydata;    
			
  var newRow = rowsArray.map((row, i) => { 
 var ordproduct;
if(i=== firstrow)
{	
   var innerrowsArray = row.ordproduct.map((eachrow, index) => {    


if(index===secondrow)
{
                 

	
return {
                ...eachrow,
                addrows: "1"
              };



	
				  
}
 return eachrow;

	})	
return {
            ...row,
            ordproduct: innerrowsArray
          };	

}

 return row;				  
              });
			 

			
this.setState({ boxarraydata: newRow }, () => {
	
	
	

    console.log("Updated Array:", this.state.boxarraydata);
  });
	
};

Addfieldremove(firstrow,secondrow,opid) {
	 var rowsArray = this.state.boxarraydata;    
			
  var newRow = rowsArray.map((row, i) => { 
 var ordproduct;
if(i=== firstrow)
{	
   var innerrowsArray = row.ordproduct.map((eachrow, index) => {    


if(index===secondrow)
{
                 

	
return {
                ...eachrow,
                addrows: "0"
              };



	
				  
}
 return eachrow;

	})	
return {
            ...row,
            ordproduct: innerrowsArray
          };	

}

 return row;				  
              });
			 

			
this.setState({ boxarraydata: newRow }, () => {
    console.log("Updated Array:", this.state.boxarraydata);
  });
};
  handleErrorclose = (event) => {
        this.setState({ erroropen: false });
    }
handleordershipping= (event) => {
	
	
	 if(this.state.boxno === "")
	 {
		
		 this.setState({errormessage:"Please Enter No. of Shipping Box ",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	
	 if(this.state.billaddress1 === "")
	 {
		
		 this.setState({errormessage:"Please Enter Billing Address1.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 if(this.state.billaddress2 === "")
	 {
		
		 this.setState({errormessage:"Please Enter Billing Address2.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }if(this.state.billcity === "")
	 {
		
		 this.setState({errormessage:"Please Enter Billing City.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }if(this.state.billstateid === "0" || this.state.billstateid === "")
	 {
		
		 this.setState({errormessage:"Please Enter Billing State.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 if(this.state.billcountryid === "0" || this.state.billcountryid === "")
	 {
		
		 this.setState({errormessage:"Please Enter Billing Country.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 if(this.state.billpincode === "")
	 {
		
		 this.setState({errormessage:"Please Enter Billing Pincode.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 if(this.state.shipaddress1 === "0")
	 {
		
		 this.setState({errormessage:"Please Enter Shipping Address1.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 if(this.state.shipaddress2 === "0")
	 {
		
		 this.setState({errormessage:"Please Enter Shipping Address2.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }if(this.state.shipcity === "")
	 {
		
		 this.setState({errormessage:"Please Enter Shipping City.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }if(this.state.shipstateid === "0" || this.state.shipstateid === "")
	 {
		
		 this.setState({errormessage:"Please Enter Shipping State.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 if(this.state.shipcountryid === "0" || this.state.shipcountryid === "")
	 {
		
		 this.setState({errormessage:"Please Enter Shipping Country.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 if(this.state.shippincode === "")
	 {
		
		 this.setState({errormessage:"Please Enter Shipping Pincode.",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 if(this.state.currencycode === "")
	 {
		
		 this.setState({errormessage:"Please Enter Currency Code",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	
	 var strsep="",strorprod="";
	 
	  var rowsArray = this.state.boxarraydata;    
              var newRow1 = rowsArray.map((rows, i) => {
				var rowsArray1 = this.state.boxarraydata[i].ordproduct;
			
				var newRow = rowsArray1.map((eachrow, j) => {
if(eachrow.addrows==="1")
{

					strorprod=strorprod+strsep+rows.box+";"+eachrow.pr_code+";"+eachrow.op_title+";"+eachrow.pr_salesrateexcl+";"+eachrow.pr_salesrateincl+";"+eachrow.tx_rate+";"+eachrow.op_quantity+";"+eachrow.total+";"+eachrow.opweight+";"+eachrow.pr_hsncode;
					strsep="~";
}
					});
			  });

if(	strorprod=="")
{
 this.setState({errormessage:"Please Select Products",erroropen:true,});
		 window.scrollTo({
          top: 0
      })
		 return false;
}	
	 
var formData = new FormData();
	
		formData.append('action','shipinsert') 
		formData.append('so_userid',localStorage.getItem("Ruserid"))
		formData.append('so_products',strorprod)
			formData.append('so_round',this.state.roundoff)
		formData.append('so_taxvalue',this.state.or_taxamount)
		formData.append('so_discount',this.state.discount)
			formData.append('so_phone',this.state.phone)
		formData.append('so_email',this.state.email)
		formData.append('so_customername',this.state.cu_contactname)
		formData.append('so_invoiceno',this.state.or_invoice)
		formData.append('so_numberofbox',this.state.numTextboxes)
				formData.append('so_numberofbox',this.state.boxno)
		formData.append('so_billingaddress1',this.state.billaddress1)
		formData.append('so_billingaddress2',this.state.billaddress2)
		formData.append('so_billingcountry',this.state.billcountry)
		formData.append('so_billingstate',this.state.billstate)
		formData.append('so_billingcity',this.state.billcity)
		formData.append('so_billingoincode',this.state.billpincode)
		formData.append('so_shippingaddress1',this.state.shipaddress1)
		formData.append('so_shippingaddress2',this.state.shipaddress2)
		formData.append('so_shippingcountry',this.state.shipcountry)
			formData.append('so_shippingstate',this.state.shipstate)
		formData.append('so_shippingcity',this.state.shipcity)
		formData.append('so_shippingpincode',this.state.shippincode)
		formData.append('so_or_id',this.state.or_id)
		formData.append('so_shippingcurrency',this.state.currencycode)
		
		 axios.post(Serverurl+"ajaxshippingorder.php", formData, {
        }).then(res => {
			if(res.data===0)
			{
				this.setState({ errormessage:"Shipping label generated for this invoice",erroropen:true,alerttyep:"error" });
				window.scrollTo({
          top: 0
      })
			}
			else if(res.data!==-1)
			{window.open(res.data);
				 this.setState({ isboxdisplay: false,pdflink:res.data,cu_contactname:'',errormessage:"Shipping label generated Successfully",erroropen:true,alerttyep:"success" });
				 window.scrollTo({
          top: 0
      })
			}else{
				this.setState({ errormessage:"Shipping label not generated, please check the details",erroropen:true,alerttyep:"error" });
				window.scrollTo({
          top: 0
      })
			}
			
		});
}	
	
	handleDiscard= (event) => {
	
	window.location.reload();
}
			
	  handleNumChange = (event) => {
		 
    let num = parseInt(event.target.value);
	 let copy=[];
for (let j = 0; j < num; j++) {
	let k= j+1;
       var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
        const item = {
        id:id,
          box: k,

         ordproduct:this.state.ProductData
        };
        
         copy.push( item)
      
        }
	
      this.setState({
        numTextboxes: num,
		boxno:event.target.value,
		numprevboxes: parseInt(num)-1,
        boxarraydata:copy
      },()=>{console.log(this.state.boxarraydata[this.state.INDEX])
	  if (this.state.or_invoice !== "") {
     
        this.setState({ isboxdisplay: true });
    }
	  
	  });
	  
    
  };

    AddbiillingBox(val) {
        this.setState({ orid: val,
type:1,
txtaddress1:this.state.billaddress1,
txtaddress2:this.state.billaddress2,
txtcity:this.state.billcity,
txtstateid:this.state.billstateid,
txtcountryid:this.state.billcountryid,

billstate:this.state.billstate,
billcountry:this.state.billcountry,
txtpincode:this.state.billpincode,
		});
        setTimeout(() => this.refs.BillingBox.handleClickOpen(), 500);
    }
	
	 AddshippingBox(val) {
        this.setState({ orid: val ,
		type:2,
txtaddress1:this.state.shipaddress1,
txtaddress2:this.state.shipaddress2,
txtcity:this.state.shipcity,
txtstateid:this.state.shipstateid,
txtcountryid:this.state.shipcountryid,

billstate:this.state.shipstate,
billcountry:this.state.shipcountry,
txtpincode:this.state.shippincode,});
        setTimeout(() => this.refs.BillingBox.handleClickOpen(), 500);
    }

    render() {
let pageindex=this.state.INDEX;
	let subtaotal=0,grandtotal=0,grandweight=0;		
        return (
            <Page layout="public">
                <Section slot="menu"> / Configuration / Generate Shipping</Section>
				<Section slot="btn"><a href='#/Shippingreport' tabIndex="-1">Back </a>
</Section>
				
				
                <Section slot="content">
                    <React.Fragment>
                        <div className="mainorder">
                            <br></br>
                            <div className="headername">
                                <p><b>Generate Shipping</b></p>
                            </div>
                            <div className="search">
							<p>
                                <Collapse in={this.state.erroropen}>
                                    <Alert
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    this.handleErrorclose();
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        } severity={this.state.alerttyep}
                                    >
                                        {this.state.errormessage}
                                    </Alert>
                                </Collapse>
                            </p>
							<p>{this.state.pdflink} </p>
                                <ul>
                                    <li>
                                        <p>Invoice No.</p>
                                        <p>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                
                                                value={this.state.or_invoice}
                                                id="or_invoice"
                                                inputProps={{ tabIndex: "1", maxLength: 150 }}
                                                className="txtbox"
                                                name="or_invoice"
                                                onChange={this.myChangeHandler}
                                            />&nbsp;&nbsp;
											 <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                id="searchform"
                                                className={useStyles.submit}
                                                onClick={this.handleSearchClick}
                                               
                                            >
                                                Search
                                            </Button>
                                        </p>
                                    </li>
									
									
									
                                    
			{this.state.cu_contactname!="" && (						
									
			  <React.Fragment>						 
		  <li>	 
      <p>Number of Boxes</p>
          <p>
          <TextField
            variant="outlined"
            margin="normal"
			name="boxno"
            onChange={this.handleNumChange}
            
            inputProps={{ tabIndex: "2", maxLength: 150 }}
            className="txtbox"
            InputLabelProps={{ shrink: true }}
          />
        </p></li>  <li>	 
      <p>Currency</p>
          <p>
          <TextField   variant="outlined"
                                                margin="normal"
                                                
                                                value={this.state.currencycode}
                                                id="currencycode"
                                                inputProps={{ tabIndex: "3", maxLength: 150 }}
                                                className="txtbox"
                                                name="currencycode"
                                                onChange={this.myChangeHandler}
                                            />
        </p></li>
		  </React.Fragment>	)}
		
		
									
                                </ul>
                            </div>
<BillingBox ref="BillingBox"

txtaddress1={this.state.txtaddress1}
txtaddress2={this.state.txtaddress2}
txtcity={this.state.txtcity}
txtstateid={this.state.txtstateid}
txtcountryid={this.state.txtcountryid}
txtpincode={this.state.txtpincode} type={this.state.type}
billstate={this.state.billstate}
billcountry={this.state.billcountry}
updateInvoiceData={this.updateInvoiceData}

updateDonenew={this.updateDonenewParent}  />

<div className="panelpad">


                             
<div className="addressbox">
							 <div className="bladdressdetails">
							 
							 {this.state.cu_contactname!="" && (
							 
							
                                    <Table width="100%">
									
									<tr>
									<td colspan="2"><h4>{this.state.cu_contactname}</h4>
									<h5>{this.state.or_invoice}</h5>
									{this.state.phone!=="" && (
									<p>{this.state.phone}</p>
									
									)}
									{this.state.email!=="" && (
									<p>{this.state.email}</p>
									
									)}
									{this.state.invdate!=="" && (
									<p>{this.state.invdate}</p>
									
									)}
									
									</td>
									</tr>
									<tr><td colspan="2"><b>Billing Address</b></td> 	<td colspan="2"><b>Shipping Address</b></td> </tr>
									
									
                                        <tr>
                                            <td>
											
											
											
											
											
											<p>Billing Address1 : {this.state.billaddress1}</p>
											<p>Billing Address2 : {this.state.billaddress2}</p>
											<p>City : {this.state.billcity}</p>
											<p>State : {this.state.billstate}</p>
											<p>Country : {this.state.billcountry}</p>
									
											
											<p>Pin Code : {this.state.billpincode}</p>
											
											</td>
											
                                            <td><Button variant="container" className="primary" onClick={() => { this.AddbiillingBox(this.state.or_id) }}>{this.state.editbuttontext}</Button></td>
											
											
											 <td>
											
											<p>Shipping Addres1 : {this.state.shipaddress1}</p>
											<p>Shipping Addres2 : {this.state.shipaddress2}</p>
											<p>City : {this.state.shipcity}</p>
											<p>State : {this.state.shipstate}</p>
											<p>Country : {this.state.shipcountry}</p>
										    <p>Pin Code : {this.state.shippincode}</p>
											
											
											</td>
											
                                            <td><Button variant="container" className="primary" onClick={() => { this.AddshippingBox(this.state.or_id) }}>{this.state.editbuttontext}</Button></td>
                                        </tr>
                                    </Table>
									 )}		 
								</div>

    

<div className="clear"></div>





 {this.state.isboxdisplay===true && ( 
 
 <React.Fragment>

<MobileStepper
                steps={this.state.numTextboxes}
                variant="dots"
                style={{
                    flexGrow: 1,
                    maxWidth: 400,
                }}
                activeStep={this.state.INDEX}
                position="static"
                nextButton={
                    <Button size="small"
                        onClick={this.previousButton}
                        disabled={this.state.INDEX === (this.state.numprevboxes)}>
                        Next
                         <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small"
                        onClick={this.forwardButton}
                        disabled={this.state.INDEX === 0}>
                        <KeyboardArrowLeft />
                        Back
                     </Button>
					 }
            />
         


         <h3>Box No.: {this.state.INDEX + 1}</h3>
                  
               
				
				
				
<React.Fragment>	
<div className="productbox">


{this.state.boxarraydata.length > 0 && (
 <div>
	
	 
	
	<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  

					  
  <TableHead> 
<TableRow>
  <TableCell ><b>Code</b></TableCell>  
	  <TableCell ><b>Name</b></TableCell> 
  <TableCell ><b>HSN Code</b></TableCell>  
   <TableCell ><b>Rate(exl)</b></TableCell> 
 <TableCell ><b>Rate(inc)</b></TableCell>  
  
    <TableCell ><b>Qty</b></TableCell>  <TableCell ><b>Remaining Qty</b></TableCell>
	    <TableCell ><b>Weight</b></TableCell>
		   <TableCell><b>Total</b></TableCell>
		   </TableRow></TableHead> 
	<TableBody>
      {this.state.boxarraydata[this.state.INDEX].ordproduct.map((row, val) => {
		  
		  let grandweight=Number(row.op_quantity)*parseFloat(row.opweight);
		
		 
		  if(row.addrows==="1")
		  {
subtaotal=Number(subtaotal)+Number(row.total)
       
        grandtotal=Number(grandtotal)+Number(row.total)	
		  }
		  let firstind=pageindex;
	   return ( 
      <TableRow key={val} >
		
          <TableCell> {row.pr_code}</TableCell>
		  
		  <TableCell>  {row.op_title}
		  </TableCell>
<TableCell>{row.pr_hsncode}
		</TableCell> 
          
		 <TableCell>{row.pr_salesrateexcl}
		</TableCell>
              	  
		  
		   <TableCell>
          
             <TextField
             variant="outlined"
            
             fullWidth  
   onChange={this.handleboxChange(firstind,val)}			 
                id={row.op_id}  
             value={row.pr_salesrateincl}
         name="pr_salesrateincl"
                                   
               />
          
          </TableCell>
		  
		  
		
		  
		   <TableCell>
          
             <TextField
             variant="outlined"
             margin="normal"
             fullWidth  name="op_quantity"           
             inputProps={{ tabIndex: "1", maxLength: 150 }}
             className="txtbox"
             value={row.op_quantity}
               id={row.op_id}  
                 onChange={this.handleboxChange(firstind,val)}	                  
               />
          
          </TableCell><TableCell> {row.remainqty}  </TableCell>
		   <TableCell>
          
             <TextField
             variant="outlined"
             margin="normal"
             fullWidth  name="grandweight"           
             inputProps={{ tabIndex: "1", maxLength: 150 }}
             className="txtbox"
             value={grandweight}
               id={row.op_id}  
                 onChange={this.handleboxChange(firstind,val)}	                  
               />
          
          </TableCell>
		  
		  
		  
		   <TableCell>
        
              <h3>{row.total}</h3>
              
              
           
          
          </TableCell>
		  <TableCell><React.Fragment>
		  {row.addrows==="0" && (
		  
		    
          <AddIcon fontSize="inherit" className="primary"  onClick={()=>{this.Addfieldsave(firstind,val,row.op_id)}} />
       
		  )}
		  
		  {row.addrows==="1" && (
		  
		  <React.Fragment>
			
          <RemoveIcon fontSize="inherit"  onClick={()=>{this.Addfieldremove(firstind,val,row.op_id)}} className="secondary"/>
       <CheckIcon />
		</React.Fragment>  )}</React.Fragment>
		 </TableCell>
		  
		  
        </TableRow>
	  )
      })}
	  
	  <TableRow  >
	

<TableCell colSpan="7" align="right">Sub Total</TableCell>
<TableCell align="right">Rs.{Number(subtaotal).toFixed(2)}</TableCell>

	</TableRow>	
		<TableRow  >
	

<TableCell colSpan="7" align="right">Discount</TableCell>
<TableCell align="right">Rs.{Number(this.state.discount).toFixed(2)}</TableCell>

	</TableRow>	
	
		<TableRow  >
	

<TableCell colSpan="7" align="right">Grand Total</TableCell>


<TableCell align="right">Rs.{Math.floor(parseFloat(subtaotal)-parseFloat(this.state.discount)-parseFloat(this.state.roundoff) )}</TableCell>




	</TableRow>  
	  </TableBody>
	</Table>
  </TableContainer> 
  
  </Paper>
	
   
 </div>
)}
</div></React.Fragment>
				
             
      </React.Fragment>


 )}





	



		
		
                   </div> <div className="clear"></div>            
				  </div>  	
<div className="buttons">

<p className="btnleft"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="discard"
            className={useStyles.submit}
			
			onClick={this.handleDiscard}
			
          >
           Discard
          </Button></p>

<p className="btnright"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
           id="searchform" 
            className={useStyles.submit}
			
			onClick={this.handleordershipping}
			disabled={this.state.btnshipdisabled}
			
          >
           {this.state.ShipButtonorder}
          </Button>



</p>
<div className="clear"></div>
</div>			
				 
                        </div>
                    </React.Fragment>
                </Section>
            </Page>
        );
    }
}	 
		 
			 
export class BillingBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
			txtaddress1:'',
			txtaddress2:'',txtpincode:'',txtstateid:'',txtcountryid:'',txtcity:'',billstate:'',billcountry:'',
            or_id: '',placearray:[],headertitle:'',
            open: false,
            errorbillmessage: '',
            errbillopen: false,
            btndisabled: false,
            submitbtn: "Save",statedata:[],

			
         
        }
    }



    handleClickOpen = (e) => {
        this.setState({ open: !this.state.open,errorbillmessage: '', errbillopen: false });
      
        this.onloadCountrybind();
		
    };

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
		var index = event.target.selectedIndex;
		const re = /^[0-9\b]+$/; 
	 if(nam==="txtstateid" )
		{
			
			

        this.setState({ [nam]: val,billstate:event.target[index].text});
		}
	else if(nam==="txtcountryid" )
		{
        this.setState({ [nam]: val,billcountry:event.target[index].text});
		}else
		{
			 this.setState({ [nam]: val,});
		}
		
    }
    handlebillErrorclose = (event) => {
        this.setState({ errbillopen: false });
    }

GetallinvoiceData() {

     if (this.props.id !== 0) {           
             this.setState({
             txtaddress1:this.props.txtaddress1,
             txtaddress2:this.props.txtaddress2,
             txtcity:this.props.txtcity,
             txtstateid:this.props.txtstateid,
             txtcountryid:this.props.txtcountryid,
             txtpincode:this.props.txtpincode,
			 billcountry:this.props.billcountry,
			 billstate:this.props.billstate,
             },()=>{
				 if(this.props.type===1)
				 {
					 this.setState({headertitle:'Billing Address'});
					 
				 }
				 else{
					 this.setState({headertitle:'Shipping Address'});
				 }
				 
			 });
                    
                
        } 
    }

	onloadStatebind(){

    axios.get(Serverurl+"ajaxstate.php?action=list&pl_type=2")
    
      .then((response) => {    
     
      this.setState({  statedata: response.data,  });
	  
	    this.GetallinvoiceData();
      })   
    
      .then(data => {
        let roleFromApi = this.state.statedata.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      this.setState({
          statedata: [{value: '', display: 'State'}].concat(roleFromApi)
        });
      })

}

	
	onloadCountrybind(){

    axios.get(Serverurl+"ajaxstate.php?action=list&pl_type=1")
    
      .then((response) => {    
     
      this.setState({  placearray: response.data,  });
	  
	 this.onloadStatebind();
      })   
    
      .then(data => {
        let roleFromApi = this.state.placearray.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      this.setState({
          placearray: [{value: '', display: 'Country'}].concat(roleFromApi)
        });
		
		
      })

}
	ErrorClose= () => {
	this.setState({open:false,erropen:false});

}	
	
	
	  HandlstatusUpdate = () => {
     
	if(this.state.txtaddress1==="")
	{
		this.setState({errorbillmessage:"Please Select Billing Address1",errbillopen:true});
	window.scrollTo({
          top: 0
      })
	return false;
	}
	if(this.state.txtaddress2==="")
	{
		this.setState({errorbillmessage:"Please Select Billing Address2",errbillopen:true});
	window.scrollTo({
          top: 0
      })
	return false;
	}
	if(this.state.txtcity==="")
	{
		this.setState({errorbillmessage:"Please Select Billing Address2",errbillopen:true});
	window.scrollTo({
          top: 0
      })
	return false;
	}
	if(this.state.txtstateid==="")
	{
		this.setState({errorbillmessage:"Please Select Billing Address2",errbillopen:true});
	window.scrollTo({
          top: 0
      })
	return false;
	}
	if(this.state.txtcountryid==="")
	{
		this.setState({errorbillmessage:"Please Select Billing Address2",errbillopen:true});
	window.scrollTo({
          top: 0
      })
	return false;
	}
	if(this.state.txtpincode==="")
	{
		this.setState({errorbillmessage:"Please Select Billing Address2",errbillopen:true});
	window.scrollTo({
          top: 0
      })
	return false;
	}
	
        this.props.updateInvoiceData({
			type:this.props.type,
			txtaddress1:this.state.txtaddress1,
			txtaddress2:this.state.txtaddress2,
			txtstateid:this.state.txtstateid,
			txtcountryid:this.state.txtcountryid,
			txtcity:this.state.txtcity,
txtpincode:this.state.txtpincode,
 billcountry:this.state.billcountry,
			 billstate:this.state.billstate,
			
		})
		 this.setState({ open: !this.state.open,errorbillmessage: '', errbillopen: false });
    
	}
	
    render() {
        return (
            <React.Fragment>
                <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="xs" fullWidth="true" aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" className="dialoghead">{this.state.headertitle}</DialogTitle>
                    <DialogContent>
                      
                            <p>
                                <Collapse in={this.state.errbillopen}>
                                    <Alert
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    this.handlebillErrorclose();
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        } severity="error"
                                    >
                                        {this.state.errorbillmessage}
                                    </Alert>
                                </Collapse>
                            </p>
							<div className="customerbox">
							
							<p>Address1</p>
                           <p> <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={this.state.txtaddress1}
                                inputProps={{ tabIndex: "1", maxLength: 150 }}
                               
                                name="txtaddress1"
                                onChange={this.myChangeHandler}
                                className="txtbox"
                            /></p>
							
							<p>Address2</p>
                        <p><TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={this.state.txtaddress2}
                                inputProps={{ tabIndex: "1", maxLength: 150 }}
                               
                                name="txtaddress2"
                                onChange={this.myChangeHandler}
                                className="txtbox"
                            /></p>
						
							
							<p>City</p>
							  <p> <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={this.state.txtcity}
                                inputProps={{ tabIndex: "1", maxLength: 150 }}
                                
                                name="txtcity"
                                onChange={this.myChangeHandler}
                                className="txtbox"
                            /></p>
							
							<p>State <span className="validation">*</span></p>
		<p ><FormControl  className="dropdown" >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "14"}}
		
          onChange={this.myChangeHandler}
          id="txtstateid"
		   value={this.state.txtstateid}
		  name="txtstateid"		
        >
		{this.state.statedata.map(state => (
            <option  
            
              value={state.value}
            >
              {state.display}
            </option>
          ))}
		
        </Select>
 </FormControl></p>
		 <p>Country <span className="validation">*</span></p>
		<p><FormControl  className="dropdown" >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "13"}}
		 
          onChange={this.myChangeHandler}
          id="txtcountryid"
		   value={this.state.txtcountryid}
		  name="txtcountryid"
		
		
        >
		{this.state.placearray.map(country => (
            <option  
            
              value={country.value}
            >
              {country.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p><p>Pin Code</p>
							  <p> <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={this.state.txtpincode}
                                inputProps={{ tabIndex: "1", maxLength: 150 }}
                                
                                name="txtpincode"
                                onChange={this.myChangeHandler}
                                className="txtbox"
                            /></p>
 

		 
		
		
		
		
		
		
							</div>
							
					
<div className="buttons">	
<ul>
<li>		 
 <Button variant="contained" 
            color="primary" id="discard" onClick={this.ErrorClose} >Cancel</Button>

</li>
				<li>			
						  <Button variant="contained" 
            color="primary"onClick={this.HandlstatusUpdate}>Save</Button>
                 </li>
</ul>
<div className="clear"></div>
			   </div>
						
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}

	
	

