import React ,{Component} from 'react';
import Button 
    from "@material-ui/core/Button";
import { useTheme } 
    from "@material-ui/core/styles";
import KeyboardArrowRight 
    from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft 
    from "@material-ui/icons/KeyboardArrowLeft";
import MobileStepper 
    from "@material-ui/core/MobileStepper";
	
	  
	 
 
 export default class App extends Component {
constructor(props) {
	
	   super();
	 this.state = {INDEX:0,prevActiveStep:0}
	
}
  
 setActiveStep(val)
 {
	 this.setState({INDEX:val,prevActiveStep:val});
 }
    
 
   forwardButton = () => {
	   
	    let prev=Number( this.state.prevActiveStep) -1;
		
         this.setActiveStep(prev);
    };
     previousButton = () => {
	
		 let prev=Number( this.state.prevActiveStep) +1;
		
        this.setActiveStep(prev);
    };
 render() {
    return (
        <div
            
        >
            <h2>How to Show Pagination in ReactJS?</h2>
            <MobileStepper
                steps={5}
                variant="dots"
                style={{
                    flexGrow: 1,
                    maxWidth: 400,
                }}
                activeStep={this.state.INDEX}
                position="static"
                nextButton={
                    <Button size="small"
                        onClick={this.previousButton}
                        disabled={this.state.INDEX === 4}>
                        Next
                         <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small"
                        onClick={this.forwardButton}
                        disabled={this.state.INDEX === 0}>
                        <KeyboardArrowLeft />
                        Back
                     </Button>
					 }
            />
            <h3>Page No: {this.state.INDEX + 1}</h3>
        </div>
    );
};
 }
 
