import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  


import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import { Page, Section } from 'react-page-layout';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [


{ id: 'pl_ctype', numeric: false, label: 'Type' },
{ id: 'pl_code', numeric: false, label: 'State/Country Code' },
{ id: 'pl_name', numeric: false,  label: 'Country Name' },
{ id: '', numeric: false,  label: 'Action' },

]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};





const useStyles = makeStyles(theme =>({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
 
form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
})
)

export default class adminstate extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'pl_id',name:'',ctype:'',sname:'',pl_id:0,code:'',namesearch:'',btndisabled:false,
submitbtn:"Submit", searchbtn:"search",searchbtndisabled:false,
	 
	 
	 
	  
	 
        
      }
	 };
	 
	 
  myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  


componentDidUpdate() {
	
  if(this.state.prevpage !== this.state.page) {
	  
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}


componentDidMount() {
	
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "1" || localStorage.getItem("Rrole") === "2" )
{
	
}else{
	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
	}
	  

 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 


 
	}
	


apicall(page,rowsPerPage){
	
	
	
	this.setState({  searchbtndisabled:true,searchbtn:"Processing..."})
axios.get(Serverurl+'ajaxstate.php?action=list&pl_name='+this.state.namesearch+'&StartIndex='+page+'&PageSize='+rowsPerPage)
 
   //axios.get(Serverurl+'ajaxstate.php?action=list&StartIndex='+page+'&PageSize='+rowsPerPage)
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[];
		
			for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}
			
	
	  this.setState({rowsPaging:copy,searchbtndisabled:false,searchbtn:"Search"});
	  
	 
     }
	  else{
		 
		 this.setState({  
	 StoreData:[],totaldata:0,
		 searchbtndisabled:false,searchbtn:"Search"})
	 }
  
    });
}

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  }; 


handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });
   

   };


  

placedelete(plid){  
  
		let r = window.confirm("Do you really want to Delete?");
		 if(r === true){ //sign Out logic
				 var formData = new FormData(); 
					 formData.append('action','delete')
					
					 formData.append('pl_id',plid)
					 formData.append('userid',localStorage.getItem("Ruserid"))
				
					  axios.post(Serverurl+"ajaxstate.php", formData, {
						}).then(res => {
					
							 this.setState({ prevpage: -1,});
   
 
        setTimeout(() =>  this.componentDidUpdate() ,100); 
				  
					
					
							});
		 }
 }



placeupdate(plid){
	
	 axios.get(Serverurl+'ajaxstate.php?action=list&pl_id='+plid)
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		this.setState({  
		code:resp.data[0].pl_code,
		name:resp.data[0].pl_name,
		ctype:resp.data[0].pl_ctype,
		pl_id:resp.data[0].pl_id
		});
	}
	
	
  })

 }
	

 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
 
 
 
 handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
   
   let ctype=this.state.ctype;
    let code = this.state.code;
    let name = this.state.name;
	
	


if(code==="")
{
	this.setState({errormessage:"Please Enter State/Country Code.",open:true});
	return false;
}

if(name==="")
{
	this.setState({errormessage:"Please Enter State/Country Name.",open:true});
	return false;
}	
	if(ctype==="")
{
	this.setState({errormessage:"Please select State/Country .",open:true});
	return false;
}	
	 
	this.setState({btndisabled:true,submitbtn:"Processing..."}) 
	 
	 var formData = new FormData();
	 if(this.state.pl_id===0)
	  {
		  formData.append('pl_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('pl_id',this.state.pl_id)
		
          
	  }
	  		 
	 //alert(this.state.pl_id)
	
//alert(name);
formData.append('action','insertorupdate') 

formData.append('pl_code',code)
formData.append('pl_name',name)
formData.append('pl_type',ctype)



	   
formData.append('userid',localStorage.getItem("Ruserid"))	
	 
	   	  axios.post(Serverurl+"ajaxstate.php", formData, {
        }).then(res => {
			
			
			
		if(res.data==-1)
{ 
	 

		this.setState({errormessage:"State Code Already Exist.",open:true,btndisabled:false,submitbtn:"Submit"
		});

	}else{
		this.setState({errormessage:"Record Updated Successfully",open:true	,btndisabled:false,submitbtn:"Submit", 
		
			
			
			
		code:"",
		name:'',
		ctype:'',
		pl_id:0
		});
			
			setTimeout(() =>  this.apicall(this.state.page,this.state.rowsPerPage),500);
			
			
	}
            });
			
};

 
 handleSearch=()=>{
	  this.setState({ Data:[]});
	
	

  
	
	  
	  
	  
	localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 
} 
 
 
  
 handleClickdiscard = (e) => {
 
  this.setState({ open:!this.state.open,   name:'',ctype:'',pl_id:0,code:'',namesearch:'',
   
	  
		 });
		


};
 
 
 
 render() {
	
	
if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    }
  
  	 
    
  return (
  
   <Page layout="public">

<Section slot="menu"> / <a href='#/configuration' tabIndex="-1">Configuration</a> / States
</Section>
<Section slot="btn"><a href='#/configuration' tabIndex="-1">Configuration</a>
</Section>


<Section slot="content">
  
  
    <React.Fragment>
	
	
	
	
	<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
	
	<div className="formleft">
  
  <form className={useStyles.form}  >
  
  <div className="headername">
		 <p><b>  New State/Country</b></p>
		 </div>
<div className="main">
 
   <br></br>
		

         <p>State/Country Code <span className="validation">*</span></p>
		 
        <p>
		<TextField
            variant="outlined"
            margin="normal"
            autoFocus
            
			value={this.state.code}
            id="code"
        inputProps={{ tabIndex: "1",maxLength:10 }} 
            name="code" onChange={this.myChangeHandler}
            autoComplete="code"
			
            
			
          /></p>
		  
		

<p> State/ Country Name <span className="validation">*</span></p>

          <p>
		  <TextField
            variant="outlined"
            margin="normal"
          
            fullWidth
			value={this.state.name}
            id="name"
            inputProps={{ tabIndex: "2",maxLength:150 }} 
            name="name" onChange={this.myChangeHandler}
            autoComplete="name"
            
			
          /></p>
		  
		 <p> Type <span className="validation">*</span></p>
		<p> <FormControl  className={useStyles.formControl} >
   
        <Select
          native 	inputProps={{ tabIndex: "3" }} 
		  variant="outlined"
            margin="normal"
         value={this.state.ctype}
          onChange={this.myChangeHandler}
        name='ctype'
         id='ctype'>
		 autoFocus
          <option value={0}>Type</option>
		  <option value={1}>Country</option>
		  <option value={2}>State</option>
		 
		  
          
        </Select>
      </FormControl>
		  </p>
		
		   
		</div>
		  
		
		  
		  
		<div className="buttons">
		  <ul><li> <p> <Button
            type="submit"
            halfWidth
            variant="contained" 
            color="primary"
            id="discard"    inputProps={{ tabIndex: "4" }} 
            className={useStyles.submit}
			onClick={this.handleClickdiscard}
          >
           Discard
          </Button></p></li>
		<li> <p> <Button
            type="submit"
            halfWidth
            variant="contained"  inputProps={{ tabIndex: "3" }} 
            color="primary" onClick={this.handleSubmit}
             id="searchform"
            className={useStyles.submit}
			disabled={this.state.btndisabled}
          >
            {this.state.submitbtn}
          </Button></p></li>
		  
		  
		  </ul>
		  </div>
		  
		  
		  
		  
		   
		 </form> 

  
  
  
  </div>
  
 
  <div className="tableright"> 
   
    <div className="headername">
		 <p><b>State/Country</b></p>
		 </div>
   
   <div className="searchmaster">
	<ul>
	
	<li>
	
	<p><TextField
            //variant="outlined"
            margin="normal"
            
            fullWidth
			value={this.state.namesearch}
            id="namesearch"
           variant="outlined"
            name="namesearch" onChange={this.myChangeHandler}
            autoComplete="namesearch"
			placeholder="Name / Code"
            
			
          />
		  </p></li><li>
		  
		  <Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
           id="searchform"
            className={useStyles.submit}
			onClick={this.handleSearch}
			disabled={this.state.searchbtndisabled}
          >
             {this.state.searchbtn}
          </Button></li></ul>
	

<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.StoreData.length}
            />
                      <TableBody>

                     {stableSort(this.state.StoreData, getSorting(this.state.order, this.state.orderBy)).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >

<TableCell>{item.statetype}</TableCell>
<TableCell>{item.pl_code}</TableCell>
<TableCell>{item.pl_name}</TableCell>


<TableCell >

<div className="primary">

<EditIcon onClick={()=>{this.placeupdate(item.pl_id)}}/>  

</div>

<div className="secondary">
<DeleteIcon onClick={()=>{this.placedelete(item.pl_id)}}/>
</div>


</TableCell>

 </TableRow>


				</React.Fragment>	
				);
					})}
						{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="3">No Records</TableCell>
					</TableRow>
					) }
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
      rowsPerPagectype={[50, 100, 150,200]} 
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
   
 

   </div></div>
 </React.Fragment>
		
 </Section></Page>








  );
  }	
   

}

